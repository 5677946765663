import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ReportSidebar from "./ReportSidebar";
const Ingredientcost = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    const {

        dataPaginating,
    } = useContext(RestaurantContext);

    const { t } = useTranslation();


    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });

    //search result
    const [searchedOrder, setSearchedOrder] = useState({
        list: null,
        searched: false,
    });

    const [dataget, setdataget] = useState(null);
    const [datagetForSearch, setdatagetForSearch] = useState(
        null
    );
    const [totaldata, settotaldata] = useState(0);
    //useEffect == componentDidMount
    useEffect(() => { getdataget() }, []);
    const settotal = (data) => {
        let total = 0;
        data.map((propertyItem, thisIndex) => {
            total = parseFloat(total) + parseFloat(propertyItem.amount);
        })
        settotaldata(total);
    };
    const getdataget = () => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-food-item-cost";
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdataget(res.data);
                settotal(res.data);
                setdatagetForSearch(res.data);
                setLoading(false);
            });
    };

    //search submitted orders here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedOrder({ ...searchedOrder, searched: false });
        } else {
            let searchedList = dataget.filter((item) => {
                //token
                let lowerCaseItemToken = JSON.stringify(item.id);

                //customer
                let lowerCaseItemCustomer = item.name.toLowerCase();

                //table
                //let lowerCaseItemTable = item.table_name.toLowerCase();

                //branch
                //let lowerCaseItemBranch = item.branch_name.toLowerCase();
                return (
                    lowerCaseItemToken.includes(searchInput) ||
                    lowerCaseItemCustomer.includes(searchInput)
                    //lowerCaseItemTable.includes(searchInput) ||
                    //(lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
                );
            });
            setSearchedOrder({
                ...searchedOrder,
                list: searchedList,
                searched: true,
            });
        }
    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Costo por ingredientes"))}</title>
            </Helmet>
            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>
                        <div className="col-9 t-mb-30 mb-lg-0">
                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {!searchedOrder.searched
                                                            ? _t(t("Reportes de Costos"))
                                                            : _t(t("Search Result"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                            <ul className="t-list fk-sort align-items-center">
                                                <div className="input-group col">
                                                    <div className="form-file">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0 form-control--light-1 rounded-0"
                                                            placeholder={
                                                                _t(t("Nombre")) +
                                                                ".."
                                                            }
                                                            onChange={handleSearch}
                                                        />
                                                    </div>
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-lg-7">

                                        </div>
                                    </div>
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                        <div className="col-12 t-mb-15  text-right">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn  btn-secondary text-uppercase sm-text"
                                                table="table-to-xls"
                                                filename="tablexls"
                                                sheet="tablexls"
                                                buttonText="Exportar a XLS" />
                                        </div>

                                    </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("#"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Nombre"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Costo"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Precio"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Ganancia"))}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {!searchedOrder.searched
                                                            ? [
                                                                dataget && [
                                                                    dataget.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        dataget.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={index}
                                                                                >
                                                                                    <th
                                                                                        scope="row"
                                                                                        className="xsm-text text-capitalize align-middle text-center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </th>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.name}
                                                                                    </td>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.costo_total}
                                                                                    </td>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.venta_total}
                                                                                    </td>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center">
                                                                                        <span className="btn btn-transparent btn-success xsm-text text-capitalize">
                                                                                            {formatPrice(isNaN(((item.venta_total * 100) / item.costo_total)-100) ? 0 : (((item.venta_total * 100) / item.costo_total)-100) )} %
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ),
                                                                ],
                                                            ]
                                                            : [
                                                                /* searched data, logic === haveData*/
                                                                searchedOrder && [
                                                                    searchedOrder.list.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        searchedOrder.list.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={index}
                                                                                >
                                                                                    <th
                                                                                        scope="row"
                                                                                        className="xsm-text text-capitalize align-middle text-center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </th>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.name}
                                                                                    </td>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.costo_total}
                                                                                    </td>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.venta_total}
                                                                                    </td>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center">
                                                                                        <span className="btn btn-transparent btn-success xsm-text text-capitalize">
                                                                                        {formatPrice(isNaN(((item.venta_total * 100) / item.costo_total)-100) ? 0 : (((item.venta_total * 100) / item.costo_total)-100) )} %
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ),
                                                                ],
                                                            ]}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Ingredientcost;
