import React, { useEffect, useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  pageLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  paginationLoading,
  showingData,
  pagination,
  searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { confirmAlert } from "react-confirm-alert";
const Inventory = () => {
  //getting context values here
  const {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  const {
    authUserInfo,

    //customer
    getCustomer,
    customerList,
    setCustomerList,
    setPaginatedCustomer,
    customerForSearch,
    setCustomerForSearch,

    //pagination
    dataPaginating,
  } = useContext(UserContext);

  const {
    //branch
    branchForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // States hook here
  //new customer
  const [selectsuplier, setselectsuplier] = useState({

    address: "",
    company_id: 0,
    contact_person: "",
    created_at: "",
    description: "",
    email: "",
    id: 0,
    name: "",
    phone: "",
    status: 0,
    updated_at: "",
    user_id: 0,

  });
  const [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    branch: null,
    selectedBranch: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });

  //search result
  let [searchedCustomer, setSearchedCustomer] = useState({
    list: null,
    searched: false,
  });

  const [allsupplier, setallsupplier] = useState({
    allsupplier: []
  });
  const [allsupliers, setallsupliers] = useState({
    allsupliers: []
  });
  const [purchasecar, setpurchasecar] = useState({
    purchasecar: []
  });
  const [cuentasporpagar, setcuentasporpagar] = useState({
    cuentasporpagar: []
  });
  const [createabono, setcreateabono] = useState(false);

  const [totaldeuda, settotaldeuda] = useState({
    totaldeuda: {
      branch_id: 0,
      user_id: 0,
      supplier_id: 0,
      total: 0,
      comment: "",
      reference: "",
      payment_type: 0,
      amount_tendered: 0
    }
  });
  const [posabono, setposabono] = useState({
    amount: 0,
    note: "",
    branch_id: 0,
    user_id: 0,
  });
  const [btneditsave, setbtneditsave] = useState(false);
  const [totaldeudabase, settotaldeudabase] = useState({
    totaldeudabase: 0
  });
  const [listtypepago, setlisttypepago] = useState({
    listtypepago: [
      { name: 'Efectivo', value: 1 },
      { name: 'Cheque', value: 2 },
      { name: 'Transferencia', value: 3 },
      { name: 'Targeta', value: 4 },
      { name: 'Deudado', value: 5 }
    ]
  });

  const [datatableitems, setdatatableitems] = useState(null);
  const [datatableitemsOrdersForSearch, setdatatableitemsOrdersForSearch] = useState(null);
  const [dataPaginatingcancelled, setDataPaginatingcancelled] = useState(false);
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });
  //useEffect == componentDidMount
  useEffect(() => {
    handlegetsuplier();
    handlegetsuppliers();
  }, []);
  //optener todos los ingredientes
  const handlegetsuplier = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-suppliers-pag";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallsupplier({ ...allsupplier, allsupplier: res.data[1] });
        setdatatableitems(res.data[0]);
        setdatatableitemsOrdersForSearch(res.data[1]);
        setLoading(false);
      });
  };
  const setPaginateddatatableitems = (pageNo) => {
    setDataPaginatingcancelled(true);
    const url = BASE_URL + "/settings/get-suppliers-pag?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setdatatableitems(res.data[0]);
        setdatatableitemsOrdersForSearch(res.data[1]);
        setDataPaginatingcancelled(false);
      })
      .catch(() => { });
  };
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedOrder({ ...searchedOrder, searched: false });
    } else {
      let searchedList = datatableitemsOrdersForSearch.filter((item) => {
        //token
        let lowerCaseItemToken = JSON.stringify(item.id);

        //customer
        let lowerCaseItemCustomer = item.name.toLowerCase();

        //table
        //let lowerCaseItemTable = item.table_name.toLowerCase();

        //branch
        //let lowerCaseItemBranch = item.branch_name.toLowerCase();
        return (
          lowerCaseItemToken.includes(searchInput) ||
          lowerCaseItemCustomer.includes(searchInput)
          //lowerCaseItemTable.includes(searchInput) ||
          //(lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
        );
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedList,
        searched: true,
      });
    }
  };
  //agregar ingrediente a la tabla
  const handleaddingredien = (row) => {
    let newitemm = {
      id: 0,
      ingredient_id: row.id,
      name: row.name,
      price: row.price,
      cost: row.cost,
      stock: row.stock,
      quantity: 0
    }
    let total = 0;
    if (purchasecar.purchasecar != undefined > purchasecar.purchasecar.length > 0) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items

      purchasecar.purchasecar.map((newOrderItem, index) => {
        if (index == 0) {
          ingredient.push(newitemm);
        };

        ingredientTemp = {
          ...newOrderItem
        };

        ingredient.push(newOrderItem);
        total = total + (newOrderItem.cost * newOrderItem.quantity)
      });

      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
    } else {
      let ingredient = []; //array to push order items
      ingredient.push(newitemm);
      total = total + (newitemm.quantity * newitemm.cost)
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
    }
    handlecartotal(total);
  };
  const handleonchangeprice = (e) => {
    if (purchasecar.purchasecar) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      purchasecar.purchasecar.map((newOrderItem, index) => {
        if (index == e.target.name) {
          ingredientTemp = {
            ...newOrderItem,
            price: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          // set other items as it was which are not selected to edit
          ingredient.push(newOrderItem);
        }
      });
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
    }
  };
  const handleonchangecost = (e) => {
    if (purchasecar.purchasecar) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      let total = 0;
      purchasecar.purchasecar.map((newOrderItem, index) => {
        if (index == e.target.name) {
          total = total + (newOrderItem.quantity * e.target.value)

          ingredientTemp = {
            ...newOrderItem,
            cost: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          total = total + (newOrderItem.cost * newOrderItem.quantity)
          ingredient.push(newOrderItem);
        }
      });
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
      handlecartotal(total);
    }
  };
  const handleonchangequantity = (e) => {
    if (purchasecar.purchasecar) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items

      let total = 0;
      purchasecar.purchasecar.map((newOrderItem, index) => {

        if (index == e.target.name) {
          total = total + (newOrderItem.cost * e.target.value)
          ingredientTemp = {
            ...newOrderItem,
            quantity: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          total = total + (newOrderItem.cost * newOrderItem.quantity)
          ingredient.push(newOrderItem);
        }
      });
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
      handlecartotal(total);
    }
  };
  const handlesetabono = (item, status) => {
    setcreateabono(status);
    console.log(authUserInfo.details)
    if (status) {
      setposabono({
        branch_id: authUserInfo.details.branch_id,
        user_id: authUserInfo.details.id,
        supplier_id: item.supplier_id,
        purchase_id: item.id,
        amount: 0,
        note: ""
      })
    } else {
      setposabono({
        branch_id: 0,
        user_id: 0,
        supplier_id: 0,
        purchase_id: 0,
        id: 0,
        amount: 0,
        note: ""
      })
    }
  };
  const handlecartotal = (total) => {
    settotaldeuda({
      supplier_id: 0,
      total: total,
      comment: "",
      reference: "",
      payment_type: 0,
      amount_tendered: 0
    });
  };
  const handleSetselectsuplier = (e) => {
    setselectsuplier({ ...selectsuplier, [e.target.name]: e.target.value });
  };
  const handleeditarsupplier = (e) => {
    e.preventDefault();
    console.log(btneditsave);
    const foodUnitUrl = BASE_URL + `/settings/get-suppliers-edit`;

    return axios
      .post(foodUnitUrl, selectsuplier, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Compra almacenada"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        handlegetsuplier();
      })
      .catch((error) => {

      });
  };
  const handlesaveabono = (e) => {
    e.preventDefault();
    console.log(posabono);
    if (posabono.amount == 0) {
      toast.error(`${_t(t("El abono debe ser mayor a cero"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      const foodUnitUrl = BASE_URL + `/settings/post-abono`;

      return axios
        .post(foodUnitUrl, posabono, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          toast.success(`${_t(t("Compra almacenada"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          //handlesetsupplier(selectsuplier);
          handlegetcuentas(selectsuplier);
          handlesetabono(null, false)
        })
        .catch((error) => {

        });
    }

  };
  const handleselectsuplier = (row) => {
    settotaldeuda({ ...totaldeuda, supplier_id: row.id });
  };
  const handleonchanguedeudapos = (e) => {
    setposabono({ ...posabono, [e.target.name]: e.target.value });
  };
  const handleselecttypepayment = (row) => {
    settotaldeuda({ ...totaldeuda, payment_type: row.value });
  };
  const handlegetsuppliers = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-suppliers";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallsupliers({ ...allsupliers, allsupliers: res.data });
        setLoading(false);
      });
  };
  const handlesetsupplier = (row) => {
    setselectsuplier({
      address: row.address,
      company_id: row.company_id,
      contact_person: row.contact_person,
      created_at: row.created_at,
      description: row.description,
      email: row.email,
      id: row.id,
      name: row.name,
      phone: row.phone,
      status: row.status,
      updated_at: row.updated_at,
      user_id: authUserInfo.details.id,
      branch_id: authUserInfo.details.branch_id,
    });
  };
  const handlegetcuentas = (row) => {
    setselectsuplier({
      address: row.address,
      company_id: row.company_id,
      contact_person: row.contact_person,
      created_at: row.created_at,
      description: row.description,
      email: row.email,
      id: row.id,
      name: row.name,
      phone: row.phone,
      status: row.status,
      updated_at: row.updated_at,
      user_id: row.user_id,
    });
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-suppliers-cuentas";
    return axios
      .get(variationUrl, {
        params: row,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setcuentasporpagar({ ...cuentasporpagar, cuentasporpagar: res.data });
        totaldeudasupplier(res.data);
        setLoading(false);
      });
  };
  const totaldeudasupplier = (data) => {
    var total = 0;
    data.map((item, index) => {
      total = total + (item.total - item.amount_tendered);
    });
    console.log(total);
    settotaldeudabase({
      ...totaldeudabase,
      totaldeudabase: total
    });
  };
  const handlepostarsupplier = (e) => {
    e.preventDefault();
    console.log(btneditsave);
    const foodUnitUrl = BASE_URL + `/settings/pos-suppliers`;

    return axios
      .post(foodUnitUrl, selectsuplier, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Compra almacenada"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        handlegetsuplier();
        setselectsuplier({
          address: "",
          company_id: 0,
          contact_person: "",
          created_at: "",
          description: "",
          email: "",
          id: 0,
          name: "",
          phone: "",
          status: 0,
          updated_at: "",
          user_id: 0,
        });
      })
      .catch((error) => {

      });
  };
  const handlesaveedit = (status) => {
    setbtneditsave(status);
    /* if (status) {
       setbtneditsave();
       setNewPropertyItem({
         ...newPropertyItem,
         name: "",
         extraPrice: null,
         allow_multi_quantity: false,
         edit: false,
         editSlug: null,
         uploading: false,
         property_id: 0
       });
       setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: [] });
     }
 */
  }
  //delete confirmation modal of property item
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeletePropertyItem(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete variation here
  const handleDeletePropertyItem = (slug) => {
    setLoading(true);
    const propertyItemUrl = BASE_URL + `/settings/delete-suppliers/${slug}`;
    return axios
      .get(propertyItemUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.info(
          `${res.data}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        /*setPropertyItemList(res.data[0]);
        setPropertyItemForSearch(res.data[0]);
        setSearchedPropertyItem({
          ...searchedPropertyItem,
          list: res.data[0],
        });
        getFood();
        setLoading(false);
        toast.success(
          `${_t(t("Property item has been deleted successfully"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );*/
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Proveedores"))}</title>
      </Helmet>

      <div className="modal fade" id="addCustomer" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  Proveedores
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={
                btneditsave
                  ? handlepostarsupplier
                  : handleeditarsupplier
              } className="mx-2 sm-text">
                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Empresa"))}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={handleSetselectsuplier}
                          value={selectsuplier && selectsuplier.name}
                          placeholder="Empresa"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Contacto"))}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="contact_person"
                          name="contact_person"
                          onChange={handleSetselectsuplier}
                          value={selectsuplier && selectsuplier.contact_person}
                          placeholder="Contacto"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Celular"))}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          onChange={handleSetselectsuplier}
                          value={selectsuplier && selectsuplier.phone}
                          placeholder="Celular"

                        />
                      </div>
                    </div>
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Correo"))}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          onChange={handleSetselectsuplier}
                          value={selectsuplier && selectsuplier.email}
                          placeholder="Correo"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                      <textarea
                        className="form-control"
                        placeholder="Direccion"
                        name="address"
                        value={selectsuplier && selectsuplier.address}
                        onChange={handleSetselectsuplier}>
                      </textarea>
                    </div>
                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={selectsuplier && selectsuplier.description}
                        onChange={handleSetselectsuplier}>
                      </textarea>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                        >
                          {
                            btneditsave
                              ? 'Guardar'
                              : 'Actualizar'
                          }
                        </button>
                      </div>
                      <div className="col-6">

                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}
      <div className="modal fade" id="cuentaspor" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  Cuentas por Pagar
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">

              <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                  <label htmlFor="Total" className="form-label">
                    {_t(t("Total Deuda"))}{" "}
                  </label>
                </div>
                <div className="col-6 t-mb-15" style={{ textAlign: "right" }}>
                  <label htmlFor="t" className="form-label">
                    {currencySymbolLeft()}
                    {formatPrice(totaldeudabase.totaldeudabase)}
                    {currencySymbolRight()}
                  </label>
                </div>
              </div>
              <br />
              {createabono ?
                <form onSubmit={handlesaveabono} className="mx-2 sm-text">
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Bono"))}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="amount"
                          name="amount"
                          type="number"
                          step="0.01"
                          min="0"
                          onChange={handleonchanguedeudapos}
                          value={posabono && posabono.amount}
                          placeholder="Numero de Factura"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                      <textarea
                        className="form-control"
                        placeholder="note"
                        name="note"
                        value={posabono && posabono.note}
                        onChange={handleonchanguedeudapos}>
                      </textarea>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={() =>
                            handlesetabono(0, false)
                          }
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                : null
              }
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead className="align-middle">
                    <tr>
                      <th
                        scope="col"
                        className="sm-text align-middle text-center border-1 border"
                      >
                        {_t(t("S/L"))}
                      </th>

                      <th
                        scope="col"
                        className="sm-text align-middle text-center border-1 border"
                      >
                        {_t(t("Compra"))}
                      </th>
                      <th
                        scope="col"
                        className="sm-text align-middle text-center border-1 border"
                      >
                        {_t(t("Total Compra"))}
                      </th>
                      <th
                        scope="col"
                        className="sm-text align-middle text-center border-1 border"
                      >
                        {_t(t("Deuda"))}
                      </th>
                      <th
                        scope="col"
                        className="sm-text align-middle text-center border-1 border"
                      >
                        {_t(t("Accion"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuentasporpagar.cuentasporpagar && cuentasporpagar.cuentasporpagar.length > 0 ? (
                      [
                        cuentasporpagar.cuentasporpagar.map((item, index) => {
                          return (
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {index + 1}
                              </td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {"Compra-" + item.id}
                              </td>
                              <td className="xsm-text text-capitalize align-middle text-center   text-secondary">

                                {currencySymbolLeft()}
                                {formatPrice(item.total)}
                                {currencySymbolRight()}

                              </td>
                              <td className="xsm-text text-capitalize align-middle text-center  text-primary">

                                {currencySymbolLeft()}
                                {formatPrice(item.total - item.amount_tendered)}
                                {currencySymbolRight()}



                              </td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {
                                  <span
                                    className="btn btn-transparent btn-success xsm-text text-capitalize"
                                    onClick={() => {
                                      handlesetabono(item, true)
                                    }}
                                    data-toggle="modal"
                                    data-target="#cuentaspor"
                                  >
                                    <i className="fa fa-money"></i>
                                  </span>}
                              </td>
                            </tr>
                          );
                        }),
                      ]
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newCustomer.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {_t(t("Proveedores"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-5">
                      <ul className="t-list fk-sort align-items-center">
                        <div className="input-group col">
                          <div className="form-file">
                            <input
                              type="text"
                              className="form-control border-0 form-control--light-1 rounded-0"
                              placeholder={
                                _t(t("Busqueda por id, cliente")) +
                                ".."
                              }
                              onChange={handleSearch}
                            />
                          </div>
                          <button className="btn btn-primary" type="button">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0">
                      <div className="row gx-2 align-items-center">
                        <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                          <ul className="t-list fk-sort align-items-center justify-content-center">
                            <li className="fk-sort__list mb-0 flex-grow-1">
                              <button
                                className="w-100 btn btn-secondary sm-text text-uppercase"
                                data-toggle="modal"
                                data-target="#addCustomer"
                                onClick={() => {
                                  handlesaveedit(true);
                                  handlesetsupplier({
                                    address: "",
                                    company_id: 0,
                                    contact_person: "",
                                    created_at: "",
                                    description: "",
                                    email: "",
                                    branch_id: authUserInfo.details.branch_id,
                                    id: 0,
                                    name: "",
                                    phone: "",
                                    status: 0,
                                    updated_at: "",
                                    user_id: 0,
                                  })
                                }}

                              >
                                {_t(t("add new"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Compañia"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Representante"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Celular"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Correo"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedOrder.searched
                              ? [
                                datatableitems && [
                                  datatableitems.data.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="8"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    datatableitems.data.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            scope="row"
                                            className="xsm-text align-middle text-center"
                                          >
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {index + 1}
                                            </td>
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.name}
                                            </td>
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.contact_person}
                                            </td>
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.phone}
                                            </td>
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.email}
                                            </td>
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <div className="dropdown">
                                                <button
                                                  className="btn t-bg-clear t-text-dark--light-40"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                >
                                                  <i className="fa fa-ellipsis-h"></i>
                                                </button>
                                                <div className="dropdown-menu">
                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    data-toggle="modal"
                                                    data-target="#addCustomer"
                                                    onClick={() => {
                                                      handlesaveedit(false);
                                                      handlesetsupplier(item);
                                                    }
                                                    }
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-pencil"></i>
                                                    </span>
                                                    {_t(t("Edit"))}
                                                  </button>
                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    data-toggle="modal"
                                                    data-target="#cuentaspor"
                                                    onClick={() =>
                                                      handlegetcuentas(item)
                                                    }
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-pencil"></i>
                                                    </span>
                                                    {_t(t("Cuentas por Pagar"))}
                                                  </button>

                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() =>
                                                      //handlesetabono(index)
                                                      handleDeleteConfirmation(item.id)
                                                    }
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-trash"></i>
                                                    </span>
                                                    {_t(t("Eliminar"))}
                                                  </button>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedOrder && [
                                  searchedOrder.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="8"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedOrder.list.map((item, index) => {
                                      return (
                                        <tr
                                          scope="row"
                                          className="xsm-text align-middle text-center"
                                        >
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            {index + 1}
                                          </td>
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            {item.name}
                                          </td>
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            {item.contact_person}
                                          </td>
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            {item.phone}
                                          </td>
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            {item.email}
                                          </td>
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            <div className="dropdown">
                                              <button
                                                className="btn t-bg-clear t-text-dark--light-40"
                                                type="button"
                                                data-toggle="dropdown"
                                              >
                                                <i className="fa fa-ellipsis-h"></i>
                                              </button>
                                              <div className="dropdown-menu">
                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  data-toggle="modal"
                                                  data-target="#addCustomer"
                                                  onClick={() => {
                                                    handlesaveedit(false);
                                                    handlesetsupplier(item);
                                                  }
                                                  }
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-pencil"></i>
                                                  </span>
                                                  {_t(t("Edit"))}
                                                </button>
                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  data-toggle="modal"
                                                  data-target="#cuentaspor"
                                                  onClick={() =>
                                                    handlegetcuentas(item)
                                                  }
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-pencil"></i>
                                                  </span>
                                                  {_t(t("Cuentas por Pagar"))}
                                                </button>

                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  onClick={() =>
                                                    handlesetabono(index)
                                                  }
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-trash"></i>
                                                  </span>
                                                  {_t(t("Eliminar"))}
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedOrder.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              datatableitems,
                              setPaginateddatatableitems
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(datatableitems)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedOrder({
                                    ...searchedOrder,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedOrder,
                                  datatableitemsOrdersForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Inventory;
