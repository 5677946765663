// for localhost development
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   "/foodkhan/laravel/public";

//for localhost production
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   `${
//     window.location.port !== ""
//       ? `:${window.location.port}/khadyo/laravel`
//       : "/khadyo/laravel"
//   }`;
//for khadyo.com
// export let BASE_URL =
//   window.location.protocol + "//" + window.location.hostname + "/laravel";

// for production
/*export let BASE_URL =
  window.location.protocol +
  "//" +
  window.location.hostname +
  `${
    window.location.port !== ""
      ? `:${window.location.port}`
      : `${!window.location.href.includes(".test") ? "/public" : ""}`
  }`;
*/
//LOCAL
//export let BASE_URL  = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split('/')[1];


//PRODUCTION
//export let BASE_URL  =  window.location.protocol + "//" + window.location.hostname + "/" + window.location.pathname.split('/')[1] + '/public';
//export let BASE_URL  = 'http://151.106.106.5:80';
//export let BASE_URL  = 'http://127.0.0.1:8000';

export let BASE_URL =
  window.location.protocol +
  "//" +
  window.location.hostname +
  `${window.location.port !== ""
    ? `:${window.location.port}`
    : `${!window.location.href.includes(".test") ? "/public" : ""}`}`;

//export let BASE_URL = 'http://localhost/foodv2backend/public';
console.log(BASE_URL);
//password 012019