import React, { useState, useContext, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import { UserContext } from "../../../../../contexts/User";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { FoodContext } from "../../../../../contexts/Food";

const Ingredients = () => {
  const { t } = useTranslation();
  const history = useHistory();

  //getting context values here
  let {
    //common
    loading,
    setLoading,

    //foodGroup
    foodGroupForSearch,

    //property group
    propertyGroupForSearch,

    //food
    getFood,
    foodList,
    setFoodList,
    setPaginatedFood,
    foodForSearch,
    setFoodForSearch,

    //variations
    variationForSearch,

    //pagination
    dataPaginating,
  } = useContext(FoodContext);

  // States hook here
  let [variations, setVariations] = useState({
    //common
    edit: false,
    uploading: false,

    //food item === item
    item: null,

    //variation list === list
    list: null,

    //new price of variations
    newPrice: null,

    //variation to delete- id of food_with_variations table
    deletedVariations: null,

    //if all variation deleted
    priceAfterAllVariationDelete: "",
  });

  //new variation
  let [newVariation, setNewVariation] = useState({
    //food item === item
    item: null,
    variations: null,
  });
  let [priceForVariations, setPriceForVariations] = useState(null);

  //edit food item
  let [foodItemEdit, setFoodItemEdit] = useState({
    //show data on modal
    editItem: null,
    propertyGroup: null,

    //formData
    item: null,
    newPropertyGroups: null,
    newFoodGroup: null,
    removeProperty: false,

    //image
    imageUpdate: false,
    newImage: null,
  });
  //edit food item
  let [formingredientItem, setformingredientItem] = useState({
    //show data on modal
    /* code: null,
     name: null,
     categorie_id: null,
     cost: null,
     quantity_alert: null,
     removeProperty: false,
     unit_id: false,
     user_id: null,
     branch_id: null,*/
    item: null
  });
  const [categories, setcategories] = useState({
    categories: []
  });
  const [dataingredients, setdataingredients] = useState({
    dataingredients: []
  });
  const [measures, setmeasures] = useState({
    measures: []
  });

  //search result
  let [searchedFoodItem, setSearchedFoodItem] = useState({
    list: null,
    searched: false,
  });


  const [datatableitems, setdatatableitems] = useState(null);
  const [datatableitemsOrdersForSearch, setdatatableitemsOrdersForSearch] = useState(null);
  const [dataPaginatingcancelled, setDataPaginatingcancelled] = useState(false);
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });

  const {
    authUserInfo,
    //customer
    customerForSearch,
    setCustomerForSearch,
    //waiter
    waiterForSearch,
  } = useContext(UserContext);
  //useEffect == componentDidMount
  useEffect(() => {
    getingredients();
    getcategories();
    getmeasures();
  }, []);
  const setPaginateddatatableitems = (pageNo) => {
    setDataPaginatingcancelled(true);
    const url = BASE_URL + "/settings/get-ingredients-pagin?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setdatatableitems(res.data[0]);
        setdatatableitemsOrdersForSearch(res.data[1]);
        setDataPaginatingcancelled(false);
      })
      .catch(() => { });
  };
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedOrder({ ...searchedOrder, searched: false });
    } else {
      let searchedList = datatableitemsOrdersForSearch.filter((item) => {
        //token
        let lowerCaseItemToken = item.categorie.toLowerCase();

        //customer
        let lowerCaseItemCustomer = item.name.toLowerCase();

        //table
        //let lowerCaseItemTable = item.table_name.toLowerCase();

        //branch
        //let lowerCaseItemBranch = item.branch_name.toLowerCase();
        return (
          lowerCaseItemToken.includes(searchInput) ||
          lowerCaseItemCustomer.includes(searchInput)
          //lowerCaseItemTable.includes(searchInput) ||
          //(lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
        );
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedList,
        searched: true,
      });
    }
  };
  //edit food item
  const handleSetItemGroup = (foodGroup) => {
    setFoodItemEdit({
      ...foodItemEdit,
      newFoodGroup: foodGroup,
    });
  };

  //on change input fields
  const handleChange = (e) => {
    setFoodItemEdit({
      ...foodItemEdit,
      item: { ...foodItemEdit.item, [e.target.name]: e.target.value },
    });
  };

  //set image hook
  const handleItemImage = (e) => {
    setFoodItemEdit({
      ...foodItemEdit,
      newImage: e.target.files[0],
    });
  };

  //set properties hook
  const handleSetPropertes = (property) => {
    setFoodItemEdit({
      ...foodItemEdit,
      newPropertyGroups: property,
    });
  };

  //submit edit food item request to server
  const handleSubmit = (e) => {
    e.preventDefault();
    setVariations({ ...variations, uploading: true });
    let formData = {
      //item id
      itemId: foodItemEdit.editItem.id,
      //item group
      itemNewFoodGroup:
        foodItemEdit.newFoodGroup !== null ? foodItemEdit.newFoodGroup : null,
      //new name
      name: foodItemEdit.item.name,
      //new price
      price: foodItemEdit.editItem.price ? foodItemEdit.item.price : null,
      //to delete all property, boolean
      deleteProperty: foodItemEdit.deleteProperty ? 1 : 0,
      //new property groups
      newPropertyGroups:
        foodItemEdit.newPropertyGroups !== null &&
          foodItemEdit.newPropertyGroups.length > 0
          ? foodItemEdit.newPropertyGroups
          : null,
    };
    const url = BASE_URL + `/settings/update-food-item`;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        //handling item in modal after item has been updated
        let tempItem = res.data[1].find((item) => {
          return item.id === foodItemEdit.editItem.id;
        });
        let selectedGroups = [];
        if (tempItem.property_groups) {
          tempItem.property_groups.map((grpItem) => {
            propertyGroupForSearch.map((singlePropertyGroup) => {
              if (singlePropertyGroup.id === grpItem) {
                selectedGroups.push(singlePropertyGroup);
              }
            });
          });
        }
        setFoodItemEdit({
          //show data on modal
          editItem: tempItem,
          propertyGroup: tempItem.property_groups ? selectedGroups : null,

          //formData
          item: tempItem,
          newPropertyGroups: null,
          newFoodGroup: null,
          removeProperty: false,
        });
        setVariations({ ...variations, uploading: false });
        toast.success(`${_t(t("Food item has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setVariations({ ...variations, uploading: false });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //submit image request to server
  const handleSubmitImage = (e) => {
    e.preventDefault();
    setVariations({ ...variations, uploading: true });
    let formData = new FormData();
    formData.append(
      "itemId",
      foodItemEdit.editItem && foodItemEdit.editItem.id
    );
    formData.append("image", foodItemEdit.newImage);
    const url = BASE_URL + `/settings/update-food-item`;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        //handling item in modal after item has been updated
        let tempItem = res.data[1].find((item) => {
          return item.id === foodItemEdit.editItem.id;
        });
        setFoodItemEdit({
          //show data on modal
          editItem: tempItem,
          //formData
          item: tempItem,
          imageUpdate: true,
          newImage: null,
        });
        setVariations({ ...variations, uploading: false });
        toast.success(`${_t(t("Image has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setVariations({ ...variations, uploading: false });
        if (error.response.data.errors.image) {
          error.response.data.errors.image.forEach((item) => {
            if (item === "Please select a valid image file") {
              toast.error(`${_t(t("Please select a valid image file"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
            if (item === "Please select a file less than 5MB") {
              toast.error(`${_t(t("Please select a file less than 5MB"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          });
        }
      });
  };

  //set variations hook
  const handleSetVariations = (variations) => {
    setNewVariation({ ...newVariation, variations });
  };

  //set each variation price
  const handleVariationPrice = (e) => {
    setPriceForVariations({
      ...priceForVariations,
      [e.target.name]: e.target.value,
    });
  };

  //update variations here
  const handleUpdateVariations = (e) => {
    e.preventDefault();
    setVariations({ ...variations, uploading: true });
    let formData = {
      foodItemId: variations.item.id,
      deletedVariationsArray: variations.deletedVariations,
      newPriceArray: variations.newPrice
        ? Object.entries(variations.newPrice)
        : null,
      priceAfterAllVariationDelete: variations.priceAfterAllVariationDelete,
    };

    const foodVariationUpdateUrl =
      BASE_URL + `/settings/update-food-item-variation`;
    return axios
      .post(foodVariationUpdateUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        //handling variations in modal after variations updated
        let tempItem = res.data[1].find((item) => {
          return item.id === variations.item.id;
        });
        setVariations({
          ...variations,
          edit: false,
          uploading: false,
          item: tempItem,
          list: tempItem.variations,
          deletedVariations: null,
          newPrice: null,
          priceAfterAllVariationDelete: "",
        });
        toast.success(`${_t(t("Food variations has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //save new variations here
  const handleSaveNewVariations = (e) => {
    e.preventDefault();
    if (newVariation.variations === null) {
      toast.error(`${_t(t("Please select variations"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      if (newVariation.variations.length > 0) {
        setVariations({ ...variations, uploading: true });
        let formData = new FormData();
        formData.append("foodItemId", newVariation.item.id);
        //converting variations and prices to array
        let slugArray = [];
        newVariation.variations.map((newVarItem) => {
          slugArray.push(newVarItem.slug);
        });
        slugArray.map((slugItem) => {
          formData.append("slugOfVariations[]", slugItem);
        });

        let tempData = Object.entries(priceForVariations);
        tempData.map((item) => {
          formData.append("variations[]", item);
        });

        const url = BASE_URL + "/settings/new-food-item-variation";
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then(() => {
            setNewVariation({
              item: null,
              variations: null,
            });
            setPriceForVariations(null);
            setVariations({ ...variations, uploading: false });
            toast.success(`${_t(t("variations has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          });
      } else {
        toast.error(`${_t(t("Please select variations"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  };

  //delete confirmation modal of food item
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteingredient(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //delete food here
  const handleDeleteingredient = (id) => {
    setLoading(true);
    const url = BASE_URL + `/settings/delete-ingredients/${id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.success(`${_t(t("Item has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        getingredients();
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  //on change input fields
  const handleChangeaddedit = (e) => {
    setformingredientItem({
      ...formingredientItem,
      item: { ...formingredientItem.item, [e.target.name]: e.target.value },
    });
  };
  const handleSetcategorie = (row) => {
    setformingredientItem({
      ...formingredientItem,
      item: { ...formingredientItem.item, categorie_id: row.id },
    });
  };
  const handleSetmeasure = (row) => {
    setformingredientItem({
      ...formingredientItem,
      item: { ...formingredientItem.item, unit_id: row.id },
    });
  };
  const getcategories = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-categories";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setcategories({ ...categories, categories: res.data });
        setLoading(false);
      });
  };
  const getmeasures = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-measures";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setmeasures({ ...measures, measures: res.data });
        setLoading(false);
      });
  };
  const getingredients = () => {
    /*setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-ingredients";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setdataingredients({ ...dataingredients, dataingredients: res.data });
        setLoading(false);
      });*/
  };
  const handlepostingredient = (e) => {
    e.preventDefault();
    if (formingredientItem.item != null) {
      if (formingredientItem.item.unit_id == null || formingredientItem.item.categorie_id == null) {
        toast.error(`${_t(t("Ingresar categoria y unidad de medida"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      } else {
        let dt = {
          categorie_id: formingredientItem.item.categorie_id,
          code: formingredientItem.item.code,
          cost: formingredientItem.item.cost,
          name: formingredientItem.item.name,
          quantity_alert: formingredientItem.item.quantity_alert,
          unit_id: formingredientItem.item.unit_id,
          user_id: authUserInfo.details.id,
          branch_id: authUserInfo.details.branch_id
        }
        const URL =
          BASE_URL + `/settings/post-ingredient`;
        return axios
          .post(URL, dt, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setformingredientItem({
              ...formingredientItem,
              item: {
                code: "",
                cost: 0,
                name: "",
                quantity_alert: 0,
              }
            });
            toast.success(`${_t(t("Food variations has been updated"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            getingredients();
          })
          .catch(() => {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          });
      };
    }

  };
  const handleeditingredient = (e) => {
    e.preventDefault();
    if (formingredientItem.item.unit_id == null || formingredientItem.item.categorie_id == null) {
      toast.error(`${_t(t("Ingresar categoria y unidad de medida"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      let dt = {
        id: formingredientItem.item.id,
        categorie_id: formingredientItem.item.categorie_id,
        code: formingredientItem.item.code,
        cost: formingredientItem.item.cost,
        name: formingredientItem.item.name,
        quantity_alert: formingredientItem.item.quantity_alert,
        unit_id: formingredientItem.item.unit_id,
        user_id: authUserInfo.details.id,
        branch_id: authUserInfo.details.branch_id
      }
      const URL =
        BASE_URL + `/settings/edit-ingredient`;
      return axios
        .post(URL, dt, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          /*setformingredientItem({
            ...formingredientItem,
            item: null
          });*/
          toast.success(`${_t(t("Food variations has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });

          getingredients();
        })
        .catch(() => {
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    };
  };
  const handlesetNewingredienm = (status) => {
    if (status) {
      setformingredientItem({
        ...formingredientItem,
        item: {
          code: "",
          cost: 0,
          name: "",
          quantity_alert: 0,
        }
      });
    }

  }
  return (
    <>
      <Helmet>
        <title>{_t(t("Ingredientes"))}</title>
      </Helmet>

      {/* edit item modal */}
      <div className="modal fade" id="editFood" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                  {_t(t("Update"))}{" "}
                  <span className="text-capitalize">
                    {foodItemEdit.editItem && foodItemEdit.editItem.name}
                  </span>
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleeditingredient} className="mx-2 sm-text">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mt-3">
                      <div className="mb-2">
                        <label htmlFor="name" className="control-label">
                          {_t(t("Name"))}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={handleChangeaddedit}
                          value={formingredientItem.item && formingredientItem.item.name}
                          placeholder="name"
                          required
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Categoria"))}
                        </label>
                      </div>
                      {formingredientItem.item && (
                        <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                          <li className="list-group-item col-12 col-md-2 py-1 my-1 border-0 px-0 ml-3 ml-md-0">
                            {_t(t("Selected Group"))}-
                              </li>
                          <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 mx-2 my-1 text-center">
                            {formingredientItem.item && formingredientItem.item.categorie}
                          </li>
                        </ul>
                      )}
                      <Select
                        options={categories.categories}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        //value={categories.categories && categories.categories.filter(option => option.value === formingredientItem.item.categorie_id)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        name="categorie_id"
                        id="categorie_id"
                        onChange={handleSetcategorie}
                        placeholder={_t(t("Categoria")) + ".."}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Unidad de Medida"))}
                        </label>
                      </div>
                      {formingredientItem.item && (
                        <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                          <li className="list-group-item col-12 col-md-2 py-1 my-1 border-0 px-0 ml-3 ml-md-0">
                            {_t(t("Selected Group"))}-
                              </li>
                          <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 mx-2 my-1 text-center">
                            {formingredientItem.item && formingredientItem.item.unit}
                          </li>
                        </ul>
                      )}
                      <Select
                        options={measures.measures}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        name="unit_id"
                        id="unit_id"
                        onChange={handleSetmeasure}
                        placeholder={_t(t("Unidad de medida")) + ".."}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <div className="mb-2">
                        <label htmlFor="costo" className="control-label">
                          {_t(t("costo"))}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="cost"
                          name="cost"
                          onChange={handleChangeaddedit}
                          value={formingredientItem.item && formingredientItem.item.cost}
                          placeholder="costo"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <div className="mb-2">
                        <label htmlFor="quantity" className="control-label">
                          {_t(t("Quantity Min"))}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="quantity_alert"
                          name="quantity_alert"
                          onChange={handleChangeaddedit}
                          value={formingredientItem.item && formingredientItem.item.quantity_alert}
                          placeholder="quantity"
                          required
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <button
                  type="submit"
                  className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4"
                /*onClick={(e) => {
                  handlepostingredient(e);
                }}*/
                >
                  {_t(t("Guardar"))}
                </button>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/* edit item modal Ends*/}

      {/*update variations modal */}
      <div className="modal fade" id="modalingredients" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                  {/* food item name in variation modal */}
                  {!variations.edit
                    ? [variations.item && variations.item.name]
                    : [
                      _t(t("Update variations of")) +
                      " " +
                      [variations.item && variations.item.name],
                    ]}
                  {/* food item name in variation modal ends */}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handlepostingredient} className="mx-2 sm-text">
                <div className="row">

                  <div className="col-md-12">
                    <div className="form-group mt-3">
                      <div className="mb-2">
                        <label htmlFor="name" className="control-label">
                          {_t(t("Name"))}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={handleChangeaddedit}
                          value={formingredientItem.item && formingredientItem.item.name}
                          placeholder="name"
                          required
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Categoria"))}
                        </label>
                      </div>
                      <Select
                        options={categories.categories}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        name="categorie_id"
                        id="categorie_id"
                        onChange={handleSetcategorie}
                        placeholder={_t(t("Categoria")) + ".."}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Unidad de Medida"))}
                        </label>
                      </div>
                      <Select
                        options={measures.measures}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        name="unit_id"
                        id="unit_id"
                        onChange={handleSetmeasure}
                        placeholder={_t(t("Unidad de medida")) + ".."}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <div className="mb-2">
                        <label htmlFor="costo" className="control-label">
                          {_t(t("costo"))}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="cost"
                          name="cost"
                          type="number"
                          step="0.01"
                          min="0"
                          onChange={handleChangeaddedit}
                          value={formingredientItem.item && formingredientItem.item.cost}
                          placeholder="costo"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <div className="mb-2">
                        <label htmlFor="quantity" className="control-label">
                          {_t(t("Quantity Min"))}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          className="form-control"
                          id="quantity_alert"
                          name="quantity_alert"
                          onChange={handleChangeaddedit}
                          value={formingredientItem.item && formingredientItem.item.quantity_alert}
                          placeholder="quantity"
                          required
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <button
                  type="submit"
                  className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4"
                /*onClick={(e) => {
                  handlepostingredient(e);
                }}*/
                >
                  {_t(t("Guardar"))}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*update variations modal Ends*/}

      {/* Add variation modal */}
      <div className="modal fade" id="addVariations" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                  {_t(t("Add variations for"))}{" "}
                  <span className="text-capitalize">
                    {newVariation.item && newVariation.item.name}
                  </span>
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {variations.uploading === false ? (
                <div key="fragment-food-group-1">
                  <form onSubmit={handleSaveNewVariations} className="mx-2">
                    {variationForSearch && (
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Add variations"))}
                          </label>
                        </div>
                        <Select
                          options={variationForSearch}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isMulti
                          maxMenuHeight="200px"
                          onChange={handleSetVariations}
                          placeholder={_t(t("Please select variations")) + ".."}
                        />
                      </div>
                    )}
                    {newVariation.variations !== null && [
                      newVariation.variations.length > 0 && (
                        <div className="card mt-3 p-3 custom-bg-secondary">
                          <div className="card-header t-bg-epsilon text-white rounded-sm text-center">
                            {_t(t("Please enter price for each variation"))}
                          </div>
                          {newVariation.variations.map((variationItem) => {
                            return (
                              <div
                                className="form-group mt-4"
                                key={variationItem.id}
                              >
                                <div className="mb-2">
                                  <label
                                    htmlFor={variationItem.slug}
                                    className="control-label sm-text"
                                  >
                                    {_t(t("Total price of"))}{" "}
                                    <span className="text-primary text-bold">
                                      {variationItem.name}
                                    </span>{" "}
                                    {_t(t("variation"))}
                                    <span className="text-primary">* </span>
                                    <small className="text-secondary">
                                      ({_t(t("Enter price in USD"))})
                                    </small>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    className="form-control"
                                    id={variationItem.slug}
                                    name={variationItem.slug}
                                    onChange={handleVariationPrice}
                                    placeholder="e.g. Type price of this item in 'US dollar'"
                                    required
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ),
                    ]}
                    <button
                      type="submit"
                      className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4"
                    >
                      {_t(t("Save"))}
                    </button>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(5)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add variation modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {variations.uploading === true || loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${dataPaginating && "loading"}`}></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedFoodItem.searched
                                    ? _t(t("Lista de Ingredientes"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-0"
                                      placeholder={
                                        _t(t("Search by name, group")) + ".."
                                      }
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#modalingredients"
                                  onClick={() => {
                                    handlesetNewingredienm(true);
                                    //property groups
                                  }}
                                >
                                  Agregar
                                  </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Categoria"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Costo"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Cantidad de Alerta"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Unidad"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Usuario"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedOrder.searched
                                ? [
                                  datatableitems && [
                                    datatableitems.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      datatableitems.data.map((item2, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index + 1}
                                            </th>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item2.name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item2.categorie}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item2.cost}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item2.quantity_alert}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item2.unit}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item2.username}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <div className="dropdown">
                                                <button
                                                  className="btn t-bg-clear t-text-dark--light-40"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                >
                                                  <i className="fa fa-ellipsis-h"></i>
                                                </button>
                                                <div className="dropdown-menu">

                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() => {
                                                      setformingredientItem({
                                                        ...formingredientItem,
                                                        item: item2
                                                      });
                                                      //property groups
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#editFood"
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-pencil"></i>
                                                    </span>
                                                    {_t(t("Editar"))}
                                                  </button>

                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() => {
                                                      handleDeleteConfirmation(
                                                        item2.id
                                                      );
                                                    }}
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-trash"></i>
                                                    </span>
                                                    {_t(t("Borrar"))}
                                                  </button>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                                : [
                                  /* searched data, logic === haveData*/
                                  searchedOrder && [
                                    searchedOrder.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedOrder.list.map(
                                        (item2, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index + 1}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item2.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item2.categorie}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item2.cost}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item2.quantity_alert}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item2.unit}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item2.username}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        setformingredientItem({
                                                          ...formingredientItem,
                                                          item: item2
                                                        });
                                                        //property groups
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#editFood"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Editar"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item2.id
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Borrar"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedOrder.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              datatableitems,
                              setPaginateddatatableitems
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(datatableitems)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedOrder({
                                    ...searchedOrder,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedOrder,
                                  datatableitemsOrdersForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Ingredients;
