import React, { useEffect, useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  pageLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/es-us";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Purchases = () => {
  //getting context values here
  const {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  const {
    authUserInfo,
    //pagination
    dataPaginating,
  } = useContext(UserContext);

  const {
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // States hook here
  //new customer
  let [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    branch: null,
    selectedBranch: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });
  const [btncreate, setbtncreate] = useState(false);

  const [allingredients, setallingredients] = useState({
    allingredients: []
  });
  const [allsupliers, setallsupliers] = useState({
    allsupliers: []
  });
  const [purchasecar, setpurchasecar] = useState({
    purchasecar: []
  });
  const [detailsdelete, setdetailsdelete] = useState([]);
  const [formValues, setformValues] = useState({
    formValues: {
      branch_id: 0,
      details: [],
      user_id: 0,
      supplier_id: 0,
      total: 0,
      comment: "",
      reference: "",
      payment_type: 0,
      amount_tendered: 0
    }
  });
  const [carttotal, setcarttotal] = useState(0);
  const [listtypepago, setlisttypepago] = useState({
    listtypepago: [
      { name: 'Efectivo', id: 1 },
      { name: 'Cheque', id: 2 },
      { name: 'Transferencia', id: 3 },
      { name: 'Tarjeta', id: 4 },
      { name: 'Deudado', id: 5 }
    ]
  });
  //data filter table
  const [dataTablePagin, setdataTablePagin] = useState({
    data: [],
    supplier_id: 0,
    total: 0,
    pages: 0,
    limit: 10,
    current_page: 1,
    pageNumbers: [],
    renderPageNumbers: [],
    searchInput: "",
    datestart: null,
    dateend: null
  });

  useEffect(() => {
    handlegetingredients();
    handlegetsuppliers();
    makeHttpRequestWithPage(0);
  }, []);
  //optener todos los ingredientes
  const handlegetingredients = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-ingredient-all";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallingredients({ ...allingredients, allingredients: res.data });
        setLoading(false);
      });
  };
  //new forma de guardado
  const addFormValueDetails = (row) => {
    let newitemm = {
      index: 0,
      id: 0,
      ingredient_id: row.id,
      name: row.name,
      price: row.price,
      cost: row.cost,
      stock: row.stock,
      quantity: 0
    }

    let temp_details = [];
    if (formValues.details && formValues.details.length > 0) {
      newitemm.index = 0;
      temp_details.push(newitemm);
      formValues.details.map((newOrderItem, index) => {
        newOrderItem.index = index + 1;
        let total = (newOrderItem.cost * newOrderItem.quantity);
        newOrderItem.total = total;
        temp_details.push(newOrderItem);

      });
    } else {
      let total = (newitemm.quantity * newitemm.cost);
      newitemm.total = total;
      temp_details.push(newitemm);

    }
    setformValues({ ...formValues, details: temp_details });

  };
  const onChangeForValuesDetailsArray = (index, e) => {
    let formValues_ = formValues;
    if (e.target.name == 'quantity') {
      const newArr = formValues_.details.map((item, i) => {
        if ((index) == item.index) {
          let temp_sub_total = parseFloat(item.cost) * (parseFloat(e.target.value));
          return {
            ...item,
            [e.target.name]: e.target.value,
            total: isNaN(temp_sub_total) ? 0 : parseFloat(temp_sub_total)
          };
        } else {
          return item;
        }
      });
      formValues_['details'] = newArr;
    } else if (e.target.name == 'cost') {
      const newArr = formValues_.details.map((item, i) => {
        if ((index) == item.index) {
          let temp_sub_total = parseFloat(item.quantity) * (parseFloat(e.target.value));
          return {
            ...item,
            [e.target.name]: e.target.value,
            total: isNaN(temp_sub_total) ? 0 : parseFloat(temp_sub_total)
          };
        } else {
          return item;
        }
      });
      formValues_['details'] = newArr;
    }
    setformValues({ ...formValues, formValues_ });
    handleUpdateTotal();
  }
  const deleteFormValueDetails = (removeIndex) => {
    let detailsdelete_ = detailsdelete;
    let temp_details = [];
    let formValues_ = formValues;
    formValues_.details.map((newOrderItem, index) => {
      if (index != removeIndex) {
        newOrderItem['index'] = temp_details.length;
        temp_details.push(newOrderItem);
      } else {
        detailsdelete_.push(newOrderItem.id);
      }
    });
    formValues_['details'] = temp_details;
    setdetailsdelete(detailsdelete_);
    setformValues({ ...formValues, formValues_ });
    handleUpdateTotal();
  };
  const handleChangeFormValuesSelects = (label, e) => {
    if (label == "supplier_id") {
      setformValues({ ...formValues, [label]: e.id });
    } else if (label == "payment_type") {
      setformValues({ ...formValues, [label]: e.id });
    } else {
      setformValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };
  const handleUpdateTotal = () => {
    let formValues_ = formValues;
    let temp_total = 0;
    let temp_details = [];
    formValues_.details.map((newOrderItem, index) => {
      temp_total = temp_total + newOrderItem.total;
      //temp_details.push(newOrderItem);
    });
    formValues_['total'] = temp_total;
    //formValues_['details'] = temp_details;
    setformValues({ ...formValues, formValues_ });
    //setformValues({ ...formValues, formValues_ });
  };
  const saveFormValue = (e) => {
    e.preventDefault();
    let dt = {
      id: formValues.id,
      branch_id: authUserInfo.details.branch_id,
      user_id: authUserInfo.details.id,
      supplier_id: formValues.supplier_id,
      comment: formValues.comment,
      reference: formValues.reference,
      payment_type: formValues.payment_type,
      amount_tendered: formValues.amount_tendered,
      items: formValues.details,
      total: formValues.total,
      detailsdelete: detailsdelete
    }
    if (dt.payment_type == 0 || dt.supplier_id == 0) {
      toast.error(`${_t(t("Seleccionar metodo de Pago y proveedor"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      let foodUnitUrl = "";
      if (btncreate) {
        console.log("creando");
        foodUnitUrl = BASE_URL + `/settings/post-purchase`;
      } else {
        console.log("actualizando");
        foodUnitUrl = BASE_URL + `/settings/update-purchase`;
      }

      return axios
        .post(foodUnitUrl, dt, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          toast.success(`${_t(t("Compra almacenada"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setdetailsdelete([]);
          setformValues({
            formValues: {
              branch_id: 0,
              details: [],
              user_id: 0,
              supplier_id: 0,
              total: 0,
              comment: "",
              reference: "",
              payment_type: 0,
              amount_tendered: 0
            }
          });
          makeHttpRequestWithPage(0);
          var modal = document.getElementById('modalCreateEdit');
          modal.click();
        })
        .catch((error) => {

        });
    }

  };
  const handlegetsuppliers = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-suppliers";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        let suppliers = [];
        suppliers.push({ name: "Todos los Usuarios", id: 0 });
        res.data.map((eachOldOrderItem) => {
          suppliers.push({ name: eachOldOrderItem.name, id: eachOldOrderItem.id });
        });
        setallsupliers({ ...allsupliers, allsupliers: suppliers });
        //setallsupliers({ ...allsupliers, allsupliers: res.data });
        setLoading(false);
      });
  };
  //tabla pagin
  const handleSearch = (name, value) => {
    console.log(name, value);
    let tem_all_Data = dataTablePagin;
    tem_all_Data[name] = value;
    console.log(tem_all_Data);
    setdataTablePagin(tem_all_Data);
    //console.log(dataTablePagin);
    setTimeout(function () {
      makeHttpRequestWithPage(0);
    }, 100);

  };
  const makeHttpRequestWithPage = async (pageNumber) => {
    const response = await fetch(`${BASE_URL}/settings/get-purchases-pagin?&page=${pageNumber}&limit=${dataTablePagin.limit}&search=${dataTablePagin.searchInput}&datestart=${moment(dataTablePagin.datestart).format("YYYY-MM-DD")}&dateend=${moment(dataTablePagin.dateend).format("YYYY-MM-DD")}&supplier_id=${dataTablePagin.supplier_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie()}`
      },
    });
    const data = await response.json();
    const pageNumbers = [];
    let renderPageNumbers;
    if (data.message == null) {
      if (data.total !== null) {
        for (let i = 1; i <= data.pages; i++) {
          pageNumbers.push(i);
        }

        renderPageNumbers = pageNumbers.map((number, index) => {
          let classes = data.current_page === number - 1 ? "page-item active" : 'page-item';
          if (number == 1 || number == data.total || (number >= data.current_page - 2 && number <= data.current_page + 2)) {
            return (
              <li key={index} className={classes} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(number - 1)}>{number}</a></li>
            );
          }
        });
      }
      setdataTablePagin({
        ...dataTablePagin,
        data: data.data,
        total: data.total,
        pages: data.pages,
        //limit: limit,
        //searchInput: searchInput,
        pageNumbers: pageNumbers,
        renderPageNumbers: renderPageNumbers,
        current_page: data.current_page
      });
    }
  }
  const seteditsetformvalues = (row) => {

    let temp_details = [];

    if (row.details && row.details.length > 0) {
      row.details.map((newOrderItem, index) => {
        newOrderItem.index = index;
        /*let total = (newOrderItem.cost * newOrderItem.quantity);
        newOrderItem.total = total;*/
        temp_details.push(newOrderItem);
      });
    }
    setbtncreate(false);
    setformValues({
      id: row.id,
      branch_id: row.branch_id,
      user_id: row.user_id,
      supplier_id: row.supplier_id,
      details: temp_details,
      total: row.total,
      comment: row.comment,
      reference: row.reference,
      payment_type: row.payment_type,
      amount_tendered: row.amount_tendered
    });
  };
  const deletepurchase = (row) => {

    var foodUnitUrl = BASE_URL + `/settings/post-purchase-delete`;

    return axios
      .post(foodUnitUrl, row, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Compra Eliminada"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        makeHttpRequestWithPage(0);
      })
      .catch((error) => {

      });
  };
  const handleDeleteConfirmation = (row) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">Esta seguro de eliminar esta compra {row.reference} ?</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  deletepurchase(row)
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Compras"))}</title>
      </Helmet>
      <div className="modal fade" id="modalCreateEdit" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  Pago de Compra
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={saveFormValue} className="mx-2 sm-text">
                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                  <div className="col-6 t-mb-15">
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          Proveedor
                        </label>
                      </div>
                      <Select
                        options={allsupliers.allsupliers}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        name='supplier_id'
                        onChange={e => handleChangeFormValuesSelects("supplier_id", e)}
                        maxMenuHeight="200px"
                        placeholder={
                          _t(t("Seleccione Proveedor")) + ".."
                        }
                        value={formValues.supplier_id ?
                          allsupliers && allsupliers.allsupliers.length > 0 ?
                            allsupliers.allsupliers.filter((item) => item.id == formValues.supplier_id)[0]
                            : null
                          : null
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6 t-mb-15">
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Factura"))}
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="reference"
                        name="reference"
                        onChange={e => handleChangeFormValuesSelects("reference", e)}
                        value={formValues && formValues.reference}
                        placeholder="Numero de Factura"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                  </div>
                  <div className="col-4">
                    <Select
                      options={allingredients.allingredients}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      classNamePrefix="select"
                      onChange={addFormValueDetails}
                      maxMenuHeight="200px"
                      placeholder={
                        _t(t("Seleccione el ingrediente")) + ".."
                      }
                    />
                  </div>
                  <div className="col-4">
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover min-table-height mt-4">
                        <thead className="align-middle">
                          <tr>
                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              #
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Producto"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Costo"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Cantidad"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Total"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Action"))}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="align-middle">
                          {formValues.details && formValues.details.length > 0 ? (
                            [
                              formValues.details.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    scope="row"
                                    className="xsm-text align-middle text-center"
                                  >
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {index + 1}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {item.name}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <input
                                        type="number"

                                        style={{ width: "50px" }}
                                        name={'cost'}
                                        value={item.cost}
                                        onChange={(e) => {
                                          onChangeForValuesDetailsArray(index, e);
                                        }}
                                        className="form-control xsm-text text-center variation-min-price-input-width"
                                      />
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <input
                                        type="number"
                                        style={{ width: "50px" }}

                                        name={'quantity'}
                                        value={item.quantity}
                                        onChange={(e) => {
                                          onChangeForValuesDetailsArray(index, e);
                                        }}
                                        className="form-control xsm-text text-center variation-min-price-input-width"
                                        placeholder="Ingrese cantidad"
                                      />
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {item.cost * item.quantity}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <button
                                        className="btn t-bg-clear t-text-dark--light-40"
                                        type="button"
                                        onClick={() =>
                                          deleteFormValueDetails(index)
                                        }
                                      >
                                        <i className="fa fa-trash-o"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }),
                            ]
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <label htmlFor="Total" className="form-label">
                        {_t(t("Total"))}{" "}
                      </label>
                    </div>
                    <div className="col-6 t-mb-15" style={{ textAlign: "right" }}>
                      <label htmlFor="t" className="form-label">
                        {currencySymbolLeft()}
                        {formatPrice(formValues.total)}
                        {currencySymbolRight()}
                      </label>
                    </div>
                  </div>
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Tipo de Pago"))}
                          </label>
                        </div>
                        <Select
                          options={listtypepago.listtypepago}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          name="payment_type"
                          onChange={e => handleChangeFormValuesSelects("payment_type", e)}
                          maxMenuHeight="200px"
                          placeholder={
                            _t(t("Seleccione tipo de pago")) + ".."
                          }
                          value={formValues.payment_type ?
                            listtypepago && listtypepago.listtypepago.length > 0 ?
                              listtypepago.listtypepago.filter((item) => item.id == formValues.payment_type)[0]
                              : null
                            : null
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Cantidad Recibida"))}
                          </label>
                        </div>
                        <input
                          type="number"
                          step="0.01"

                          className="form-control"
                          name="amount_tendered"
                          onChange={e => handleChangeFormValuesSelects("amount_tendered", e)}
                          value={formValues && formValues.amount_tendered}
                          placeholder="cantidad recibida"
                          required
                        />
                      </div>
                    </div>

                  </div>
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                      <textarea
                        className="form-control"
                        placeholder="Comentario"
                        name="comment"
                        value={formValues && formValues.comment}
                        onChange={e => handleChangeFormValuesSelects("comment", e)}>
                      </textarea>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">

                      </div>
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newCustomer.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {_t(t("Compras"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <button className="btn btn-primary" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded-0"
                            placeholder={
                              "Referencia" + ".."
                            }
                            onChange={(e) => {
                              /*setdataTablePagin({
                                ...dataTablePagin,
                                searchInput: e.target.value
                              });*/
                              handleSearch('searchInput', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">

                    </div>
                    <hr />
                    <div className="col-md-4">
                      <Select
                        options={allsupliers.allsupliers}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        onChange={(e) => {
                          /*setdataTablePagin({
                            ...dataTablePagin,
                            supplier_id: e.id
                          });*/
                          handleSearch('supplier_id', e.id);
                        }}
                        maxMenuHeight="200px"
                        placeholder={
                          _t(t("Seleccione Proveedor")) + ".."
                        }
                      />
                    </div>
                    <div className="col-md-8">
                      <ul className="t-list fk-sort align-items-center justify-content-end">
                        <li className="fk-sort__list ml-2">
                          <DatePicker
                            selected={dataTablePagin.datestart}
                            onChange={(date) => {
                              /*setdataTablePagin({
                                ...dataTablePagin,
                                datestart: date
                              });*/
                              handleSearch('datestart', date);
                            }}
                            dateFormat={'dd-MM-yyyy'}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control xsm-text py-2"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={false}
                          />
                        </li>
                        <li className="fk-sort__list">
                          <span className="fk-sort__icon">
                            <span className="fa fa-long-arrow-right"></span>
                          </span>
                        </li>
                        <li className="fk-sort__list">
                          <DatePicker
                            selected={dataTablePagin.dateend}
                            onChange={(date) => {
                              /*setdataTablePagin({
                                ...dataTablePagin,
                                dateend: date
                              });*/
                              handleSearch('dateend', date);
                            }}
                            dateFormat={'dd-MM-yyyy'}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control xsm-text py-2"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={false}
                          />
                        </li>
                        <li class="fk-sort__list">
                          <button
                            class="btn btn-transparent btn-danger xsm-text text-uppercase py-2"
                            onClick={handleSearch}
                          >
                            {_t(t("Filter"))}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div className="col-md-6">
                      <Select
                        options={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }]}
                        components={makeAnimated()}
                        value={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }].filter(({ value }) => value === dataTablePagin.limit)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        className="xsm-text"
                        onChange={(e) => {
                          /*setdataTablePagin({
                            ...dataTablePagin,
                            limit: e.value
                          });*/
                          handleSearch('limit', e.value);
                        }}
                        maxMenuHeight="200px"
                        placeholder={"Cantidad de filas"}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="row gx-2 align-items-center">
                        <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                          <ul className="t-list fk-sort align-items-center justify-content-center">
                            <li className="fk-sort__list mb-0 flex-grow-1">
                              <button
                                className="w-100 btn btn-secondary sm-text text-uppercase"
                                data-toggle="modal"
                                data-target="#modalCreateEdit"
                                onClick={() => {
                                  setbtncreate(true);
                                  setformValues({
                                    id: 0,
                                    branch_id: 0,
                                    user_id: 0,
                                    supplier_id: 0,
                                    details: [],
                                    total: 0,
                                    comment: "",
                                    reference: "",
                                    payment_type: 0,
                                    amount_tendered: 0
                                  });
                                }}
                              >
                                {_t(t("Agregar Compra"))}
                              </button>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("#"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                Ref.
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                Proveedor
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                Total
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                Fecha
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                Editar
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {dataTablePagin && dataTablePagin.data.map((item, index) => (
                              <tr key={index}
                                className="align-middle"
                              >
                                <th
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle"
                                >
                                  {index + 1 + (dataTablePagin.current_page) * dataTablePagin.limit}
                                </th>
                                <td className="xsm-text text-capitalize align-middle text-secondary">
                                  {item.reference}
                                </td>
                                <td className="xsm-text text-capitalize align-middle">
                                  {item.supplier_name}
                                </td>
                                <td className="xsm-text align-middle  text-right">
                                  {item.total}
                                </td>
                                <td class="xsm-text text-capitalize align-middle">
                                  <Moment format="LL">
                                    {item.created_at}
                                  </Moment>
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-secondary sm-text text-uppercase"
                                    data-toggle="modal"
                                    data-target="#modalCreateEdit"
                                    onClick={() => {
                                      console.log(item.details);
                                      /*setbtncreate(false);
                                      setformValues({
                                        id: item.id,
                                        branch_id: item.branch_id,
                                        user_id: item.user_id,
                                        supplier_id: item.supplier_id,
                                        details: item.details,
                                        total: item.total,
                                        comment: item.comment,
                                        reference: item.reference,
                                        payment_type: item.payment_type,
                                        amount_tendered: item.amount_tendered
                                      });*/
                                      setdetailsdelete([]);
                                      seteditsetformvalues(item);
                                    }}
                                  >
                                    <span className="text-right">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </button>
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-danger sm-text text-uppercase"
                                    onClick={() => {
                                      handleDeleteConfirmation(item);
                                    }}
                                  >
                                    <span className="">
                                      <i className="fa fa-trash"></i>
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div key="fragment4">
                <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                  <div className="row align-items-center t-pl-15 t-pr-15">
                    <div className="col-md-7 t-mb-15 mb-md-0">
                      <nav aria-label="Page navigation example" style={{ float: 'left' }}>
                        <ul className="pagination">Showing {dataTablePagin.current_page + 1} to {dataTablePagin.limit} of {dataTablePagin.total} entries</ul>
                      </nav>
                    </div>
                    <div className="col-md-5">

                      <nav aria-label="Page navigation example" style={{ float: 'right' }}>
                        <ul className="pagination">
                          <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(0)}>First</a></li>
                          <li className={dataTablePagin.current_page == 0 ? "page-item disabled" : "page-item"} style={dataTablePagin.current_page == 1 ? {} : { cursor: "pointer" }} ><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.current_page - 1)}>Anterior</a></li>
                          {dataTablePagin.renderPageNumbers}
                          <li className={dataTablePagin.current_page == dataTablePagin.pages - 1 ? "page-item disabled" : "page-item"} style={dataTablePagin.current_page == dataTablePagin.total ? {} : { cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.current_page + 1)}>Siguiente</a></li>
                          <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.pages - 1)}>Last</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Purchases;
