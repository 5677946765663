import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "./BaseUrl";

//routes
import RestaurantRoute from "./routes/RestaurantRoute";

//functions
import {
  consolee,
  checkPermission,
  getSystemSettings,
} from "./functions/Functions";

//3rd party packages
import { ToastContainer } from "react-toastify";

//pages & includes
import {
  Visitas,
  Ingredientcost,
  Propinas,
  Cuentasclientes,
  //installation
  Welcome,
  InstallPermission,
  DbSetup,
  ImportDb,
  InstallationUser,
  InstallationCongratulation,
  Wastedusers,
  CategoriaGasto,
  //landing
  RestaurantLanding,
  //common
  Refresh,
  Login,
  NotFound,
  ForgetPw,
  SetNewPw,
  NoPermission,

  //dashboard
  RestaurantHome,
  UpdateProfile,
  WorkPeriod,

  //pos
  Pos,
  Submitted,
  Settled,
  //
  OrderHistories,
  Customers,
  Kitchen,

  //Reports
  Dashboard,
  Daily,
  Monthly,
  // Yearly,
  ItemWise,
  GroupWise,
  BranchWise,
  UserWise,
  DeptWise,
  ServiceChargeWise,
  DiscountWise,

  //manage->food
  GroupCrud,
  UnitCrud,
  VariationCrud,
  PropertyCrud,
  PropertyItemCrud,
  FoodItemCrud,
  AllItemList,

  //manage->settings
  Currency,
  Lang,
  Translation,
  Smtp,
  General,

  //manage->user
  AdminStaffCrud,
  Waiter,
  Permissions,
  CustomerCrud,

  //manage->restaurantDetails
  BranchCrud,
  TableCrud,
  DeptTagCrud,
  PaymentTypeCrud,
  Box,
  Waiter2,
  Waitersubmitted,
  Waitersettled,
  Bar,
  Purchases,
  Inventory,
  Ingredients,
  Units,
  CategorieIngredient,
  Canceledorders,
  Ordersliquidated,
  PurchasesReport,
  Clients,
  Desperdicio,
  Gastos,
  DesperdicioReport,GastosReport,
  EstadoResultado,
  Inventario,
  Inventorybajo,
  BoxReports
} from "./imports/Pages";
import { Navbar, Footer } from "./imports/Components";

//context consumer
import { SettingsContext } from "./contexts/Settings";
import { UserContext } from "./contexts/User";

function App() {
  //getting context values here
  const { loading, setLoading, generalSettings } = useContext(SettingsContext);
  let { authUserInfo } = useContext(UserContext);

  //state hooks here
  const [credentials, setCredentials] = useState({
    install_no: false,
  });

  //useEffect == componentDidMount()
  useEffect(() => {
    /*(async () => {
      setLoading(false);
      const url = BASE_URL + "/check-install";
      return axios
        .get(url)
        .then((res) => {
          if (res.data === "NO") {
            setCredentials({
              ...credentials,
              install_no: true,
            });
          }
        })
        .catch((error) => {});
    })();*/

    if (generalSettings) {
      const favicon = document.getElementById("favicon");
      favicon.href = BASE_URL + getSystemSettings(generalSettings, "favicon");
    }
    // consolee();
  }, [authUserInfo]);
  return (
    <>
      <ToastContainer />
      {/* <Router basename="/khadyo"> */}
      {/* "homepage": "http://localhost/khadyo" */}
      <Router   /*basename="/foodv2backend/public"*/ >
        <Navbar />
        <Switch>
          {/* installation */}
          {credentials.install_no && (
            <Route path="/installation" exact>
              <Welcome />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/permission-chcek" exact>
              <InstallPermission />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/database-setup" exact>
              <DbSetup />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/import-database" exact>
              <ImportDb />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/add-admin-user" exact>
              <InstallationUser />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/congratulation" exact>
              <InstallationCongratulation />
            </Route>
          )}

          {/* common */}
          <Route path="/refresh" exact>
            <Refresh />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/reset-password" exact>
            <ForgetPw />
          </Route>

          <Route path="/set-new-password/:token" exact>
            <SetNewPw />
          </Route>

          <Route path="/" exact>
            <RestaurantLanding />
          </Route>

          {/* restaurant dashboard pages */}
          <RestaurantRoute path="/dashboard" exact>
            <RestaurantHome />
          </RestaurantRoute>

          <RestaurantRoute path="/update-user-profile" exact>
            <UpdateProfile />
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/work-periods" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Work period") ? (
              <WorkPeriod />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <Pos />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos/submitted" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <Submitted />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos/settled" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <Settled />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Order history") ? (
              <OrderHistories />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/customers" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Customer") ? (
              <Customers />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/kitchen" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Kitchen") ? (
              <Kitchen />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Dashboard />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>




          <RestaurantRoute path="/dashboard/Ingredientcost-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Ingredientcost />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/visitas-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Visitas />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          

          <RestaurantRoute path="/dashboard/daily-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Daily />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/Cuentasclient-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Cuentasclientes />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/wasted-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <DesperdicioReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/wasted-user" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Wastedusers />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/propinas" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Propinas />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/gastos-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <GastosReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/monthly-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Monthly />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/canceled-orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Canceledorders />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>


          <RestaurantRoute path="/dashboard/report-inventory" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Inventario />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/result" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <EstadoResultado />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/inventorio-bajo" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Inventorybajo />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>


          <RestaurantRoute path="/dashboard/purchase-report" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <PurchasesReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/orders-liquidated" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Ordersliquidated />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* <RestaurantRoute path="/dashboard/yearly-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Yearly />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute> */}

          <RestaurantRoute path="/dashboard/food-items-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <ItemWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/food-group-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <GroupWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/branch-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <BranchWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos-user-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <UserWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/dept-tag-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <DeptWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/service-charge-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <ServiceChargeWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/discount-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <DiscountWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/box-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <BoxReports />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Manage routes */}
          {/* food */}
          <RestaurantRoute path="/dashboard/manage/food/groups" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <GroupCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          {/* Manage routes */}
          {/* food */}
          <RestaurantRoute path="/dashboard/manage/restaurant/categoria-Gasto" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CategoriaGasto />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          

          {/* <RestaurantRoute path="/dashboard/manage/food/units" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <UnitCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute> */}

          <RestaurantRoute path="/dashboard/manage/food/variations" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <VariationCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/properties" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PropertyCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/properties/:slug" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PropertyItemCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/add-new" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <FoodItemCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/all-items" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AllItemList />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/ingredients" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Ingredients />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/manage/food/units" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Units />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/manage/food/categorieingredient" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CategorieIngredient />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Users */}
          <RestaurantRoute path="/dashboard/manage/user/customers" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CustomerCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/user/admin-staff" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AdminStaffCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/user/waiters" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Waiter />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/roles-and-permissions" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Permissions />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Restaurant */}
          <RestaurantRoute path="/dashboard/manage/restaurant/branches" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <BranchCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/restaurant/tables" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <TableCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/restaurant/dept-tags" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <DeptTagCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/restaurant/payment-type"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PaymentTypeCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Settings */}

          <RestaurantRoute path="/dashboard/manage/settings/currencies" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Currency />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/settings/languages" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Lang />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/languages/:code"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Translation />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/smtp-settings"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Smtp />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/general-settings"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <General />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/desperdicios" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Box") ? (
              <Desperdicio />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/gastos" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Purchases") ? (
              <Gastos />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/box" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Box") ? (
              <Box />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/bar" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Bar") ? (
              <Bar />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/waiter" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Waiter") ? (
              <Waiter2 />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/waiter/submitted" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Waiter") ? (
              <Waitersubmitted />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/waiter/settled" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Waiter") ? (
              <Waitersettled />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/inventory" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Inventory") ? (
              <Inventory />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/clients" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Inventory") ? (
              <Clients />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/purchases" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Purchases") ? (
              <Purchases />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>


          {/* Error Routing */}
          <Route component={NotFound} />
          {/* Error Routing */}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
