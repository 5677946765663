import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import moment from "moment";
import "moment/locale/es-us";
const Gastos = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    const {
        authUserInfo,
    } = useContext(UserContext);

    const {
        //submitted orders
        submittedOrders,
        setPaginatedSubmittedOrders,
        submittedOrdersForSearch,

        //payment-type
        paymentTypeForSearch,

        //pagination
        dataPaginating,
    } = useContext(RestaurantContext);

    const { t } = useTranslation();

    // States hook here
    // paidMoney
    const [paidMoney, setPaidMoney] = useState(0);
    //return
    const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });

    //search result
    const [searchedOrder, setSearchedOrder] = useState({
        list: null,
        searched: false,
    });

    const [dataPaginatingcancelled, setDataPaginatingcancelled] = useState(false);

    const [datatableitems, setdatatableitems] = useState(null);
    const [datatableitemsOrdersForSearch, setdatatableitemsOrdersForSearch] = useState(null);


    const [datatablecard, setdatatablecard] = useState({
        datatablecard: []
    });

    const [datausers, setdatausers] = useState([]);
    const [dataplatillos, setdataplatillos] = useState([]);
    const [datacategory, setdatacategory] = useState([]);
    const [datavariation, setvariation] = useState([]);

    const [posform, setposform] = useState({
        id: 0,
        user_id: "",
        categorie: 0,
        nota: 0,
        charged_to: 0,
        amount: 0
    });
    const [btncreate, setbtncreate] = useState(false);
    //useEffect == componentDidMount
    const [dataTablePagin, setdataTablePagin] = useState({
        data: [],
        user_id: 0,
        charged_to: 0,
        categorie_id: 0,
        total: 0,
        pages: 0,
        limit: 10,
        current_page: 1,
        pageNumbers: [],
        renderPageNumbers: [],
        searchInput: "",
        datestart: null,
        dateend: null
    });

    const [allusers, setallusers] = useState({
        allusers: []
    });
    const [allcategorys, setallcategorys] = useState({
        allcategorys: []
    });

    useEffect(() => {
        getdatatableitems();
        getdataslc();
        getdataslcinit();
        makeHttpRequestWithPage(0);
    }, []);
    const getdataslcinit = () => {
        setLoading(true);
        let formData = {
            branch_id: authUserInfo.details.branch_id,
            user_id: authUserInfo.details.id,
        };
        const customerUrl = BASE_URL + "/settings/get-data-slc-gasto";
        return axios
            .get(customerUrl, {
                params: formData,
                headers: { Authorization: `Bearer ${getCookie()}` },
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                /*setdatadetallecaja(res.data[0][0]);
                setdatausers(res.data[1]);*/
                setdatausers(res.data[1]);
                setdatacategory(res.data[0]);
                setLoading(false);
            });
    };
    const getdataslc = () => {
        setLoading(true);
        let formData = {
            branch_id: authUserInfo.details.branch_id,
            user_id: authUserInfo.details.id,
        };
        const customerUrl = BASE_URL + "/settings/get-data-slc-gasto";
        return axios
            .get(customerUrl, {
                params: formData,
                headers: { Authorization: `Bearer ${getCookie()}` },
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                /*setdatadetallecaja(res.data[0][0]);
                setdatausers(res.data[1]);*/

                let users = [];
                users.push({ name: "Todos los Usuarios", id: 0 });
                res.data[1].map((eachOldOrderItem) => {
                    users.push({ name: eachOldOrderItem.name, id: eachOldOrderItem.id });
                });
                setallusers({ ...allusers, allusers: users });

                let categorys = [];
                categorys.push({ name: "Todos los Gastos", id: 0 });
                res.data[0].map((eachOldOrderItem) => {
                    categorys.push({ name: eachOldOrderItem.name, id: eachOldOrderItem.id });
                });
                setallcategorys({ ...allcategorys, allcategorys: categorys });

                setLoading(false);
            });
    };


    const makeHttpRequestWithPage = async (pageNumber) => {
        const response = await fetch(`${BASE_URL}/settings/report-get-getExpenses-pagin?&page=${pageNumber}&limit=${dataTablePagin.limit}&search=${dataTablePagin.searchInput}&datestart=${moment(dataTablePagin.datestart).format("YYYY-MM-DD")}&dateend=${moment(dataTablePagin.dateend).format("YYYY-MM-DD")}&user_id=${dataTablePagin.user_id}&charged_to=${dataTablePagin.charged_to}&categorie_id=${dataTablePagin.categorie_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie()}`
            },
        });
        const data = await response.json();
        const pageNumbers = [];
        let renderPageNumbers;
        if (data.message == null) {
            if (data.total !== null) {
                for (let i = 1; i <= data.pages; i++) {
                    pageNumbers.push(i);
                }
                renderPageNumbers = pageNumbers.map((number, index) => {
                    let classes = data.current_page === number - 1 ? "page-item active" : 'page-item';
                    if (number == 1 || number == data.total || (number >= data.current_page - 2 && number <= data.current_page + 2)) {
                        return (
                            <li key={index} className={classes} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(number - 1)}>{number}</a></li>
                        );
                    }
                });
            } else {
                data['data'] = [];
                data['current_page'] = 0;
                data['pages'] = 1;
                data['total'] = 1;
            }
            setdataTablePagin({
                ...dataTablePagin,
                data: data.data,
                total: data.total,
                pages: data.pages,
                //limit: limit,
                //searchInput: searchInput,
                pageNumbers: pageNumbers,
                renderPageNumbers: renderPageNumbers,
                current_page: data.current_page
            });
            //settotal(data.data);
        }
    }

    const getdatatableitems = () => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-get-getExpenses";
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdatatableitems(res.data[0]);
                setdatatableitemsOrdersForSearch(res.data[1]);
                setLoading(false);
            });
    };
    const setPaginateddatatableitems = (pageNo) => {
        setDataPaginatingcancelled(true);
        const url = BASE_URL + "/settings/get-get-getExpenses?page=" + pageNo;
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdatatableitems(res.data[0]);
                setdatatableitemsOrdersForSearch(res.data[1]);
                setDataPaginatingcancelled(false);
            })
            .catch(() => { });
    };
    //payment type
    const handleSetpaymentType = (payment_type) => {
        setCheckOrderDetails({
            ...checkOrderDetails,
            payment_type,
        });

        //calculate paid amount to set return amount
        handleCalculatePaid(checkOrderDetails.payment_amount, payment_type);
    };

    //payment type amount
    const handlePaymentTypeAmount = (e) => {
        let tempPaymentAmount = {
            ...checkOrderDetails.payment_amount,
            [e.target.name]: e.target.value,
        };

        setCheckOrderDetails({
            ...checkOrderDetails,
            payment_amount: tempPaymentAmount,
        });

        //calculate paid amount to set return amount
        handleCalculatePaid(tempPaymentAmount, checkOrderDetails.payment_type);
    };

    //calculate paid amount
    const handleCalculatePaid = (paymentAmount, paymentType) => {
        let paidAmount = 0;
        if (paymentAmount !== null && paymentType !== null) {
            let thePaymentArray = [];
            if (paymentAmount) {
                thePaymentArray = Object.entries(paymentAmount);
            }
            thePaymentArray.map((eachPaymentItem) => {
                let thePaymentType = paymentType.find((paymentTypeItem) => {
                    return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
                });
                if (eachPaymentItem[1] !== "") {
                    if (
                        thePaymentType &&
                        thePaymentType.id === parseInt(eachPaymentItem[0])
                    ) {
                        paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
                    }
                }
            });
            let localCurrency = JSON.parse(localStorage.getItem("currency"));
            paidAmount = paidAmount / localCurrency.rate;
            let theReturnMoney = 0;
            if (checkOrderDetails.item) {
                theReturnMoney =
                    paidAmount - parseFloat(checkOrderDetails.item.total_payable);
            }
            setReturnMoneyUsd(theReturnMoney);
        } else {
            setReturnMoneyUsd(0);
        }
        setPaidMoney(paidAmount);
    };
    // handleSettleOrder
    const handleSettleOrder = (e) => {
        e.preventDefault();
        if (
            paidMoney <
            parseFloat(
                checkOrderDetails.item.total_payable -
                checkOrderDetails.item.paid_amount
            )
        ) {
            toast.error(
                `${_t(
                    t("Please enter paid amount atleast equal to the total due amount")
                )}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        } else {
            handleSettleOrderAxiosReq();
        }
    };
    //settle order server request
    const handleSettleOrderAxiosReq = () => {
        let url = BASE_URL + "/settings/settle-submitted-order";
        let localCurrency = JSON.parse(localStorage.getItem("currency"));
        let formData = {
            order_group_id: checkOrderDetails.item.id,
            payment_type: checkOrderDetails.payment_type,
            payment_amount: checkOrderDetails.payment_amount,
            paidMoney: paidMoney,
            localCurrency: localCurrency,
        };
        setLoading(true);
        axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                if (res.data !== "paymentIssue") {
                    setCheckOrderDetails({
                        ...checkOrderDetails,
                        item: res.data[2],
                        payment_type: null,
                        payment_amount: null,
                        settle: false,
                    });
                    setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(
                        `${_t(
                            t(
                                "Please enter paid amount atleast equal to the total due amount"
                            )
                        )}`,
                        {
                            position: "bottom-center",
                            closeButton: false,
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        }
                    );
                }
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };
    //search submitted orders here
    const handleSearch = (name, value) => {
        console.log(name, value);
        let tem_all_Data = dataTablePagin;
        tem_all_Data[name] = value;
        console.log(tem_all_Data);
        setdataTablePagin(tem_all_Data);
        //console.log(dataTablePagin);
        setTimeout(function () {
            makeHttpRequestWithPage(0);
        }, 100);
    };
    //cancel order confirmation modal
    const handleCancelOrderConfirmation = (orderGroup) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">
                            {_t(t("You want to cancel this order?"))}
                        </p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleCancelOrder(orderGroup);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, cancel it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };
    //cancel order here
    const handleCancelOrder = (orderGroup) => {
        if (parseInt(orderGroup.is_accepted) === 0) {
            let url = BASE_URL + "/settings/cancel-submitted-order";
            let formData = {
                id: orderGroup.id,
            };
            setLoading(true);
            axios
                .post(url, formData, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data === "accepted") {
                        toast.error(
                            `${_t(t("Can not cancel this order, this is being cooked"))}`,
                            {
                                position: "bottom-center",
                                closeButton: false,
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            }
                        );
                    }
                })
                .catch(() => {
                    setLoading(false);
                    toast.error(`${_t(t("Please try again"))}`, {
                        position: "bottom-center",
                        closeButton: false,
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                });
        } else {
            toast.error(
                `${_t(t("Can not cancel this order, this is being cooked"))}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        }
    };
    //desperdicio
    const handleonchangueposform = (e) => {
        setposform({ ...posform, [e.target.name]: e.target.value });
    };
    const handleposform = (e) => {
        e.preventDefault();
        if (posform.charged_to == 0) {
            toast.error(`${_t(t("Ingresar Persona responsable"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        } else {
            let dt = {
                user_id: authUserInfo.details.id,
                branch_id: authUserInfo.details.branch_id,
                categorie: posform.categorie,
                nota: posform.nota,
                charged_to: posform.charged_to,
                amount: posform.amount,
            }
            if (posform.categorie == 0) {
                toast.error(`${_t(t("Favor agregar categoria"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            } else {
                const foodUnitUrl = BASE_URL + `/settings/post-gasto`;

                return axios
                    .post(foodUnitUrl, dt, {
                        headers: { Authorization: `Bearer ${getCookie()}` },
                    })
                    .then((res) => {
                        toast.success(`${_t(t("Gasto almacenado"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                        makeHttpRequestWithPage(0);
                    })
                    .catch((error) => {

                    });
            }
        }
    };
    const handlesetslccharged = (row) => {
        setposform({
            ...posform,
            charged_to: row.id
        });
    };
    const handlesetslccategorie = (row) => {
        setposform({
            ...posform,
            categorie: row.id
        });
    };

    const handleaddingredients_items = (row) => {

        if (row.target.type == 2) {
            setvariation(row.target.value.variations);
        } else {
            let newitem = {
                id: 0,
                item_id: row.target.value.id,
                name: row.target.value.name,
                amount: 0,
                type: row.target.type,
                cost_unit: row.target.type == 2 ? 0 : row.target.value.cost,
                cost_total: 0,
            }
            let total = 0;
            if (datatablecard.datatablecard != undefined > datatablecard.datatablecard.length > 0) {
                let ingredientTemp = null; //to edit selected item
                let ingredient = []; //array to push order items

                datatablecard.datatablecard.map((newOrderItem, index) => {
                    if (index == 0) {
                        ingredient.push(newitem);
                    };

                    ingredientTemp = {
                        ...newOrderItem
                    };

                    ingredient.push(newOrderItem);
                    total = total + (newOrderItem.cost_unit * newOrderItem.amount)
                });

                setdatatablecard({ ...datatablecard, datatablecard: ingredient });
            } else {
                let ingredient = []; //array to push order items
                ingredient.push(newitem);
                total = total + (newitem.amount * newitem.cost_unit)
                setdatatablecard({ ...datatablecard, datatablecard: ingredient });
            }
            handledatatablecard(total);
        }
    };
    const handleonchangeamount = (e) => {
        console.log(datatablecard)
        if (datatablecard.datatablecard) {
            let ingredientTemp = null; //to edit selected item
            let ingredient = []; //array to push order items
            let total = 0;
            datatablecard.datatablecard.map((newOrderItem, index) => {
                if (index == e.target.name) {
                    total = total + (e.target.value * newOrderItem.cost_unit)

                    ingredientTemp = {
                        ...newOrderItem,
                        amount: e.target.value,
                        cost_total: e.target.value * newOrderItem.cost_unit
                    };
                    ingredient.push(ingredientTemp);

                } else {
                    total = total + (newOrderItem.cost_unit * newOrderItem.amount)
                    ingredient.push(newOrderItem);
                }
            });
            setdatatablecard({ ...datatablecard, datatablecard: ingredient });
            handledatatablecard(total);
        }
    };
    const handleDeleteitemcard = (removeIndex) => {
        //setLoading(true);
        let oldOrderItems = []; //array to push order items
        datatablecard.datatablecard.map((newOrderItem, index) => {
            if (index !== removeIndex) {
                //push item to orderlist
                oldOrderItems.push(newOrderItem);
            } else {
                if (newOrderItem.id != 0) {

                }

            }
        });
        setdatatablecard({ ...datatablecard, datatablecard: oldOrderItems });
    };
    const handleaddingredients_items_variation = (row) => {

        let newitem = {
            id: 0,
            item_id: row.target.value.food_item,
            name: row.target.value.food_item_name + "(" + row.target.value.name + ")",
            amount: 0,
            type: row.target.type,
            cost_unit: row.target.value.cost_total,
            cost_total: 0,
        }
        let total = 0;
        if (datatablecard.datatablecard != undefined > datatablecard.datatablecard.length > 0) {
            let ingredientTemp = null; //to edit selected item
            let ingredient = []; //array to push order items

            datatablecard.datatablecard.map((newOrderItem, index) => {
                if (index == 0) {
                    ingredient.push(newitem);
                };

                ingredientTemp = {
                    ...newOrderItem
                };

                ingredient.push(newOrderItem);
                total = total + (newOrderItem.cost_unit * newOrderItem.amount)
            });

            setdatatablecard({ ...datatablecard, datatablecard: ingredient });
        } else {
            let ingredient = []; //array to push order items
            ingredient.push(newitem);
            total = total + (newitem.amount * newitem.cost_unit)
            setdatatablecard({ ...datatablecard, datatablecard: ingredient });
        }
        setvariation([]);
        handledatatablecard(total);
    };
    const handledatatablecard = (total) => {
        setposform({ ...posform, total_cost: total });
    };
    const handlesetdataeditcard = (row) => {
        setdatatablecard({ ...datatablecard, datatablecard: row.items });
    };
    const handleeditform = (e) => {
        e.preventDefault();
        if (posform.charged_to == 0) {
            toast.error(`${_t(t("Ingresar Persona responsable"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        } else {
            let dt = {
                user_id: authUserInfo.details.id,
                branch_id: authUserInfo.details.branch_id,
                categorie: posform.categorie,
                nota: posform.nota,
                charged_to: posform.charged_to,
                amount: posform.amount,
                id: posform.id
            }
            if (posform.categorie == 0) {
                toast.error(`${_t(t("Favor agregar ingredientes o platillos"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            } else {
                const foodUnitUrl = BASE_URL + `/settings/post-gasto-edit`;

                return axios
                    .post(foodUnitUrl, dt, {
                        headers: { Authorization: `Bearer ${getCookie()}` },
                    })
                    .then((res) => {
                        toast.success(`${_t(t("Gasto almacenado"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                        makeHttpRequestWithPage(0);
                    })
                    .catch((error) => {

                    });
            }
        }
    };
    const handledeleteprevi = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="card card-body bg-primary text-white">
                        <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">
                            Esta seguro de eliminar este gasto
                        </p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-warning text-dark"
                                onClick={() => {
                                    handledeletei(id);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };
    const handledeletei = (id) => {
        let dt = {
            id: id
        }
        var foodUnitUrl = BASE_URL + `/settings/delete_expense`;

        return axios
        .post(foodUnitUrl, dt, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
            toast.success(`${_t(t("Gasto Eliminado"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
            });
            makeHttpRequestWithPage(0);
        })
        .catch((error) => {

        });

    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Gastos"))}</title>
            </Helmet>

            {/* Settle modal */}
            <div className="modal fade" id="modalcreate" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">Gastos</h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* if loading true show loading effect */}
                        <div className="modal-body">
                            <form onSubmit={btncreate ? handleposform : handleeditform} className="mx-2 sm-text">
                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                                        <div className="form-group">
                                            <div className="mb-2">
                                                <label className="control-label">
                                                    {_t(t("Persona Responsable"))}
                                                </label>
                                            </div>
                                            <div className="mb-2">
                                                <Select
                                                    isSearchable={false}
                                                    options={datausers}
                                                    components={makeAnimated()}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.name}
                                                    classNamePrefix="select"
                                                    className="xsm-text"
                                                    onChange={handlesetslccharged}
                                                    maxMenuHeight="200px"
                                                    placeholder={_t(t("Usuarios")) + ".."}
                                                    value={posform.charged_to ?
                                                        datausers && datausers.length > 0 ?
                                                            datausers.filter((item) => item.id == posform.charged_to)[0]
                                                            : null
                                                        : null
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ textAlign: "left" }}>
                                        <div className="col-md-6" style={{ textAlign: "left" }}>
                                            <div className="form-group">
                                                <div className="mb-2">
                                                    <label className="control-label">
                                                        {_t(t("Categoria"))}
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <Select
                                                        isSearchable={false}
                                                        options={datacategory}
                                                        components={makeAnimated()}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.name}
                                                        classNamePrefix="select"
                                                        className="xsm-text"
                                                        onChange={handlesetslccategorie}
                                                        maxMenuHeight="200px"
                                                        placeholder={_t(t("Categoria")) + ".."}
                                                        value={posform.categorie ?
                                                            datacategory && datacategory.length > 0 ?
                                                                datacategory.filter((item) => item.id == posform.categorie)[0]
                                                                : null
                                                            : null
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{ textAlign: "left" }}>
                                            <div className="form-group">
                                                <div className="mb-2">
                                                    <label className="control-label">
                                                        {_t(t("Cantidad"))}
                                                    </label>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="amount"
                                                    name="amount"
                                                    onChange={handleonchangueposform}
                                                    value={posform.amount}
                                                    placeholder="cantidad"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                                            <textarea
                                                className="form-control"
                                                placeholder="nota"
                                                name="nota"
                                                value={posform && posform.nota}
                                                onChange={handleonchangueposform}>
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                        </div>
                                        <div className="col-6">
                                            <button
                                                type="submit"
                                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                            >
                                                {btncreate ? 'Guardar' : 'Editar'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
            {/* Settle modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        <div className="col-12 t-mb-30 mb-lg-0">

                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {_t(t("Gastos"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <button className="btn btn-primary" type="button">
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                                <div className="form-file">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 form-control--light-1 rounded-0"
                                                        placeholder={
                                                            "Buscar" + ".."
                                                        }
                                                        onChange={(e) => {
                                                            /*setdataTablePagin({
                                                              ...dataTablePagin,
                                                              searchInput: e.target.value
                                                            });*/
                                                            handleSearch('searchInput', e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="t-list fk-sort align-items-center justify-content-center">
                                                <li className="fk-sort__list mb-0 flex-grow-1">
                                                    <button
                                                        className="w-100 btn btn-secondary sm-text text-uppercase"
                                                        data-toggle="modal"
                                                        data-target="#modalcreate"
                                                        onClick={() => {
                                                            setbtncreate(true);
                                                            setdatatablecard([]);
                                                            setposform({
                                                                id: 0,
                                                                user_id: "",
                                                                categorie: 0,
                                                                nota: "",
                                                                charged_to: 0,
                                                                amount: 0
                                                            });
                                                        }}
                                                    >
                                                        {_t(t("add new"))}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="col-md-6">
                                            <Select
                                                options={allusers.allusers}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                    /*setdataTablePagin({
                                                      ...dataTablePagin,
                                                      supplier_id: e.id
                                                    });*/
                                                    handleSearch('user_id', e.id);
                                                }}
                                                maxMenuHeight="200px"
                                                placeholder={
                                                    _t(t("Creado por")) + ".."
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Select
                                                options={allusers.allusers}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                    /*setdataTablePagin({
                                                      ...dataTablePagin,
                                                      supplier_id: e.id
                                                    });*/
                                                    handleSearch('charged_to', e.id);
                                                }}
                                                maxMenuHeight="200px"
                                                placeholder={
                                                    _t(t("Gastado por")) + ".."
                                                }
                                            />
                                        </div>
                                        <hr />
                                        <div className="col-md-4">
                                            <Select
                                                options={allcategorys.allcategorys}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                    /*setdataTablePagin({
                                                      ...dataTablePagin,
                                                      supplier_id: e.id
                                                    });*/
                                                    handleSearch('categorie_id', e.id);
                                                }}
                                                maxMenuHeight="200px"
                                                placeholder={
                                                    _t(t("Categoria")) + ".."
                                                }
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <ul className="t-list fk-sort align-items-center justify-content-end">
                                                <li className="fk-sort__list ml-2">
                                                    <DatePicker
                                                        selected={dataTablePagin.datestart}
                                                        onChange={(date) => {
                                                            /*setdataTablePagin({
                                                              ...dataTablePagin,
                                                              datestart: date
                                                            });*/
                                                            handleSearch('datestart', date);
                                                        }}
                                                        dateFormat={'dd-MM-yyyy'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="form-control xsm-text py-2"
                                                        placeholderText={_t(t("From date"))}
                                                        shouldCloseOnSelect={false}
                                                    />
                                                </li>
                                                <li className="fk-sort__list">
                                                    <span className="fk-sort__icon">
                                                        <span className="fa fa-long-arrow-right"></span>
                                                    </span>
                                                </li>
                                                <li className="fk-sort__list">
                                                    <DatePicker
                                                        selected={dataTablePagin.dateend}
                                                        onChange={(date) => {
                                                            /*setdataTablePagin({
                                                              ...dataTablePagin,
                                                              dateend: date
                                                            });*/
                                                            handleSearch('dateend', date);
                                                        }}
                                                        dateFormat={'dd-MM-yyyy'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="form-control xsm-text py-2"
                                                        placeholderText={_t(t("To date"))}
                                                        shouldCloseOnSelect={false}
                                                    />
                                                </li>
                                                <li class="fk-sort__list">
                                                    <button
                                                        class="btn btn-transparent btn-danger xsm-text text-uppercase py-2"
                                                        onClick={handleSearch}
                                                    >
                                                        {_t(t("Filter"))}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="col-md-6">
                                            <Select
                                                options={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }]}
                                                components={makeAnimated()}
                                                value={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }].filter(({ value }) => value === dataTablePagin.limit)}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                classNamePrefix="select"
                                                className="xsm-text"
                                                onChange={(e) => {
                                                    /*setdataTablePagin({
                                                      ...dataTablePagin,
                                                      limit: e.value
                                                    });*/
                                                    handleSearch('limit', e.value);
                                                }}
                                                maxMenuHeight="200px"
                                                placeholder={"Cantidad de filas"}
                                            />
                                        </div>
                                    </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("#"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Categoria
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Gastado por"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Total"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Nota"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Fecha
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Creado por
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Editar
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {dataTablePagin && dataTablePagin.data.map((item, index) => (
                                                            <tr
                                                                className="align-middle"
                                                                key={index}
                                                            >
                                                                <th
                                                                    scope="row"
                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                >
                                                                    {index + 1}
                                                                </th>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.categorie_name}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.charged_to_name}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    <span className="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                                        {item.amount}
                                                                    </span>
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.nota}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    <Moment format="LL">
                                                                        {item.created_at}
                                                                    </Moment>
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.create_name}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {
                                                                        <span
                                                                            className="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                                                            onClick={() => {
                                                                                handlesetdataeditcard(item)
                                                                                setbtncreate(false);
                                                                                setposform({
                                                                                    id: item.id,
                                                                                    user_id: "",
                                                                                    categorie: item.categorie,
                                                                                    nota: item.nota,
                                                                                    charged_to: item.charged_to,
                                                                                    amount: item.amount
                                                                                });
                                                                            }}
                                                                            data-toggle="modal"
                                                                            data-target="#modalcreate"
                                                                        >
                                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                                        </span>}
                                                                        {" "}
                                                                    {
                                                                        <span
                                                                            className="btn btn-transparent btn-danger xsm-text text-uppercase py-2"
                                                                            onClick={() => {
                                                                                handledeleteprevi(item.id)
                                                                            }}
                                                                        >
                                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                                        </span>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                            <div key="fragment4">
                                <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                    <div className="row align-items-center t-pl-15 t-pr-15">
                                        <div className="col-md-7 t-mb-15 mb-md-0">
                                            <nav aria-label="Page navigation example" style={{ float: 'left' }}>
                                                <ul className="pagination">Showing {dataTablePagin.current_page + 1} to {dataTablePagin.limit} of {dataTablePagin.total} entries</ul>
                                            </nav>
                                        </div>
                                        <div className="col-md-5">

                                            <nav aria-label="Page navigation example" style={{ float: 'right' }}>
                                                <ul className="pagination">
                                                    <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(0)}>First</a></li>
                                                    <li className={dataTablePagin.current_page == 0 ? "page-item disabled" : "page-item"} style={dataTablePagin.current_page == 1 ? {} : { cursor: "pointer" }} ><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.current_page - 1)}>Anterior</a></li>
                                                    {dataTablePagin.renderPageNumbers}
                                                    <li className={dataTablePagin.current_page == dataTablePagin.pages - 1 ? "page-item disabled" : "page-item"} style={dataTablePagin.current_page == dataTablePagin.total ? {} : { cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.current_page + 1)}>Siguiente</a></li>
                                                    <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.pages - 1)}>Last</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Gastos;
