import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ReportSidebar from "./ReportSidebar";

const Canceledorders = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    const {
        //submitted orders
        submittedOrders,
        setPaginatedSubmittedOrders,
        submittedOrdersForSearch,

        //payment-type
        paymentTypeForSearch,

        //pagination
        dataPaginating,
    } = useContext(RestaurantContext);

    const { t } = useTranslation();

    // States hook here
    // paidMoney
    const [paidMoney, setPaidMoney] = useState(0);
    //return
    const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });

    //search result
    const [searchedOrder, setSearchedOrder] = useState({
        list: null,
        searched: false,
    });

    const [dataget, setdataget] = useState(null);
    const [datagetForSearch, setdatagetForSearch] = useState(
        null
    );
    const [dataPaginatingcancelled, setDataPaginatingcancelled] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //useEffect == componentDidMount
    useEffect(() => {  }, []);

    const getdataget = () => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-canceled-orders";
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdataget(res.data);
                setdatagetForSearch(res.data);
                setLoading(false);
            });
    };
    const getdataget2 = () => {
        if (startDate !== null && endDate !== null) {
            setLoading(true);
            var fromDate = startDate.toISOString();
            var toDate = endDate.toISOString();
            let formData = {
                fromDate,
                toDate,
            };
            const url = BASE_URL + "/settings/get-canceled-orders2";
            return axios
                .post(url, formData, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    setdataget(res.data);
                    setdatagetForSearch(res.data);
                    setLoading(false);
                });
        } else {
            toast.error(
                `${_t(t("Seleccione rango de fecha"))}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        }
    };

    //payment type
    const handleSetpaymentType = (payment_type) => {
        setCheckOrderDetails({
            ...checkOrderDetails,
            payment_type,
        });

        //calculate paid amount to set return amount
        handleCalculatePaid(checkOrderDetails.payment_amount, payment_type);
    };

    //payment type amount
    const handlePaymentTypeAmount = (e) => {
        let tempPaymentAmount = {
            ...checkOrderDetails.payment_amount,
            [e.target.name]: e.target.value,
        };

        setCheckOrderDetails({
            ...checkOrderDetails,
            payment_amount: tempPaymentAmount,
        });

        //calculate paid amount to set return amount
        handleCalculatePaid(tempPaymentAmount, checkOrderDetails.payment_type);
    };

    //calculate paid amount
    const handleCalculatePaid = (paymentAmount, paymentType) => {
        let paidAmount = 0;
        if (paymentAmount !== null && paymentType !== null) {
            let thePaymentArray = [];
            if (paymentAmount) {
                thePaymentArray = Object.entries(paymentAmount);
            }
            thePaymentArray.map((eachPaymentItem) => {
                let thePaymentType = paymentType.find((paymentTypeItem) => {
                    return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
                });
                if (eachPaymentItem[1] !== "") {
                    if (
                        thePaymentType &&
                        thePaymentType.id === parseInt(eachPaymentItem[0])
                    ) {
                        paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
                    }
                }
            });
            let localCurrency = JSON.parse(localStorage.getItem("currency"));
            paidAmount = paidAmount / localCurrency.rate;
            let theReturnMoney = 0;
            if (checkOrderDetails.item) {
                theReturnMoney =
                    paidAmount - parseFloat(checkOrderDetails.item.total_payable);
            }
            setReturnMoneyUsd(theReturnMoney);
        } else {
            setReturnMoneyUsd(0);
        }
        setPaidMoney(paidAmount);
    };

    // handleSettleOrder
    const handleSettleOrder = (e) => {
        e.preventDefault();
        if (
            paidMoney <
            parseFloat(
                checkOrderDetails.item.total_payable -
                checkOrderDetails.item.paid_amount
            )
        ) {
            toast.error(
                `${_t(
                    t("Please enter paid amount atleast equal to the total due amount")
                )}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        } else {
            handleSettleOrderAxiosReq();
        }
    };

    //settle order server request
    const handleSettleOrderAxiosReq = () => {
        let url = BASE_URL + "/settings/settle-submitted-order";
        let localCurrency = JSON.parse(localStorage.getItem("currency"));
        let formData = {
            order_group_id: checkOrderDetails.item.id,
            payment_type: checkOrderDetails.payment_type,
            payment_amount: checkOrderDetails.payment_amount,
            paidMoney: paidMoney,
            localCurrency: localCurrency,
        };
        setLoading(true);
        axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                if (res.data !== "paymentIssue") {
                    setCheckOrderDetails({
                        ...checkOrderDetails,
                        item: res.data[2],
                        payment_type: null,
                        payment_amount: null,
                        settle: false,
                    });
                    setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(
                        `${_t(
                            t(
                                "Please enter paid amount atleast equal to the total due amount"
                            )
                        )}`,
                        {
                            position: "bottom-center",
                            closeButton: false,
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        }
                    );
                }
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    //search submitted orders here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedOrder({ ...searchedOrder, searched: false });
        } else {
            if (datagetForSearch && datagetForSearch.length > 0) {
                let searchedList = datagetForSearch.filter((item) => {
                    //token
                    let lowerCaseItemToken = JSON.stringify(formartid(item.id));

                    //customer
                    let lowerCaseItemCustomer = item.customer_name.toLowerCase();

                    //table
                    let lowerCaseItemTable = item.table_name.toLowerCase();

                    //branch
                    let lowerCaseItemBranch = item.branch_name.toLowerCase();
                    return (
                        lowerCaseItemToken.includes(searchInput) ||
                        lowerCaseItemCustomer.includes(searchInput) ||
                        lowerCaseItemTable.includes(searchInput) ||
                        (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
                    );
                });
                setSearchedOrder({
                    ...searchedOrder,
                    list: searchedList,
                    searched: true,
                });
            }
        }
    };

    //cancel order confirmation modal
    const handleCancelOrderConfirmation = (orderGroup) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">
                            {_t(t("You want to cancel this order?"))}
                        </p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleCancelOrder(orderGroup);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, cancel it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    //cancel order here
    const handleCancelOrder = (orderGroup) => {
        if (parseInt(orderGroup.is_accepted) === 0) {
            let url = BASE_URL + "/settings/cancel-submitted-order";
            let formData = {
                id: orderGroup.id,
            };
            setLoading(true);
            axios
                .post(url, formData, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data === "accepted") {
                        toast.error(
                            `${_t(t("Can not cancel this order, this is being cooked"))}`,
                            {
                                position: "bottom-center",
                                closeButton: false,
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            }
                        );
                    }
                })
                .catch(() => {
                    setLoading(false);
                    toast.error(`${_t(t("Please try again"))}`, {
                        position: "bottom-center",
                        closeButton: false,
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                });
        } else {
            toast.error(
                `${_t(t("Can not cancel this order, this is being cooked"))}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        }
    };
    const formartid = (id_order) => {
        var str = "" + id_order;
        var pad = "0000000";
        return pad.substring(0, pad.length - str.length) + str;
      };
    return (
        <>
            <Helmet>
                <title>{_t(t("Ordenes Canceladas"))}</title>
            </Helmet>

            {/* Settle modal */}
            <div className="modal fade" id="orderDetails" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {/* show order token on modal header */}
                                    {_t(t("Order details, "))}: #
                  {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* if loading true show loading effect */}
                        {loading ? (
                            <div className="modal-body">{modalLoading(5)}</div>
                        ) : (
                            <div className="modal-body">
                                {checkOrderDetails.item &&
                                    parseInt(checkOrderDetails.item.is_settled) === 0 ? /*(
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                        <div className="text-right">
                          {checkOrderDetails.settle &&
                            paidMoney >
                              parseFloat(
                                checkOrderDetails.item.total_payable
                              ) && (
                              <span className="mr-2 text-secondary font-weight-bold">
                                Return: {currencySymbolLeft()}
                                {formatPrice(returnMoneyUsd)}
                                {currencySymbolRight()}{" "}
                              </span>
                            )}
                          {checkOrderDetails.settle ? (
                            <button
                              className="btn btn-primary px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: false,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Cancel"))}
                            </button>
                          ) : (
                            <button
                              className="btn btn-success px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: true,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Settle order"))}
                            </button>
                          )}
                        </div>
                      )}
                  </>
                )*/ null : (
                                        // if this item is not settled then show settle-cancel button else, show this notification
                                        <div className="text-center bg-success text-white py-2">
                                            {_t(t("Order has been settled, you can close this now"))}
                                        </div>
                                    )}
                                {checkOrderDetails.item &&
                                    //show this if order is cancelled
                                    parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                                        <div className="text-center bg-secondary text-white py-2">
                                            {_t(t("This order has been cancelled"))}
                                        </div>
                                    )}
                                {/* show this if order settle is not true, if true show payment input field */}
                                {!checkOrderDetails.settle ? (
                                    <div className="col-12 filtr-item">
                                        <div className="fk-order-token t-bg-white">
                                            <div className="fk-order-token__body">
                                                <div className="fk-addons-table">
                                                    <div className="fk-addons-table__head text-center">
                                                        {_t(t("order "))}: #
                            {checkOrderDetails.item &&
                                                            formartid(checkOrderDetails.item.id)}
                                                    </div>
                                                    <div className="fk-addons-table__info">
                                                        <div className="row g-0">
                                                            <div className="col-2 text-center border-right">
                                                                <span className="fk-addons-table__info-text text-capitalize">
                                                                    {_t(t("S/L"))}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 text-center border-right">
                                                                <span className="fk-addons-table__info-text text-capitalize">
                                                                    {_t(t("food"))}
                                                                </span>
                                                            </div>
                                                            <div className="col-4 text-left pl-2 border-right">
                                                                <span className="fk-addons-table__info-text text-capitalize">
                                                                    {_t(t("Additional Info"))}
                                                                </span>
                                                            </div>
                                                            <div className="col-2 text-center border-right">
                                                                <span className="fk-addons-table__info-text text-capitalize">
                                                                    {_t(t("QTY"))}
                                                                </span>
                                                            </div>
                                                            <div className="col-1 text-center">
                                                                <span className="fk-addons-table__info-text text-capitalize">
                                                                    {_t(t("Status"))}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {checkOrderDetails.item &&
                                                        checkOrderDetails.item.orderedItems.map(
                                                            (thisItem, indexThisItem) => {
                                                                return (
                                                                    <div className="fk-addons-table__body-row">
                                                                        <div className="row g-0">
                                                                            <div className="col-2 text-center border-right d-flex">
                                                                                <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                    {indexThisItem + 1}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-3 text-center border-right d-flex">
                                                                                <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                    {thisItem.food_item} (
                                          {thisItem.food_group})
                                        </span>
                                                                            </div>
                                                                            <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                                                                {thisItem.variation !== null && (
                                                                                    <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                                                        <span className="font-weight-bold mr-1">
                                                                                            {_t(t("variation"))}:
                                            </span>
                                                                                        {thisItem.variation}
                                                                                    </span>
                                                                                )}

                                                                                {thisItem.properties !== null && (
                                                                                    <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                                                        <span className="font-weight-bold mr-1">
                                                                                            {_t(t("properties"))}:
                                            </span>
                                                                                        {JSON.parse(
                                                                                            thisItem.properties
                                                                                        ).map((propertyItem, thisIndex) => {
                                                                                            if (
                                                                                                thisIndex !==
                                                                                                JSON.parse(thisItem.properties)
                                                                                                    .length -
                                                                                                1
                                                                                            ) {
                                                                                                return (
                                                                                                    propertyItem.property + ", "
                                                                                                );
                                                                                            } else {
                                                                                                return propertyItem.property;
                                                                                            }
                                                                                        })}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-2 text-center border-right d-flex">
                                                                                <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                    {thisItem.quantity}
                                                                                </span>
                                                                            </div>

                                                                            <div className="col-1 text-center d-flex">
                                                                                <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                                                    <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                                                                        {parseInt(thisItem.is_cooking) ===
                                                                                            1 ? (
                                                                                            [
                                                                                                parseInt(thisItem.is_ready) ===
                                                                                                    1 ? (
                                                                                                    <i
                                                                                                        className="fa fa-check text-success"
                                                                                                        title={_t(t("Ready"))}
                                                                                                    ></i>
                                                                                                ) : (
                                                                                                    <i
                                                                                                        className="fa fa-cutlery text-secondary"
                                                                                                        title={_t(t("Cooking"))}
                                                                                                    ></i>
                                                                                                ),
                                                                                            ]
                                                                                        ) : (
                                                                                            <i
                                                                                                className="fa fa-times text-primary"
                                                                                                title={_t(t("Pending"))}
                                                                                            ></i>
                                                                                        )}
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="my-2 payment-type-parent">
                                        <Select
                                            options={paymentTypeForSearch && paymentTypeForSearch}
                                            components={makeAnimated()}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            classNamePrefix="select"
                                            className="xsm-text"
                                            backspaceRemovesValue={false}
                                            onChange={handleSetpaymentType}
                                            maxMenuHeight="200px"
                                            isMulti
                                            clearIndicator={null}
                                            placeholder={_t(t("Select payment methods")) + ".."}
                                        />
                                        {checkOrderDetails.payment_type !== null && (
                                            <form
                                                className="border my-2 change-background rounded-lg"
                                                onSubmit={handleSettleOrder}
                                            >
                                                <div className="sm-text text-center text-white py-2">
                                                    {_t(t("Amount"))}
                                                </div>
                                                {checkOrderDetails.payment_type.map(
                                                    (eachPaymentType, paymentTypeIndex) => {
                                                        return (
                                                            <div className="addons-list__item mx-2 mb-1">
                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    step="0.01"
                                                                    name={eachPaymentType.id}
                                                                    autoComplete="off"
                                                                    className="form-control xsm-text pl-2"
                                                                    onChange={handlePaymentTypeAmount}
                                                                    placeholder={eachPaymentType.name}
                                                                    value={
                                                                        checkOrderDetails.payment_amount &&
                                                                        checkOrderDetails.payment_amount[
                                                                        eachPaymentType.id
                                                                        ]
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                                <div className="pb-2 pl-2 my-2">
                                                    <button
                                                        className="btn btn-sm btn-warning text-dark px-3 text-uppercase"
                                                        type="submit"
                                                    >
                                                        {_t(t("Settle order"))}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                )}
                                <table className="table table-striped table-sm text-center mt-3">
                                    <thead className="bg-info text-white text-uppercase">
                                        <tr>
                                            <th scope="col" colSpan="2">
                                                {_t(t("Order details"))}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Received by"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.user_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Customer"))}</td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.customer_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Branch"))}</td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.branch_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Department"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.dept_tag_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Table"))}</td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.table_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Waiter"))}</td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.waiter_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.order_bill)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Vat"))}</td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.vat)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Service charge"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.service_charge)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Discount"))}</td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.discount)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Total bill"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.total_payable)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Paid amount"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.paid_amount)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        {checkOrderDetails.item &&
                                            parseFloat(
                                                checkOrderDetails.item.total_payable -
                                                checkOrderDetails.item.paid_amount
                                            ) >= 0 ? (
                                            <tr>
                                                <td className="text-capitalized">
                                                    {_t(t("Due amount"))}
                                                </td>
                                                <td>
                                                    {checkOrderDetails.item && (
                                                        <>
                                                            {currencySymbolLeft()}
                                                            {formatPrice(
                                                                parseFloat(
                                                                    checkOrderDetails.item.total_payable -
                                                                    checkOrderDetails.item.paid_amount
                                                                )
                                                            )}
                                                            {currencySymbolRight()}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td className="text-capitalized">
                                                    {_t(t("Return amount"))}
                                                </td>
                                                <td>
                                                    {checkOrderDetails.item && (
                                                        <>
                                                            {currencySymbolLeft()}
                                                            {formatPrice(
                                                                parseFloat(
                                                                    checkOrderDetails.item.paid_amount -
                                                                    checkOrderDetails.item.total_payable
                                                                )
                                                            )}
                                                            {currencySymbolRight()}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Settle modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>
                        <div className="col-9 t-mb-30 mb-lg-0">
                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {!searchedOrder.searched
                                                            ? _t(t("Ordenes Canceladas"))
                                                            : _t(t("Search Result"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row gx-2 mt-2">
                                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                <ul className="t-list fk-sort align-items-center">
                                                    <div className="input-group col">
                                                        <div className="form-file">
                                                            <input
                                                                type="text"
                                                                className="form-control border-0 form-control--light-1 rounded-0"
                                                                placeholder={
                                                                    _t(t("Busqueda por id, cliente")) +
                                                                    ".."
                                                                }
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                        <button className="btn btn-primary" type="button">
                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 ">
                                                <button
                                                    className="btn  btn-primary text-uppercase sm-text"
                                                    onClick={getdataget}
                                                >
                                                    {_t(t("Ver todos"))}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mt-2">
                                            <div className="col-12 col-md-2 d-md-block">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control sm-text py-2 t-mb-15 mb-md-0"
                                                    placeholderText={_t(t("From date"))}
                                                    shouldCloseOnSelect={false}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control sm-text py-2"
                                                    placeholderText={_t(t("To date"))}
                                                    shouldCloseOnSelect={false}
                                                />
                                            </div>

                                            <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                                                <button
                                                    className="btn btn-block btn-primary text-uppercase sm-text py-2"
                                                    onClick={getdataget2}
                                                >
                                                    {_t(t("Generate Report"))}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                            <div className="col-12 t-mb-15  text-right">
                                                <ReactHTMLTableToExcel
                                                    id="test-table-xls-button"
                                                    className="btn  btn-secondary text-uppercase sm-text"
                                                    table="table-to-xls"
                                                    filename="tablexls"
                                                    sheet="tablexls"
                                                    buttonText="Exportar a XLS" />
                                            </div>

                                        </div>

                                    </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("S/L"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("orden"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Customer"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Table"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Precio"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Motivo"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Status"))}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {!searchedOrder.searched
                                                            ? [
                                                                dataget && [
                                                                    dataget.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        dataget.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="align-middle"
                                                                                        key={index}
                                                                                    >
                                                                                        <th
                                                                                            scope="row"
                                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                                        >
                                                                                            {index + 1}
                                                                                        </th>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                            #{formartid(item.id)}
                                                                                        </td>

                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.customer_name}
                                                                                        </td>

                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.table_name}
                                                                                        </td>

                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            <span
                                                                                                className="btn btn-primary xsm-text text-capitalize">
                                                                                                {currencySymbolLeft()}
                                                                                                {formatPrice(item.total_payable)}
                                                                                                {currencySymbolRight()}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.description}
                                                                                        </td>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {parseInt(item.is_cancelled) ===
                                                                                                0 ? (
                                                                                                [
                                                                                                    parseInt(item.is_ready) ===
                                                                                                        0 ? (
                                                                                                        <span
                                                                                                            className="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                                                                                            onClick={() => {
                                                                                                                setCheckOrderDetails({
                                                                                                                    ...checkOrderDetails,
                                                                                                                    item: item,
                                                                                                                    settle: false,
                                                                                                                });
                                                                                                                setReturnMoneyUsd(0);
                                                                                                                setPaidMoney(0);
                                                                                                            }}
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#orderDetails"
                                                                                                        >
                                                                                                            {_t(t("processing"))}
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span
                                                                                                            className="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                                                                                            onClick={() => {
                                                                                                                setCheckOrderDetails({
                                                                                                                    ...checkOrderDetails,
                                                                                                                    item: item,
                                                                                                                    settle: false,
                                                                                                                });
                                                                                                                setReturnMoneyUsd(0);
                                                                                                                setPaidMoney(0);
                                                                                                            }}
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#orderDetails"
                                                                                                        >
                                                                                                            {_t(t("Ready"))}
                                                                                                        </span>
                                                                                                    ),
                                                                                                ]
                                                                                            ) : (
                                                                                                <span
                                                                                                    className="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                                                                                    onClick={() => {
                                                                                                        setCheckOrderDetails({
                                                                                                            ...checkOrderDetails,
                                                                                                            item: item,
                                                                                                            settle: false,
                                                                                                        });
                                                                                                        setReturnMoneyUsd(0);
                                                                                                        setPaidMoney(0);
                                                                                                    }}
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#orderDetails"
                                                                                                >
                                                                                                    {_t(t("Cancelled"))}
                                                                                                </span>
                                                                                            )}
                                                                                        </td>

                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    ),
                                                                ],
                                                            ]
                                                            : [
                                                                /* searched data, logic === haveData*/
                                                                searchedOrder && [
                                                                    searchedOrder.list.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        searchedOrder.list.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={index}
                                                                                >
                                                                                    <th
                                                                                        scope="row"
                                                                                        className="xsm-text text-capitalize align-middle text-center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </th>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        #{formartid(item.id)}
                                                                                    </td>

                                                                                    <td className="xsm-text align-middle text-center">
                                                                                        {item.customer_name}
                                                                                    </td>

                                                                                    <td className="xsm-text align-middle text-center">
                                                                                        {item.table_name}
                                                                                    </td>

                                                                                    <td className="xsm-text align-middle text-center">
                                                                                        <span
                                                                                            className="btn btn-primary xsm-text text-capitalize">
                                                                                            {currencySymbolLeft()}
                                                                                            {formatPrice(item.total_payable)}
                                                                                            {currencySymbolRight()}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="xsm-text align-middle text-center">
                                                                                        {item.description}
                                                                                    </td>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center">
                                                                                        {parseInt(item.is_cancelled) ===
                                                                                            0 ? (
                                                                                            [
                                                                                                parseInt(item.is_ready) ===
                                                                                                    0 ? (
                                                                                                    <span
                                                                                                        className="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                                                                                        onClick={() => {
                                                                                                            setCheckOrderDetails({
                                                                                                                ...checkOrderDetails,
                                                                                                                item: item,
                                                                                                                settle: false,
                                                                                                            });
                                                                                                            setReturnMoneyUsd(0);
                                                                                                            setPaidMoney(0);
                                                                                                        }}
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#orderDetails"
                                                                                                    >
                                                                                                        {_t(t("processing"))}
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <span
                                                                                                        className="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                                                                                        onClick={() => {
                                                                                                            setCheckOrderDetails({
                                                                                                                ...checkOrderDetails,
                                                                                                                item: item,
                                                                                                                settle: false,
                                                                                                            });
                                                                                                            setReturnMoneyUsd(0);
                                                                                                            setPaidMoney(0);
                                                                                                        }}
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#orderDetails"
                                                                                                    >
                                                                                                        {_t(t("Ready"))}
                                                                                                    </span>
                                                                                                ),
                                                                                            ]
                                                                                        ) : (
                                                                                            <span
                                                                                                className="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                                                                                onClick={() => {
                                                                                                    setCheckOrderDetails({
                                                                                                        ...checkOrderDetails,
                                                                                                        item: item,
                                                                                                        settle: false,
                                                                                                    });
                                                                                                    setReturnMoneyUsd(0);
                                                                                                    setPaidMoney(0);
                                                                                                }}
                                                                                                data-toggle="modal"
                                                                                                data-target="#orderDetails"
                                                                                            >
                                                                                                {_t(t("Cancelled"))}
                                                                                            </span>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ),
                                                                ],
                                                            ]}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* pagination loading effect */}
                            {checkOrderDetails.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedOrder.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}

                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                                                <span className="d-inline-block sm-text">
                                                                    {showingData(dataget)}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() =>
                                                                    setSearchedOrder({
                                                                        ...searchedOrder,
                                                                        searched: false,
                                                                    })
                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                                            <span className="d-inline-block sm-text">
                                                                {searchedShowingData(
                                                                    searchedOrder,
                                                                    datagetForSearch
                                                                )}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Canceledorders;
