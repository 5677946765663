import React, { useState, useContext, useEffect, useRef } from "react";


//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    getCookie,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
    currencyName,
    getSystemSettings,
    currencyNameSecondary,
    currencyOperationSecondary,
    currencySymbolLeftSecondary,
} from "../../../../functions/Functions";
import { SettingsContext } from "../../../../contexts/Settings";
import { useTranslation } from "react-i18next";
import moment from 'moment'
//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import "moment/locale/es-us";
import { useReactToPrint } from "react-to-print";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import ReportSidebar from "./ReportSidebar";

const OrderHistories = () => {
    //getting context values here
    const { authUserInfo } = useContext(UserContext);
    const {
        //common
        generalSettings,
    } = useContext(SettingsContext);

    const {
        //branch
        branchForSearch,

        //order histories
        allOrders,
        setPaginatedAllOrders,
        allOrdersForSearch,

        //pagination
        dataPaginating,
        setDataPaginating,
    } = useContext(RestaurantContext);

    const {
        //common
        loading,
        setLoading,
    } = useContext(FoodContext);

    const { t } = useTranslation();

    // States hook here
    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });
    //search result
    let [searchedOrders, setSearchedOrders] = useState({
        list: null,
        searched: false,
        branch: null,
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [datasettled, setDataSettled] = useState({
        data: [],
        total: 0,
        pages: 0,
        limit: 10,
        current_page: 1,
        pageNumbers: [],
        renderPageNumbers: [],
        searchInput: "",
        searchedBranch: null,
        datestart: null,
        dateend: null
    });

    const [datadetallecajaprint, setdatadetallecajaprint] = useState(
        {
            aperturado_por: "Bagents",
            cash_data_cordobas: [{ "name": "C$ - 1000", "value": 1000, "valuefron": "1", "total": 1000 }, { "name": "C$ - 500", "value": 500, "valuefron": 0, "total": 0 }, { "name": "C$ - 200", "value": 200, "valuefron": 0, "total": 0 }, { "name": "C$ - 100", "value": 100, "valuefron": 0, "total": 0 }, { "name": "C$ - 50", "value": 50, "valuefron": 0, "total": 0 }, { "name": "C$ - 20", "value": 20, "valuefron": 0, "total": 0 }, { "name": "C$ - 10", "value": 10, "valuefron": 0, "total": 0 }, { "name": "C$ - 5", "value": 5, "valuefron": 0, "total": 0 }, { "name": "C$ - 1", "value": 1, "valuefron": 0, "total": 0 }],
            cash_data_dolares: [{ "name": "$ - 1000", "value": 1000, "valuefron": "1", "total": 1000 }, { "name": "$ - 500", "value": 500, "valuefron": 0, "total": 0 }, { "name": "$ - 200", "value": 200, "valuefron": 0, "total": 0 }, { "name": "$ - 100", "value": 100, "valuefron": 0, "total": 0 }, { "name": "$ - 50", "value": 50, "valuefron": 0, "total": 0 }, { "name": "$ - 20", "value": 20, "valuefron": 0, "total": 0 }, { "name": "$ - 10", "value": 10, "valuefron": 0, "total": 0 }, { "name": "$ - 5", "value": 5, "valuefron": 0, "total": 0 }, { "name": "$ - 1", "value": 1, "valuefron": 0, "total": 0 }],
            cerrado_por: "Bagents",
            fecha_creada: "2021-03-15 16:13:50",
            id_caja: 11,
            note: "test note cerrado",
            pago_clientes: null,
            pago_proveedores: null,
            saldo_inicial_cordobas: 1000,
            saldo_inicial_dolares: 200,
            tasa_cambio: 35.5,
            total_dolares: null,
            total_gastos: 130,
            total_tarjetas: null,
            total_ventas: 102,
            total_cambio: 0,
            verificado_por_id: "Luis Martinez"
        }
    );
    const [datadetallecaja, setdatadetallecaja] = useState({
        id_caja: 0,
        pago_clientes: 0,
        pago_proveedores: 0,
        saldo_inicial_cordobas: 0,
        saldo_inicial_dolares: 0,
        tasa_cambio: 0,
        total_tarjetas: 0,
        total_ventas: 0,
        total_dolares: 0,
        total_cambio: 0
    });

    //useEffect == componentDidMount
    useEffect(() => { makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch); }, []);

    //cancel order confirmation modal
    const handleDeleteOrderConfirmation = (orderGroup) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">
                            {_t(t("You want to delete this order?"))}
                        </p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleDeleteOrder(orderGroup);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, delete it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    //cancel order here
    const handleDeleteOrder = (orderGroup) => {
        let url = BASE_URL + "/settings/delete-order-from-history";
        let formData = {
            id: orderGroup.id,
        };
        setLoading(true);
        axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then(() => {
                setLoading(false);
                setSearchedOrders({
                    ...searchedOrders,
                    searched: false,
                });
                toast.success(`${_t(t("Deleted successfully"))}`, {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch);
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };
    //search submitted orders here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        makeHttpRequestWithPage(0, datasettled.limit, searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch);
    };
    //branch wise filter
    const handleBranchFilter = (branch) => {
        setDataSettled({
            ...datasettled,
            searchedBranch: branch.id
        });
        makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, branch.id);
    };

    //date wise filter
    const handleDateFilter = () => {

        makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch)

    };
    const makeHttpRequestWithPage = async (pageNumber, limit, searchInput, datestart, dateend, searchedBranch) => {
        const response = await fetch(`${BASE_URL}/settings/get-boxreports?&page=${pageNumber}&limit=${limit}&search=${searchInput}&datestart=${datestart == null ? null : moment(datestart).format('YYYY-MM-DD')}&dateend=${dateend == null ? null : moment(dateend).format('YYYY-MM-DD')}&searchedBranch=${searchedBranch}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie()}`
            },
        });
        const data = await response.json();
        const pageNumbers = [];
        let renderPageNumbers;
        if (data.total !== null) {
            for (let i = 1; i <= data.pages; i++) {
                pageNumbers.push(i);
            }

            renderPageNumbers = pageNumbers.map(number => {
                let classes = data.current_page === number - 1 ? "page-item active" : 'page-item';
                if (number == 1 || number == data.total || (number >= data.current_page - 2 && number <= data.current_page + 2)) {
                    return (
                        <li key={number - 1} className={classes} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(number - 1, limit, searchInput, datestart, dateend, searchedBranch)}>{number}</a></li>
                    );
                }
            });
        }
        setDataSettled({
            ...datasettled,
            data: data.data,
            total: data.total,
            pages: data.pages,
            limit: limit,
            searchInput: searchInput,
            pageNumbers: pageNumbers,
            renderPageNumbers: renderPageNumbers,
            dateend: dateend,
            datestart: datestart,
            searchedBranch: searchedBranch,
            current_page: data.current_page
        });
    }
    const handlesetslccharged2 = (row) => {
        setDataSettled({
            ...datasettled,
            limit: row.value
        });
        setDataSettled({ ...datasettled, limit: row.value });
        makeHttpRequestWithPage(0, row.value, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch);
    };
    const datestart_ = (row) => {
        setDataSettled({
            ...datasettled,
            datestart: row
        });
        if (datasettled.dateend != null) {
            makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, row, datasettled.dateend, datasettled.searchedBranch);
        }
    };
    const dateend_ = (row) => {
        setDataSettled({
            ...datasettled,
            dateend: row
        });
        if (datasettled.datestart != null) {
            makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, datasettled.datestart, row, datasettled.searchedBranch);
        }
    };
    const formartid = (id_order) => {
        var str = "" + id_order;
        var pad = "0000000";
        return pad.substring(0, pad.length - str.length) + str;
    };

    const componentRef = useRef();

    const handlebaucher = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            /* if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
               handleOnlyPrint2();
             }*/
        },
    });
    return (
        <>
            <Helmet>
                <title>{_t(t("Order history"))}</title>
            </Helmet>

            <div className="d-none">
                <div ref={componentRef}>
                    {
                        <div className="fk-print t-pt-30 t-pb-30">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="d-block fk-print-text fk-print-text--bold text-uppercase fk-print__title text-center mb-2">
                                            {getSystemSettings(generalSettings, "siteName")}
                                        </span>
                                        <p className="mb-0 xsm-text fk-print-text text-center text-capitalize">
                                            {getSystemSettings(generalSettings, "address")}
                                        </p>
                                        <p className="mb-0 xsm-text fk-print-text text-center text-capitalize">
                                            {_t(t("call"))}:{" "}
                                            {getSystemSettings(generalSettings, "phnNo")}
                                        </p>
                                        <hr className="mb-0" />
                                        <hr className="m-0" />
                                        {datadetallecajaprint ?
                                            <>
                                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered table-hover min-table-height mt-4">
                                                                <thead className="align-middle">
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            colSpan="2"
                                                                            className="text-capitalize text-center"

                                                                        >
                                                                            Caja Creada: &nbsp;
                                                                            < Moment format="DD-MM-YYYY hh:mm:ss">{datadetallecajaprint && datadetallecajaprint.fecha_creada}</Moment>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="text-capitalize"

                                                                        >
                                                                            {_t(t("Descripcion"))}
                                                                        </th>

                                                                        <th
                                                                            scope="col"
                                                                            className="text-capitalize"

                                                                        >
                                                                            {_t(t("Valor"))}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="align-middle">
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"

                                                                    >
                                                                        <td className="d-block">Efectivo Inicial {currencyName()}</td>
                                                                        <td className="xsm-text align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.saldo_inicial_cordobas)}
                                                                            {currencySymbolRight()}

                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"

                                                                    >
                                                                        <td className="d-block">Inicial Caja {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " + formatPrice(datadetallecajaprint && (datadetallecajaprint.saldo_inicial_dolares)) + ")"}  </td>
                                                                        <td className="xsm-text align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio))}
                                                                            {currencySymbolRight()}

                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"

                                                                    >
                                                                        <td className="d-block">Inicial Caja ({currencyName()} + {currencyNameSecondary()}) </td>
                                                                        <td className="xsm-text align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.saldo_inicial_cordobas + (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio))}
                                                                            {currencySymbolRight()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Inicial Caja</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.saldo_inicial_cordobas)}
                                                                            {currencySymbolRight()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Total de Ventas {currencyName()}</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && (datadetallecaja.pago_clientes + datadetallecajaprint.total_ventas - datadetallecajaprint.total_cambio))}
                                                                            {currencySymbolRight()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Total de Ventas en {currencySymbolLeftSecondary() + " (" + currencySymbolLeftSecondary() + " " + (currencyOperationSecondary() == "/" ? datadetallecajaprint.total_dolares / datadetallecajaprint.tasa_cambio : datadetallecajaprint.total_dolares * datadetallecajaprint.tasa_cambio) + ")"}</td>
                                                                        <td className="xsm-text text-capitalize align-middle">

                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.total_dolares)}
                                                                            {currencySymbolRight()}


                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Total Pago Proveedores</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.pago_proveedores)}
                                                                            {currencySymbolRight()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Total Tarjetas</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.total_tarjetas)}
                                                                            {currencySymbolRight()}

                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Total Gastos</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecajaprint.total_gastos)}
                                                                            {currencySymbolRight()}


                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"

                                                                    >
                                                                        <td className="d-block">Efectivo Final {currencyName()}</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && datadetallecaja.pago_clientes + datadetallecajaprint.saldo_inicial_cordobas + datadetallecajaprint.total_ventas - datadetallecajaprint.total_cambio)}
                                                                            {currencySymbolRight()}

                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"

                                                                    >
                                                                        <td className="d-block">Final {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " + formatPrice(datadetallecajaprint && (datadetallecajaprint.saldo_inicial_dolares) + (currencyOperationSecondary() == "/" ? datadetallecajaprint.total_dolares / datadetallecajaprint.tasa_cambio : datadetallecajaprint.total_dolares * datadetallecajaprint.tasa_cambio)) + ")"}  </td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {formatPrice(datadetallecajaprint && (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio) + datadetallecajaprint.total_dolares)}
                                                                            {currencySymbolRight()}

                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Total Operacion {currencyName()}</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {
                                                                                datadetallecajaprint ?


                                                                                    formatPrice(
                                                                                        datadetallecajaprint.saldo_inicial_cordobas +
                                                                                        (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio) +
                                                                                        datadetallecajaprint.total_ventas +
                                                                                        datadetallecajaprint.total_dolares +
                                                                                        datadetallecajaprint.pago_clientes -
                                                                                        datadetallecajaprint.pago_proveedores -
                                                                                        datadetallecajaprint.total_gastos - datadetallecajaprint.total_cambio) : 0
                                                                            }
                                                                            {currencySymbolRight()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        scope="row"
                                                                        className="xsm-text align-middle"
                                                                    >
                                                                        <td className="d-block">Diferencia de Efectivo {currencyName()}</td>
                                                                        <td className="xsm-text text-capitalize align-middle">
                                                                            {currencySymbolLeft()}
                                                                            {
                                                                                datadetallecajaprint ?
                                                                                    formatPrice(
                                                                                        (
                                                                                            datadetallecajaprint.saldo_inicial_cordobas +
                                                                                            (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio) +
                                                                                            datadetallecajaprint.total_ventas +
                                                                                            datadetallecajaprint.total_dolares +
                                                                                            datadetallecajaprint.pago_clientes -
                                                                                            datadetallecajaprint.pago_proveedores -
                                                                                            datadetallecajaprint.total_gastos - datadetallecajaprint.total_cambio
                                                                                        ) - datadetallecajaprint.total_cash_submitted_cordobas - (currencyOperationSecondary() == "/" ? datadetallecajaprint.total_cash_submitted_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.total_cash_submitted_dolares / datadetallecajaprint.tasa_cambio)
                                                                                    ) : 0
                                                                            }
                                                                            {currencySymbolRight()}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    {datadetallecajaprint.cash_data_cordobas != undefined && datadetallecajaprint.cash_data_cordobas.length > 0 ?
                                                        datadetallecajaprint.cash_data_cordobas.map((item, index) => {
                                                            return (
                                                                <div className="col-md-6">
                                                                    <label className="control-label">
                                                                        {item.name + " * " + item.valuefron + " = " + item.total}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                    }
                                                    <hr />
                                                    {datadetallecajaprint.cash_data_dolares != undefined && datadetallecajaprint.cash_data_dolares.length > 0 ?
                                                        datadetallecajaprint.cash_data_dolares.map((item, index) => {
                                                            return (
                                                                <div className="col-md-6">
                                                                    <label className="control-label">
                                                                        {item.name + " * " + item.valuefron + " = " + item.total}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                    }

                                                </div>
                                                <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-md-12">
                                                        {datadetallecajaprint.note}
                                                    </div>

                                                </div>
                                                <div className="row" style={{ marginTop: "30px" }}>
                                                    <div className="col-md-12" style={{ textAlign: "center" }}>
                                                        <span className="d-block">_________________________</span>
                                                        <span className="d-block">Aperturado Por</span>
                                                        <span className="d-block">{datadetallecajaprint.aperturado_por}</span>
                                                    </div>

                                                </div>
                                                <div className="row" style={{ marginTop: "30px" }}>
                                                    <div className="col-md-12" style={{ textAlign: "center" }}>
                                                        <span className="d-block">_________________________</span>
                                                        <span className="d-block">Cerrado Por:</span>
                                                        <span className="d-block">{datadetallecajaprint.cerrado_por}</span>
                                                    </div>

                                                </div>
                                                <div className="row" style={{ marginTop: "30px" }}>
                                                    <div className="col-md-12" style={{ textAlign: "center" }}>
                                                        <span className="d-block">_________________________</span>
                                                        <span className="d-block">Verificado Por:</span>
                                                        <span className="d-block">{datadetallecajaprint.verificado_por}</span>
                                                    </div>
                                                </div>
                                            </>
                                            : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container-fluid">
                    <div className="row t-mt-10 gx-2">
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>

                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide"
                                    data-simplebar
                                >
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                            {/* large screen 
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-uppercase">
                                                        {searchedOrders.searched === false
                                                            ? _t(t("Order history"))
                                                            : _t(t("Filtered order history"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="input-group">
                                                <button className="btn btn-primary" type="button">
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                                <div className="form-file">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 form-control--light-1 rounded-0"
                                                        placeholder={
                                                            _t(t("Search by token, customer, branch")) + ".."
                                                        }
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                       */}
                                        <div className="col-md-8 col-lg-9 t-mb-15 mb-md-0 d-md-block">
                                            <ul className="t-list fk-sort align-items-center justify-content-end">
                                                  {/* large screen 
                                                {authUserInfo.details !== null &&
                                                    authUserInfo.details.user_type !== "staff" && (
                                                        <li
                                                            className="fk-sort__list "
                                                            style={{ minWidth: "150px" }}
                                                        >
                                                            <Select
                                                                options={branchForSearch && branchForSearch}
                                                                components={makeAnimated()}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.name}
                                                                className="xsm-text"
                                                                onChange={handleBranchFilter}
                                                                maxMenuHeight="200px"
                                                                placeholder={_t(t("Select branch")) + ".."}
                                                            />
                                                        </li>
                                                    )} */}
                                                <li className="fk-sort__list ml-2">
                                                    <DatePicker
                                                        selected={datasettled.datestart}
                                                        onChange={(datee) => datestart_(datee)}
                                                        dateFormat={'dd-MM-yyyy'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="form-control xsm-text py-2"
                                                        placeholderText={_t(t("From date"))}
                                                        shouldCloseOnSelect={false}
                                                    />
                                                </li>
                                                <li className="fk-sort__list">
                                                    <span className="fk-sort__icon">
                                                        <span className="fa fa-long-arrow-right"></span>
                                                    </span>
                                                </li>
                                                <li className="fk-sort__list">
                                                    <DatePicker
                                                        selected={datasettled.dateend}
                                                        onChange={(date) => dateend_(date)}
                                                        dateFormat={'dd-MM-yyyy'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="form-control xsm-text py-2"
                                                        placeholderText={_t(t("To date"))}
                                                        shouldCloseOnSelect={false}
                                                    />
                                                </li>
                                                <li class="fk-sort__list">
                                                    <button
                                                        class="btn btn-transparent btn-danger xsm-text text-uppercase py-2"
                                                        onClick={handleDateFilter}
                                                    >
                                                        {_t(t("Filter"))}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                      
                                    </div>
                                    <div className="col-md-2">
                                            <Select
                                                options={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }]}
                                                components={makeAnimated()}
                                                value={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }].filter(({ value }) => value === datasettled.limit)}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                classNamePrefix="select"
                                                className="xsm-text"
                                                onChange={handlesetslccharged2}
                                                maxMenuHeight="200px"
                                                placeholder={"Cantidad de filas"}
                                            />
                                        </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("S/L"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Aperturado
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Vereficado
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Cerrado
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Total Ventas
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Total Operación"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Diferencia de Efectivo"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Imprimir
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Creada"))}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {datasettled && datasettled.data && datasettled.data.map((item, index) => (
                                                            <tr
                                                                className="align-middle"
                                                                key={index}
                                                            >
                                                                <th
                                                                    scope="row"
                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                >
                                                                    {index + 1 + (datasettled.current_page) * datasettled.limit}
                                                                </th>
                                                                <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                    {item.aperturado_por}
                                                                </td>

                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.verificado_por}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.cerrado_por}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {currencySymbolLeft()}
                                                                    {formatPrice(item && (datadetallecaja.pago_clientes + item.total_ventas - item.total_cambio))}
                                                                    {currencySymbolRight()}
                                                                </td>
                                                                <td className="xsm-text align-middle text-center">
                                                                    {currencySymbolLeft()}
                                                                    {
                                                                        item ?
                                                                            formatPrice(
                                                                                item.saldo_inicial_cordobas +
                                                                                (currencyOperationSecondary() == "/" ? item.saldo_inicial_dolares * item.tasa_cambio : item.saldo_inicial_dolares / item.tasa_cambio) +
                                                                                item.total_ventas +
                                                                                item.total_dolares +
                                                                                item.pago_clientes -
                                                                                item.pago_proveedores -
                                                                                item.total_gastos - item.total_cambio) : 0
                                                                    }
                                                                    {currencySymbolRight()}
                                                                </td>
                                                                <td className="xsm-text align-middle text-center">
                                                                    {currencySymbolLeft()}
                                                                    {
                                                                        item ?
                                                                            formatPrice(
                                                                                (
                                                                                    item.saldo_inicial_cordobas +
                                                                                    (currencyOperationSecondary() == "/" ?
                                                                                        item.saldo_inicial_dolares * item.tasa_cambio :
                                                                                        item.saldo_inicial_dolares / item.tasa_cambio) +
                                                                                    item.total_ventas +
                                                                                    item.total_dolares +
                                                                                    item.pago_clientes -
                                                                                    item.pago_proveedores -
                                                                                    item.total_gastos -
                                                                                    item.total_cambio
                                                                                )
                                                                                - item.total_cash_submitted_cordobas - (currencyOperationSecondary() == "/" ? item.total_cash_submitted_dolares * item.tasa_cambio : item.total_cash_submitted_dolares / item.tasa_cambio)
                                                                            ) : 0
                                                                    }
                                                                    {currencySymbolRight()}
                                                                </td>
                                                                <td class="xsm-text text-capitalize align-middle text-center">

                                                                    <button
                                                                        className="btn btn-success sm-text text-uppercase"
                                                                        type="buttom"
                                                                        onClick={() => {
                                                                            setdatadetallecajaprint(item);
                                                                            setTimeout(() => handlebaucher(), 1000);
                                                                        }}
                                                                    >
                                                                        <span

                                                                        ><i class="fa fa-file-text-o"></i></span>
                                                                    </button>
                                                                </td>

                                                                <td className="xsm-text align-middle text-center">
                                                                    < Moment format="DD-MM-YYYY hh:mm:ss">{item && item.fecha_creada}</Moment>

                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div key="fragment4">
                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                <div className="row align-items-center t-pl-15 t-pr-15">
                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                        <nav aria-label="Page navigation example" style={{ float: 'left' }}>
                                            <ul className="pagination">Showing {datasettled.current_page + 1} to {datasettled.limit} of {datasettled.total} entries</ul>
                                        </nav>
                                    </div>
                                    <div className="col-md-5">
                                        <nav aria-label="Page navigation example" style={{ float: 'right' }}>
                                            <ul className="pagination">
                                                <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch)}>First</a></li>
                                                <li className={datasettled.current_page == 0 ? "page-item disabled" : "page-item"} style={datasettled.current_page == 1 ? {} : { cursor: "pointer" }} ><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.current_page - 1, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch)}>Previous</a></li>
                                                {datasettled.renderPageNumbers}
                                                <li className={datasettled.current_page == datasettled.pages - 1 ? "page-item disabled" : "page-item"} style={datasettled.current_page == datasettled.total ? {} : { cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.current_page + 1, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch)}>Next</a></li>
                                                <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.pages - 1, datasettled.limit, datasettled.searchInput, datasettled.datestart, datasettled.dateend, datasettled.searchedBranch)}>Last</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default OrderHistories;
