import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { FoodContext } from "../../../../contexts/Food";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ReportSidebar from "./ReportSidebar";
import DatePicker from "react-datepicker";
const Visitas = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);
    const [totaldata, settotaldata] = useState(0);
    const [totaldatadely, settotaldatadely] = useState(0);
    const [totalfac, settotalfac] = useState(0);
    const {
        dataPaginating,
    } = useContext(RestaurantContext);
    let { adminStaffForSearch } = useContext(UserContext);

    const { t } = useTranslation();

    // States hook here
    // paidMoney
    const [paidMoney, setPaidMoney] = useState(0);
    //return
    const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);
    const [theUser, setTheUser] = useState(null);
    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });
    const {
        cajadetalle
    } = useContext(FoodContext);
    //search result
    const [searchedOrder, setSearchedOrder] = useState({
        list: null,
        searched: false,
    });

    const [dataget, setdataget] = useState(null);
    const [datagetForSearch, setdatagetForSearch] = useState(
        null
    );

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [dataPaginatingcancelled, setDataPaginatingcancelled] = useState(false);
    const [datatableitems, setdatatableitems] = useState(null);
    const [datatableitemsOrdersForSearch, setdatatableitemsOrdersForSearch] = useState(null);

    //useEffect == componentDidMount
    useEffect(() => { }, []);

    const getdatatableitems = () => {
        setLoading(true);
        const url = BASE_URL + "/settings/reportevisitas";
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdatatableitems(res.data[0]);
                setdatatableitemsOrdersForSearch(res.data[1]);
                setLoading(false);
            });
    };
    const setPaginateddatatableitems = (pageNo) => {

        let formData = {};
        if (startDate !== null && endDate !== null) {
            var fromDate = startDate.toISOString();
            var toDate = endDate.toISOString();
            formData = {
                fromDate,
                toDate
            };
        } else {
            var fromDate = '';
            var toDate = '';
            formData = {
                fromDate,
                toDate
            };
        }
        setDataPaginatingcancelled(true);
        const url = BASE_URL + "/settings/reportevisitas?page=" + pageNo;
        return axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdatatableitems(res.data[0]);
                setdatatableitemsOrdersForSearch(res.data[1]);
                setDataPaginatingcancelled(false);
            })
            .catch(() => { });
    };


    const settotal = (data) => {
        let total = 0;
        let total2 = 0;
        let total3 = 0;
        data.map((propertyItem, thisIndex) => {
            total = parseFloat(total) + parseFloat(propertyItem.propina_);
            total2 = parseFloat(total2) + parseFloat(propertyItem.service_charge);
            total3 = parseFloat(total3) + parseFloat(propertyItem.total_payable);
        })
        settotaldata(total);
        settotaldatadely(total2)
        settotalfac(total3)
    };

    const handleUser = (user) => {
        setTheUser(user);
    };
    const getdataget = () => {

        setStartDate(null);
        setEndDate(null)
        var fromDate = '';
        var toDate = '';
        let formData = {
            fromDate,
            toDate
        };

        setDataPaginatingcancelled(true);
        const url = BASE_URL + "/settings/reportevisitas?page=" + 1;
        return axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdatatableitems(res.data[0]);
                setdatatableitemsOrdersForSearch(res.data[1]);
                setDataPaginatingcancelled(false);
            })
            .catch(() => { });
    };
    const getdataget2 = () => {
        if (startDate !== null && endDate !== null) {
            setLoading(true);
            var fromDate = startDate.toISOString();
            var toDate = endDate.toISOString();
            var user_id = theUser !== null ? theUser.id : 0;
            let formData = {
                fromDate,
                toDate,
                user_id
            };
            const url = BASE_URL + "/settings/get-liquidated-propinas2";
            return axios
                .post(url, formData, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    setdataget(res.data);
                    settotal(res.data);
                    setdatagetForSearch(res.data);
                    setLoading(false);
                });
        } else {
            toast.error(
                `${_t(t("Seleccione rango de fecha"))}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        }
    };
    //payment type
    const handleSetpaymentType = (payment_type) => {
        setCheckOrderDetails({
            ...checkOrderDetails,
            payment_type,
        });

        //calculate paid amount to set return amount
        handleCalculatePaid(checkOrderDetails.payment_amount, payment_type);
    };

    //payment type amount
    const handlePaymentTypeAmount = (e) => {
        let tempPaymentAmount = {
            ...checkOrderDetails.payment_amount,
            [e.target.name]: e.target.value,
        };

        setCheckOrderDetails({
            ...checkOrderDetails,
            payment_amount: tempPaymentAmount,
        });

        //calculate paid amount to set return amount
        handleCalculatePaid(tempPaymentAmount, checkOrderDetails.payment_type);
    };

    //calculate paid amount
    const handleCalculatePaid = (paymentAmount, paymentType) => {
        let paidAmount = 0;
        if (paymentAmount !== null && paymentType !== null) {
            let thePaymentArray = [];
            if (paymentAmount) {
                thePaymentArray = Object.entries(paymentAmount);
            }
            thePaymentArray.map((eachPaymentItem) => {
                let thePaymentType = paymentType.find((paymentTypeItem) => {
                    return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
                });
                if (eachPaymentItem[1] !== "") {
                    if (
                        thePaymentType &&
                        thePaymentType.id === parseInt(eachPaymentItem[0])
                    ) {
                        paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
                    }
                }
            });
            let localCurrency = JSON.parse(localStorage.getItem("currency"));
            paidAmount = paidAmount / localCurrency.rate;
            let theReturnMoney = 0;
            if (checkOrderDetails.item) {
                theReturnMoney =
                    paidAmount - parseFloat(checkOrderDetails.item.total_payable);
            }
            setReturnMoneyUsd(theReturnMoney);
        } else {
            setReturnMoneyUsd(0);
        }
        setPaidMoney(paidAmount);
    };

    // handleSettleOrder
    const handleSettleOrder = (e) => {
        e.preventDefault();
        if (
            paidMoney <
            parseFloat(
                checkOrderDetails.item.total_payable -
                checkOrderDetails.item.paid_amount
            )
        ) {
            toast.error(
                `${_t(
                    t("Please enter paid amount atleast equal to the total due amount")
                )}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        } else {
            handleSettleOrderAxiosReq();
        }
    };

    //settle order server request
    const handleSettleOrderAxiosReq = () => {
        let url = BASE_URL + "/settings/settle-submitted-order";
        let localCurrency = JSON.parse(localStorage.getItem("currency"));
        let formData = {
            order_group_id: checkOrderDetails.item.id,
            payment_type: checkOrderDetails.payment_type,
            payment_amount: checkOrderDetails.payment_amount,
            paidMoney: paidMoney,
            localCurrency: localCurrency,
        };
        setLoading(true);
        axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                if (res.data !== "paymentIssue") {
                    setCheckOrderDetails({
                        ...checkOrderDetails,
                        item: res.data[2],
                        payment_type: null,
                        payment_amount: null,
                        settle: false,
                    });
                    setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(
                        `${_t(
                            t(
                                "Please enter paid amount atleast equal to the total due amount"
                            )
                        )}`,
                        {
                            position: "bottom-center",
                            closeButton: false,
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        }
                    );
                }
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    //search submitted orders here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedOrder({ ...searchedOrder, searched: false });
        } else {
            let searchedList = datatableitemsOrdersForSearch.filter((item) => {
                //token


                //customer
                let lowerCaseItemCustomer = item.name.toLowerCase();

                //table


                //branch
                return (
                    lowerCaseItemCustomer.includes(searchInput)

                );
            });
            setSearchedOrder({
                ...searchedOrder,
                list: searchedList,
                searched: true,
            });
        }
    };

    //cancel order confirmation modal
    const handleCancelOrderConfirmation = (orderGroup) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">
                            {_t(t("You want to cancel this order?"))}
                        </p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleCancelOrder(orderGroup);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, cancel it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    //cancel order here
    const handleCancelOrder = (orderGroup) => {
        if (parseInt(orderGroup.is_accepted) === 0) {
            let url = BASE_URL + "/settings/cancel-submitted-order";
            let formData = {
                id: orderGroup.id,
            };
            setLoading(true);
            axios
                .post(url, formData, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data === "accepted") {
                        toast.error(
                            `${_t(t("Can not cancel this order, this is being cooked"))}`,
                            {
                                position: "bottom-center",
                                closeButton: false,
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            }
                        );
                    }
                })
                .catch(() => {
                    setLoading(false);
                    toast.error(`${_t(t("Please try again"))}`, {
                        position: "bottom-center",
                        closeButton: false,
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                });
        } else {
            toast.error(
                `${_t(t("Can not cancel this order, this is being cooked"))}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        }
    };
    const formartid = (id_order) => {
        var str = "" + id_order;
        var pad = "0000000";
        return pad.substring(0, pad.length - str.length) + str;
    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Control de visitas"))}</title>
            </Helmet>
            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>
                        <div className="col-9 t-mb-30 mb-lg-0">
                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {!searchedOrder.searched
                                                            ? _t(t("Control de visitas"))
                                                            : _t(t("Search Result"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row gx-2 mt-2">
                                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                <ul className="t-list fk-sort align-items-center">
                                                    <div className="input-group col">
                                                        <div className="form-file">
                                                            <input
                                                                type="text"
                                                                className="form-control border-0 form-control--light-1 rounded-0"
                                                                placeholder={
                                                                    _t(t("Busqueda por id, cliente")) +
                                                                    ".."
                                                                }
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                        <button className="btn btn-primary" type="button">
                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 ">
                                                <button
                                                    className="btn  btn-primary text-uppercase sm-text"
                                                    onClick={getdataget}
                                                >
                                                    {_t(t("Ver todos"))}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mt-2">
                                            <div className="col-12 col-md-2 d-md-block">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control sm-text py-2 t-mb-15 mb-md-0"
                                                    placeholderText={_t(t("From date"))}
                                                    shouldCloseOnSelect={false}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control sm-text py-2"
                                                    placeholderText={_t(t("To date"))}
                                                    shouldCloseOnSelect={false}
                                                />
                                            </div>
                                            <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                                                <button
                                                    className="btn btn-block btn-primary text-uppercase sm-text py-2"
                                                    onClick={setPaginateddatatableitems}
                                                >
                                                    {_t(t("Generate Report"))}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                            <div className="col-12 t-mb-15  text-right">
                                                <ReactHTMLTableToExcel
                                                    id="test-table-xls-button"
                                                    className="btn  btn-secondary text-uppercase sm-text"
                                                    table="table-to-xls"
                                                    filename="tablexls"
                                                    sheet="tablexls"
                                                    buttonText="Exportar a XLS" />
                                            </div>

                                        </div>

                                    </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("#"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Cliente"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Total Consumo"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Visitas"))}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {!searchedOrder.searched
                                                            ? [
                                                                datatableitems && [
                                                                    datatableitems.data.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        datatableitems.data.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="align-middle"
                                                                                        key={index}
                                                                                    >
                                                                                        <th
                                                                                            scope="row"
                                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                                        >
                                                                                            {index + 1}
                                                                                        </th>
                                                                                        <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                            {item.name}
                                                                                        </td>
                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            <span
                                                                                                className="btn btn-transparent btn-success xsm-text text-capitalize">
                                                                                                {currencySymbolLeft()}
                                                                                                {formatPrice(item.total_consumo)}
                                                                                                {currencySymbolRight()}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            <span
                                                                                                className="btn btn-transparent btn-success xsm-text text-capitalize">

                                                                                                {item.visitas}

                                                                                            </span>

                                                                                        </td>

                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    ),
                                                                ],
                                                            ]
                                                            : [
                                                                /* searched data, logic === haveData*/
                                                                searchedOrder && [
                                                                    searchedOrder.list.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        searchedOrder.list.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={index}
                                                                                >
                                                                                    <th
                                                                                        scope="row"
                                                                                        className="xsm-text text-capitalize align-middle text-center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </th>
                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.name}
                                                                                    </td>
                                                                                    <td className="xsm-text align-middle text-center">
                                                                                        <span
                                                                                            className="btn btn-transparent btn-success xsm-text text-capitalize">
                                                                                            {currencySymbolLeft()}
                                                                                            {formatPrice(item.total_consumo)}
                                                                                            {currencySymbolRight()}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="xsm-text align-middle text-center">
                                                                                        <span
                                                                                            className="btn btn-transparent btn-success xsm-text text-capitalize">

                                                                                            {item.visitas}

                                                                                        </span>

                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ),
                                                                ],
                                                            ]}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* pagination loading effect */}
                            {checkOrderDetails.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedOrder.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(
                                                            datatableitems,
                                                            setPaginateddatatableitems
                                                        )}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                                                <span className="d-inline-block sm-text">
                                                                    {showingData(datatableitems)}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() =>
                                                                    setSearchedOrder({
                                                                        ...searchedOrder,
                                                                        searched: false,
                                                                    })
                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                                            <span className="d-inline-block sm-text">
                                                                {searchedShowingData(
                                                                    searchedOrder,
                                                                    datatableitemsOrdersForSearch
                                                                )}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Visitas;
