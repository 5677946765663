import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import { FoodContext } from "../../../../contexts/Food";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
  currencyNameSecondary,
  currencyOperationSecondary,
  currencySymbolLeftSecondary,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import "moment/locale/es-us";
//importing context consumer here
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const Settled = () => {
  //getting context values here
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);

  const {
    //submitted orders
    settledOrders,
    setPaginatedSettledOrders,
    settledOrdersForSearch,

    //payment-type
    paymentTypeForSearch,

    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);
  const {
    cajadetalle
  } = useContext(FoodContext);
  const { t } = useTranslation();

  // States hook here
  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  //data_settled
  const [datasettled, setDataSettled] = useState({
    data: [],
    total: 0,
    pages: 0,
    limit: 10,
    current_page: 1,
    pageNumbers: [],
    renderPageNumbers: [],
    searchInput: ""
  });

  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });


  //useEffect == componentDidMount
  useEffect(() => { makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput); }, []);

  //search submitted orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    makeHttpRequestWithPage(0, datasettled.limit, searchInput);
  };

  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();

  // only print bill
  //for pos manager
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {

    },
  });

  //for kithcen
  const handleOnlyPrint2 = useReactToPrint({
    //content: () => component2Ref.current,
  });

  //after order submit or settle
  //for pos manager
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        //handlePrint2();

      } else {
        // handleOrderSubmitSuccessful();
      }
    },
  });

  //for kithcen
  const handlePrint2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      //handleOrderSubmitSuccessful();
    },
  });
  const formartid = (id_order) => {
    var str = "" + id_order;
    var pad = "0000000";
    return pad.substring(0, pad.length - str.length) + str;
  };

  const makeHttpRequestWithPage = async (pageNumber, limit, searchInput) => {
    console.log(datasettled.limit);
    const response = await fetch(`${BASE_URL}/settings/get-settled-orders-pagin?&page=${pageNumber}&limit=${limit}&search=${searchInput}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie()}`
      },
    });
    const data = await response.json();
    const pageNumbers = [];
    let renderPageNumbers;
    if (data.total !== null) {
      for (let i = 1; i <= data.pages; i++) {
        pageNumbers.push(i);
      }

      renderPageNumbers = pageNumbers.map(number => {
        let classes = data.current_page === number - 1 ? "page-item active" : 'page-item';
        if (number == 1 || number == data.total || (number >= data.current_page - 2 && number <= data.current_page + 2)) {
          return (
            <li key={number - 1} className={classes} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(number - 1, limit, searchInput)}>{number}</a></li>
          );
        }
      });
    }
    setDataSettled({
      ...datasettled,
      data: data.data,
      total: data.total,
      pages: data.pages,
      limit: limit,
      searchInput: searchInput,
      pageNumbers: pageNumbers,
      renderPageNumbers: renderPageNumbers,
      current_page: data.current_page
    });
  }
  const handlesetslccharged = (row) => {
    console.log(row.value)
    setDataSettled({
      ...datasettled,
      limit: row.value
    });

    setDataSettled({ ...datasettled, limit: row.value });

    makeHttpRequestWithPage(0, row.value, datasettled.searchInput);
  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Settled orders"))}</title>
      </Helmet>
      {/* Print bill */}
      <div className="d-none">
        <div ref={componentRef}>
          {checkOrderDetails.item && (
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {getSystemSettings(generalSettings, "siteName")}--------------
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {getSystemSettings(generalSettings, "address")}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {_t(t("Teléfono"))}:{" "}
                      {getSystemSettings(generalSettings, "phnNo")}
                    </p>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Orden"))}: {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                    </span>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Ticket"))}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {/*_t(t("Vat reg"))}: {_t(t("Applied"))*/}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Fecha"))}: <Moment format="LL">{new Date()}</Moment>
                      {", "}
                      {checkOrderDetails.item && (
                        <Moment format="LT">{checkOrderDetails.item.token.time}</Moment>
                      )}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Total Invitados"))}:{" "}
                      {checkOrderDetails.item && checkOrderDetails.item.total_guest}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Elaborada por"))}:{" "}
                      {checkOrderDetails.item.user_name}
                    </p>
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente"))}:{" "}
                        {checkOrderDetails.item.customer_name}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Num"))}:{" "}
                        {checkOrderDetails.item.customer_phn_no}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Dirección"))}:{" "}
                        {checkOrderDetails.item.customer_address}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Mesa"))}:{" "}
                        {checkOrderDetails.item.table_name}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Tipo de Orden"))}:{" "}{checkOrderDetails.item.dept_tag_name}
                      </p>
                      : null}
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text"
                          >
                            {_t(t("Platillo"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-center"
                          >
                            {_t(t("Cantidad"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-right"
                          >
                            {_t(t("Precio"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item.orderedItems.map((printItem, printItemIndex) => {
                          return (
                            <tr style={{ borderBottom: "solid 1px black" }}>
                              <th className="mb-0 xxsm-text fk-print-text">

                                <span className="d-block xxsm-text fk-print-text">{printItem.food_item}</span>
                                {printItem.properties !== null && (
                                  <span class="mb-0 xxsm-text fk-print-text">
                                    <span class="mb-0 xxsm-text fk-print-text">
                                      {_t(t("properties"))}:
                                    </span>
                                    {JSON.parse(
                                      printItem.properties
                                    ).map((propertyItem, thisIndex) => {
                                      if (
                                        thisIndex !==
                                        JSON.parse(printItem.properties)
                                          .length -
                                        1
                                      ) {
                                        return (
                                          propertyItem.property + ", "
                                        );
                                      } else {
                                        return propertyItem.property;
                                      }
                                    })}
                                  </span>
                                )}

                              </th>
                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                {printItem.quantity}
                              </td>
                              <td className="mb-0 xxsm-text fk-print-text text-right">
                                {currencySymbolLeft()}
                                {printItem.price}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Total"))}</span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("VAT"))}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.vat)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("Delivery"))}
                            </span>
                          </th>

                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.service_charge)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Descuento"))}</span>
                          </th>
                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.discount)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("propina"))}</span>
                          </th>
                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.propina_)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="mb-0 xxsm-text fk-print-text">Gran Total</td>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2" className="mb-0 xxsm-text fk-print-text">
                            {_t(t("Detalle Pago"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {checkOrderDetails.item != null && checkOrderDetails.item.bill_distribution ? (

                          checkOrderDetails.item.bill_distribution.map((item) => {
                            return (

                              <tr>
                                {console.log(checkOrderDetails.item.bill_distribution)}
                                {item.is_secondary ?
                                  <td className="mb-0 xxsm-text fk-print-text">
                                    {item.payment_type + "(" + currencySymbolLeftSecondary() + " " +
                                      (
                                        item.amount
                                      )
                                      + ")"}
                                  </td>

                                  :
                                  <td className="mb-0 xxsm-text fk-print-text">
                                    {item.payment_type}
                                  </td>
                                }
                                {item.is_secondary ?
                                  <td className="mb-0 xxsm-text fk-print-text text-right">
                                    {currencySymbolLeft()}
                                    {formatPrice(currencyOperationSecondary() == "/" ? item.amount * cajadetalle.tasa_cambio : item.amount / cajadetalle.tasa_cambio)}
                                    {currencySymbolRight()}
                                  </td>

                                  :
                                  <td className="mb-0 xxsm-text fk-print-text text-right">
                                    {currencySymbolLeft()}
                                    {formatPrice(item.amount)}
                                    {currencySymbolRight()}
                                  </td>
                                }

                              </tr>
                            );
                          }
                          )
                        ) : (
                          null
                        )}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="mb-0 xxsm-text fk-print-text">Cambio</td>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.return_amount)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*<span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              En Café Alicia tu opinión es importante!
            </span>
            <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              <img src={`${BASE_URL}` + "/assets/img/qr.jpeg"} alt="Logo" width="170" height="175" />
            </span>*/}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Settle modal */}
      <div className="modal fade" id="orderDetails" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {/* show order token on modal header */}
                  {_t(t("Order details, Orden"))}: #
                  {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* if loading true show loading effect */}
            {loading ? (
              <div className="modal-body">{modalLoading(5)}</div>
            ) : (
              <div className="modal-body">
                {checkOrderDetails.item &&
                  parseInt(checkOrderDetails.item.is_settled) === 0 ? (
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                        <div className="text-right">
                          {checkOrderDetails.settle &&
                            paidMoney >
                            parseFloat(
                              checkOrderDetails.item.total_payable
                            ) && (
                              <span className="mr-2 text-secondary font-weight-bold">
                                Return: {currencySymbolLeft()}
                                {formatPrice(returnMoneyUsd)}
                                {currencySymbolRight()}{" "}
                              </span>
                            )}
                          {checkOrderDetails.settle ? (
                            <button
                              className="btn btn-primary px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: false,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Cancel"))}
                            </button>
                          ) : (
                            <button
                              className="btn btn-success px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: true,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Pagar orden"))}
                            </button>
                          )}
                        </div>
                      )}
                  </>
                ) : (
                  // if this item is not settled then show settle-cancel button else, show this notification
                  ""
                )}
                {checkOrderDetails.item &&
                  //show this if order is cancelled
                  parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                    <div className="text-center bg-secondary text-white py-2">
                      {_t(t("This order has been cancelled"))}
                    </div>
                  )}
                {/* show this if order settle is not true, if true show payment input field */}
                {!checkOrderDetails.settle ? (
                  <div class="col-12 filtr-item">
                    <div class="fk-order-token t-bg-white">
                      <div class="fk-order-token__body">
                        <div class="fk-addons-table">
                          <div class="fk-addons-table__head text-center">
                            {_t(t("orden "))}: #
                            {checkOrderDetails.item &&
                              formartid(checkOrderDetails.item.id)}
                          </div>
                          <div class="fk-addons-table__info">
                            <div class="row g-0">
                              <div class="col-2 text-center border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("#"))}
                                </span>
                              </div>
                              <div class="col-3 text-center border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("food"))}
                                </span>
                              </div>
                              <div class="col-4 text-left pl-2 border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("Additional Info"))}
                                </span>
                              </div>
                              <div class="col-2 text-center border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("QTY"))}
                                </span>
                              </div>
                              <div class="col-1 text-center">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("Status"))}
                                </span>
                              </div>
                            </div>
                          </div>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.orderedItems.map(
                              (thisItem, indexThisItem) => {
                                return (
                                  <div class="fk-addons-table__body-row">
                                    <div class="row g-0">
                                      <div class="col-2 text-center border-right d-flex">
                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                          {indexThisItem + 1}
                                        </span>
                                      </div>
                                      <div class="col-3 text-center border-right d-flex">
                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                          {thisItem.food_item} (
                                          {thisItem.food_group})
                                        </span>
                                      </div>
                                      <div class="col-4 text-center border-right t-pl-10 t-pr-10">
                                        {thisItem.variation !== null && (
                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                            <span class="font-weight-bold mr-1">
                                              {_t(t("Base del platillo"))}:
                                            </span>
                                            {thisItem.variation}
                                          </span>
                                        )}

                                        {thisItem.properties !== null && (
                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                            <span class="font-weight-bold mr-1">
                                              {_t(t("properties"))}:
                                            </span>
                                            {JSON.parse(
                                              thisItem.properties
                                            ).map((propertyItem, thisIndex) => {
                                              if (
                                                thisIndex !==
                                                JSON.parse(thisItem.properties)
                                                  .length -
                                                1
                                              ) {
                                                return (
                                                  propertyItem.property + ", "
                                                );
                                              } else {
                                                return propertyItem.property;
                                              }
                                            })}
                                          </span>
                                        )}
                                      </div>
                                      <div class="col-2 text-center border-right d-flex">
                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                          {thisItem.quantity}
                                        </span>
                                      </div>

                                      <div class="col-1 text-center d-flex">
                                        <label class="mx-checkbox mx-checkbox--empty m-auto">
                                          <span class="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                            {parseInt(thisItem.destine_bar) ===
                                              1 && parseInt(thisItem.is_cooking_bar) ===
                                              1 ? (
                                              [
                                                parseInt(thisItem.is_ready) ===
                                                  1 ? (
                                                  <i
                                                    className="fa fa-check text-success"
                                                    title={_t(t("Ready"))}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-cutlery text-secondary"
                                                    title={_t(t("Cooking"))}


                                                  ></i>
                                                ),
                                              ]
                                            ) : parseInt(thisItem.destine) ===
                                              1 && parseInt(thisItem.is_cooking) ===
                                              1 ? (
                                              [
                                                parseInt(thisItem.is_ready) ===
                                                  1 ? (
                                                  <i
                                                    className="fa fa-check text-success"
                                                    title={_t(t("Ready"))}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-cutlery text-secondary"
                                                    title={_t(t("Cooking"))}

                                                  ></i>
                                                ),
                                              ]
                                            ) : (
                                              <i
                                                className="fa fa-times text-primary"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                title={_t(t("Pending"))}
                                              ></i>
                                            )
                                            }
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Detalle Pago"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkOrderDetails.item != null && checkOrderDetails.item.bill_distribution ? (

                      checkOrderDetails.item.bill_distribution.map((item) => {
                        return (

                          <tr>
                            {item.is_secondary ?
                              <td >
                                {item.payment_type + "(" + currencySymbolLeftSecondary() + " " +
                                  (
                                    item.amount
                                  )
                                  + ")"}
                              </td>

                              :
                              <td>
                                {item.payment_type}
                              </td>
                            }
                            {item.is_secondary ?
                              <td >
                                {currencySymbolLeft()}
                                {formatPrice(currencyOperationSecondary() == "/" ? item.amount * cajadetalle.tasa_cambio : item.amount / cajadetalle.tasa_cambio)}
                                {currencySymbolRight()}
                              </td>

                              :
                              <td>
                                {currencySymbolLeft()}
                                {formatPrice(item.amount)}
                                {currencySymbolRight()}
                              </td>
                            }

                          </tr>
                        );
                      }
                      )
                    ) : (
                      null
                    )}
                    <tr>
                      <td className="text-capitalized">Total desglose</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.paid_amount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>


                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Order details"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Received by"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.user_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Customer"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.customer_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Branch"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.branch_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Department"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.dept_tag_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Table"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.table_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Vat"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.vat)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Service charge"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.service_charge)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("propina"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.propina_)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Discount"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.discount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Total bill"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Paid amount"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.paid_amount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    {checkOrderDetails.item &&
                      parseFloat(
                        checkOrderDetails.item.total_payable -
                        checkOrderDetails.item.paid_amount
                      ) >= 0 ? (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Due amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.total_payable -
                                  checkOrderDetails.item.paid_amount
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Return amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.paid_amount -
                                  checkOrderDetails.item.total_payable
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Settle modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {checkOrderDetails.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedOrder.searched
                              ? _t(t("Settled orders"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                      <ul className="t-list fk-sort align-items-center">
                        <div className="input-group col">
                          <div className="form-file">
                            <input
                              type="text"
                              className="form-control border-0 form-control--light-1 rounded-0"
                              placeholder={
                                _t(t("Search by token, customer, branch")) +
                                ".."
                              }
                              onChange={handleSearch}
                            //value={datasettled.searchInput}
                            />
                          </div>
                          <button className="btn btn-primary" type="button">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-7">
                      <div className="row align-items-center gx-2">
                        <div className="col"></div>
                        <div className="col">
                          <NavLink
                            to="/dashboard/pos"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100"
                          >
                            {_t(t("POS"))}
                          </NavLink>
                        </div>
                        <div className="col">
                          <NavLink
                            to="/dashboard/pos/submitted"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100"
                          >
                            {_t(t("Ordenes sin Pagar"))}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Select
                        options={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }]}
                        components={makeAnimated()}
                        value={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }].filter(({ value }) => value === datasettled.limit)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        className="xsm-text"
                        onChange={handlesetslccharged}
                        maxMenuHeight="200px"
                        placeholder={"Cantidad de filas"}
                      />
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("#"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Orden"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Time"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Table"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Ticket"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {datasettled && datasettled.data.map((item, index) => (
                              <tr
                                className="align-middle"
                                key={index}
                              >
                                <th
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                  {index + 1 + (datasettled.current_page) * datasettled.limit}
                                </th>
                                <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                  #{formartid(item.id)}
                                </td>
                                <td className="xsm-text text-capitalize align-middle text-center">
                                  <Moment format="LT">
                                    {item.token.time}
                                  </Moment>
                                </td>
                                <td className="xsm-text align-middle text-center">
                                  {item.customer_name}
                                </td>
                                <td className="xsm-text align-middle text-center">
                                  {item.table_name}
                                </td>
                                <td className="xsm-text align-middle text-center">
                                  {item.branch_name || "-"}
                                </td>
                                <td class="xsm-text text-capitalize align-middle text-center">
                                  {parseInt(item.is_cancelled) ===
                                    0 ? (
                                    [
                                      parseInt(item.is_ready) ===
                                        0 ? (
                                        <span
                                          class="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                          onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: false,
                                            });
                                            setReturnMoneyUsd(0);
                                            setPaidMoney(0);
                                          }}
                                          data-toggle="modal"
                                          data-target="#orderDetails"
                                        >
                                          {_t(t("processing"))}
                                        </span>
                                      ) : (
                                        <span
                                          class="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                          onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: false,
                                            });
                                            setReturnMoneyUsd(0);
                                            setPaidMoney(0);
                                          }}
                                          data-toggle="modal"
                                          data-target="#orderDetails"
                                        >
                                          {_t(t("Ready"))}
                                        </span>
                                      ),
                                    ]
                                  ) : (
                                    <span
                                      class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                      onClick={() => {
                                        setCheckOrderDetails({
                                          ...checkOrderDetails,
                                          item: item,
                                          settle: false,
                                        });
                                        setReturnMoneyUsd(0);
                                        setPaidMoney(0);
                                      }}
                                      data-toggle="modal"
                                      data-target="#orderDetails"
                                    >
                                      {_t(t("Cancelled"))}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <button
                                    // send state- order group id
                                    className="dropdown-item sm-text text-capitalize"
                                    onClick={() => {
                                      setCheckOrderDetails({
                                        ...checkOrderDetails,
                                        item: item,
                                        settle: true,
                                      });
                                      setTimeout(() => {
                                        handleOnlyPrint();
                                      }, 1000);

                                    }}
                                  >
                                    <span className="t-mr-8">
                                      <i className="fa fa-refresh"></i>
                                    </span>
                                    {_t(t("Ticket"))}
                                  </button>
                                </td>
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div key="fragment4">
                <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                  <div className="row align-items-center t-pl-15 t-pr-15">
                    <div className="col-md-7 t-mb-15 mb-md-0">
                      <nav aria-label="Page navigation example" style={{ float: 'left' }}>
                        <ul className="pagination">Showing {datasettled.current_page + 1} to {datasettled.limit} of {datasettled.total} entries</ul>
                      </nav>
                    </div>
                    <div className="col-md-5">

                      <nav aria-label="Page navigation example" style={{ float: 'right' }}>
                        <ul className="pagination">
                          <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput)}>First</a></li>
                          <li className={datasettled.current_page == 0 ? "page-item disabled" : "page-item"} style={datasettled.current_page == 1 ? {} : { cursor: "pointer" }} ><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.current_page - 1, datasettled.limit, datasettled.searchInput)}>Previous</a></li>
                          {datasettled.renderPageNumbers}
                          <li className={datasettled.current_page == datasettled.pages - 1 ? "page-item disabled" : "page-item"} style={datasettled.current_page == datasettled.total ? {} : { cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.current_page + 1, datasettled.limit, datasettled.searchInput)}>Next</a></li>
                          <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.pages - 1, datasettled.limit, datasettled.searchInput)}>Last</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Settled;
