import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ReportSidebar from "./ReportSidebar";
import moment from "moment";
const Purchases = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    const {
        dataPaginating,
    } = useContext(RestaurantContext);

    const { t } = useTranslation();

    const [checkOrderDetails, setCheckOrderDetails] = useState({
        data: null,
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });
    const [totaldata, settotaldata] = useState(0);

    const [allsupliers, setallsupliers] = useState({
        allsupliers: []
    });

    //UPDATE 
    const [dataTablePagin, setdataTablePagin] = useState({
        data: [],
        supplier_id: 0,
        total: 0,
        pages: 0,
        limit: 10,
        current_page: 1,
        pageNumbers: [],
        renderPageNumbers: [],
        searchInput: "",
        datestart: null,
        dateend: null
    });

    useEffect(() => {
        makeHttpRequestWithPage(0);
        handlegetsuppliers();
    }, []);
    //UPDATE PURCHASES
    const handlegetsuppliers = () => {
        setLoading(true);
        const variationUrl = BASE_URL + "/settings/get-suppliers";
        return axios
            .get(variationUrl, {
                //params: formData,
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                let suppliers = [];
                suppliers.push({ name: "Todos los Usuarios", id: 0 });
                res.data.map((eachOldOrderItem) => {
                    suppliers.push({ name: eachOldOrderItem.name, id: eachOldOrderItem.id });
                });
                setallsupliers({ ...allsupliers, allsupliers: suppliers });
                //setallsupliers({ ...allsupliers, allsupliers: res.data });
                setLoading(false);
            });
    };
    const makeHttpRequestWithPage = async (pageNumber) => {
        const response = await fetch(`${BASE_URL}/settings/get-purchases-pagin?&page=${pageNumber}&limit=${dataTablePagin.limit}&search=${dataTablePagin.searchInput}&datestart=${moment(dataTablePagin.datestart).format("YYYY-MM-DD")}&dateend=${moment(dataTablePagin.dateend).format("YYYY-MM-DD")}&supplier_id=${dataTablePagin.supplier_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie()}`
            },
        });
        const data = await response.json();
        const pageNumbers = [];
        let renderPageNumbers;

        if (data.message == null) {
            if (data.total !== null) {
                for (let i = 1; i <= data.pages; i++) {
                    pageNumbers.push(i);
                }
                renderPageNumbers = pageNumbers.map((number, index) => {
                    let classes = data.current_page === number - 1 ? "page-item active" : 'page-item';
                    if (number == 1 || number == data.total || (number >= data.current_page - 2 && number <= data.current_page + 2)) {
                        return (
                            <li key={index} className={classes} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(number - 1)}>{number}</a></li>
                        );
                    }
                });
            }
            setdataTablePagin({
                ...dataTablePagin,
                data: data.data,
                total: data.total,
                pages: data.pages,
                //limit: limit,
                //searchInput: searchInput,
                pageNumbers: pageNumbers,
                renderPageNumbers: renderPageNumbers,
                current_page: data.current_page
            });
            settotal(data.data);
        }

    }
    const handleSearch = (name, value) => {
        console.log(name, value);
        let tem_all_Data = dataTablePagin;
        tem_all_Data[name] = value;
        console.log(tem_all_Data);
        setdataTablePagin(tem_all_Data);
        //console.log(dataTablePagin);
        setTimeout(function () {
            makeHttpRequestWithPage(0);
        }, 100);
    };
    const settotal = (data) => {
        let total = 0;
        data.map((propertyItem, thisIndex) => {
            total = parseFloat(total) + (parseFloat(propertyItem.total) - parseFloat(propertyItem.amount_tendered));
        })
        settotaldata(total);
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Ordenes"))}</title>
            </Helmet>

            {/* Settle modal */}
            <div className="modal fade" id="orderDetails" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {/* show order token on modal header */}
                                    {_t(t("Order details, Compra"))}: #
                                    {checkOrderDetails.item && checkOrderDetails.item.id}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* if loading true show loading effect */}
                        {loading ? (
                            <div className="modal-body">{modalLoading(5)}</div>
                        ) : (
                            <div className="modal-body">
                                {checkOrderDetails.item &&
                                    //show this if order is cancelled
                                    parseInt(checkOrderDetails.due) == 0 && (
                                        <div className="text-center bg-secondary text-white py-2">
                                            {_t(t("Esta compra a sido cancelada"))}
                                        </div>
                                    )}
                                {checkOrderDetails.data &&
                                    //show this if order is cancelled
                                    parseInt(checkOrderDetails.data.due) != 0 && (
                                        <div className="text-center bg-primary text-white py-2">
                                            {_t(t("Esta compra no a sido cancelada"))}
                                        </div>
                                    )}
                                {/* show this if order settle is not true, if true show payment input field */}

                                <div className="col-12 filtr-item">
                                    <div className="fk-order-token t-bg-white">
                                        <div className="fk-order-token__body">
                                            <div className="fk-addons-table">
                                                <div className="fk-addons-table__head text-center">
                                                    {_t(t("Compra "))}: #{checkOrderDetails.data && checkOrderDetails.data.reference}
                                                </div>
                                                <div className="fk-addons-table__info">
                                                    <div className="row g-0">
                                                        <div className="col-2 text-center border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                #
                                                            </span>
                                                        </div>
                                                        <div className="col-4 text-center border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Ingrediente"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-left pl-2 border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Costo"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-2 text-center border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Cantidad"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-1 text-center">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Total"))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.map(
                                                        (thisItem, indexThisItem) => {
                                                            return (
                                                                <div className="fk-addons-table__body-row">
                                                                    <div className="row g-0">
                                                                        <div className="col-2 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {indexThisItem + 1}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {thisItem.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {currencySymbolLeft()}
                                                                                {formatPrice(thisItem.cost)}
                                                                                {currencySymbolRight()}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {thisItem.quantity}
                                                                            </span>
                                                                        </div>

                                                                        <div className="col-1 text-center d-flex">
                                                                            <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                                                <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                                                                    {currencySymbolLeft()}
                                                                                    {formatPrice(thisItem.quantity * thisItem.cost)}
                                                                                    {currencySymbolRight()}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-striped table-sm text-center mt-3">
                                    <thead className="bg-info text-white text-uppercase">
                                        <tr>
                                            <th scope="col" colSpan="2">
                                                {_t(t("Detalle de la Compra"))}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Elaborada por"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.data &&
                                                    checkOrderDetails.data.user_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-capitalized">{_t(t("Total"))}</td>
                                            <td>
                                                {checkOrderDetails.data && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.data.total)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Settle modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>
                        <div className="col-9 t-mb-30 mb-lg-0">
                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {_t(t("Compras"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <button className="btn btn-primary" type="button">
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                                <div className="form-file">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 form-control--light-1 rounded-0"
                                                        placeholder={
                                                            "Referencia" + ".."
                                                        }
                                                        onChange={(e) => {
                                                            /*setdataTablePagin({
                                                              ...dataTablePagin,
                                                              searchInput: e.target.value
                                                            });*/
                                                            handleSearch('searchInput', e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                        <hr />
                                        <div className="col-md-4">
                                            <Select
                                                options={allsupliers.allsupliers}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                    /*setdataTablePagin({
                                                      ...dataTablePagin,
                                                      supplier_id: e.id
                                                    });*/
                                                    handleSearch('supplier_id', e.id);
                                                }}
                                                maxMenuHeight="200px"
                                                placeholder={
                                                    _t(t("Seleccione Proveedor")) + ".."
                                                }
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <ul className="t-list fk-sort align-items-center justify-content-end">
                                                <li className="fk-sort__list ml-2">
                                                    <DatePicker
                                                        selected={dataTablePagin.datestart}
                                                        onChange={(date) => {
                                                            /*setdataTablePagin({
                                                              ...dataTablePagin,
                                                              datestart: date
                                                            });*/
                                                            handleSearch('datestart', date);
                                                        }}
                                                        dateFormat={'dd-MM-yyyy'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="form-control xsm-text py-2"
                                                        placeholderText={_t(t("From date"))}
                                                        shouldCloseOnSelect={false}
                                                    />
                                                </li>
                                                <li className="fk-sort__list">
                                                    <span className="fk-sort__icon">
                                                        <span className="fa fa-long-arrow-right"></span>
                                                    </span>
                                                </li>
                                                <li className="fk-sort__list">
                                                    <DatePicker
                                                        selected={dataTablePagin.dateend}
                                                        onChange={(date) => {
                                                            /*setdataTablePagin({
                                                              ...dataTablePagin,
                                                              dateend: date
                                                            });*/
                                                            handleSearch('dateend', date);
                                                        }}
                                                        dateFormat={'dd-MM-yyyy'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="form-control xsm-text py-2"
                                                        placeholderText={_t(t("To date"))}
                                                        shouldCloseOnSelect={false}
                                                    />
                                                </li>
                                                <li class="fk-sort__list">
                                                    <button
                                                        class="btn btn-transparent btn-danger xsm-text text-uppercase py-2"
                                                        onClick={handleSearch}
                                                    >
                                                        {_t(t("Filter"))}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="col-md-6">
                                            <Select
                                                options={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }]}
                                                components={makeAnimated()}
                                                value={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }].filter(({ value }) => value === dataTablePagin.limit)}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                classNamePrefix="select"
                                                className="xsm-text"
                                                onChange={(e) => {
                                                    /*setdataTablePagin({
                                                      ...dataTablePagin,
                                                      limit: e.value
                                                    });*/
                                                    handleSearch('limit', e.value);
                                                }}
                                                maxMenuHeight="200px"
                                                placeholder={"Cantidad de filas"}
                                            />
                                        </div>
                                    </div>
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15  text-right">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn  btn-secondary text-uppercase sm-text"
                                                table="table-to-xls"
                                                filename="tablexls"
                                                sheet="tablexls"
                                                buttonText="Exportar a XLS" />
                                        </div>
                                    </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Fecha
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                Ref.
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Proveedor"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Total"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Abonado"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Deuda"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Estado"))}
                                                            </th>


                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Detalle"))}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {dataTablePagin && dataTablePagin.data.map((item, index) => (
                                                            <tr
                                                                className="align-middle"
                                                                key={index}
                                                            >
                                                                <th
                                                                    scope="row"
                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                >
                                                                    {index + 1}
                                                                </th>
                                                                <td className="xsm-text align-middle text-center">
                                                                    <Moment format="LL">
                                                                        {item.created_at}
                                                                    </Moment>
                                                                </td>

                                                                <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                    {item.reference}
                                                                </td>
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {item.supplier_name}
                                                                </td>

                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {currencySymbolLeft()}
                                                                    {formatPrice(item.total)}
                                                                    {currencySymbolRight()}
                                                                </td>

                                                                <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                    {currencySymbolLeft()}
                                                                    {formatPrice(item.amount_tendered)}
                                                                    {currencySymbolRight()}
                                                                </td>

                                                                <td className="xsm-text text-capitalize align-middle text-center text-primary">
                                                                    {currencySymbolLeft()}
                                                                    {formatPrice(item.due)}
                                                                    {currencySymbolRight()}
                                                                </td>

                                                                <td className="xsm-text align-middle text-center">
                                                                    {parseFloat(item.due) ===
                                                                        0 ?
                                                                        <span className="btn btn-transparent btn-success xsm-text text-capitalize px-3">
                                                                            {_t(t("Saldada"))}
                                                                        </span>
                                                                        :
                                                                        <span className="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                                            {_t(t("Por Pagar"))}
                                                                        </span>
                                                                    }
                                                                </td>

                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {
                                                                        <span
                                                                            className="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                                                            onClick={() => {
                                                                                setCheckOrderDetails({
                                                                                    ...checkOrderDetails,
                                                                                    item: item.details,
                                                                                    data: item,
                                                                                    settle: false,
                                                                                });
                                                                            }}
                                                                            data-toggle="modal"
                                                                            data-target="#orderDetails"
                                                                        >
                                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                                        </span>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row t-mt-10 gx-2">
                                                {/* left Sidebar */}
                                                <div className="col-lg-4  t-shadow">

                                                </div>
                                                <div className="col-lg-4  t-shadow">

                                                </div>
                                                <div className="col-lg-4  t-shadow">
                                                    <div className="product-card__body">
                                                        <div className="product-card__add" style={{ top: "0px" }}>
                                                            <span className="product-card__add-icon">
                                                                <span><i className="fa fa-money"></i></span>
                                                            </span>
                                                        </div>
                                                        <span
                                                            className={`product-card__sub-title t-text-alpha text-uppercase`}
                                                        >
                                                            <span className="fa fa-clock-o"></span>{" "}
                                                            {_t(t("Total Deuda")) + " " + totaldata}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* pagination loading effect */}
                            <div key="fragment4">
                                <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                    <div className="row align-items-center t-pl-15 t-pr-15">
                                        <div className="col-md-7 t-mb-15 mb-md-0">
                                            <nav aria-label="Page navigation example" style={{ float: 'left' }}>
                                                <ul className="pagination">Showing {dataTablePagin.current_page + 1} to {dataTablePagin.limit} of {dataTablePagin.total} entries</ul>
                                            </nav>
                                        </div>
                                        <div className="col-md-5">

                                            <nav aria-label="Page navigation example" style={{ float: 'right' }}>
                                                <ul className="pagination">
                                                    <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(0)}>First</a></li>
                                                    <li className={dataTablePagin.current_page == 0 ? "page-item disabled" : "page-item"} style={dataTablePagin.current_page == 1 ? {} : { cursor: "pointer" }} ><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.current_page - 1)}>Anterior</a></li>
                                                    {dataTablePagin.renderPageNumbers}
                                                    <li className={dataTablePagin.current_page == dataTablePagin.pages - 1 ? "page-item disabled" : "page-item"} style={dataTablePagin.current_page == dataTablePagin.total ? {} : { cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.current_page + 1)}>Siguiente</a></li>
                                                    <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(dataTablePagin.pages - 1)}>Last</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Purchases;
