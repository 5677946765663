import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import Select from "react-select";
import makeAnimated from "react-select/animated";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { FoodContext } from "../../../../../contexts/Food";

const VariationCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);
  const [allingredients, setallingredients] = useState({
    allingredients: []
  });
  const [ingredientconsumption, setingredientconsumption] = useState({
    ingredientconsumption: []
  });
  const [selectitemingredient, setselectitemingredient] = useState({});

  let {
    //variation
    getVariation,
    variationList,
    setVariationList,
    setPaginatedVariation,
    variationForSearch,
    setVariationForSearch,

    //pagination
    dataPaginating,
  } = useContext(FoodContext);

  // States hook here
  //new variation
  let [newVariation, setNewVariation] = useState({
    name: "",
    edit: false,
    editSlug: null,
    uploading: false,
    variation_id: 0,
    destine: 0
  });

  //search result
  let [searchedVariation, setSearchedVariation] = useState({
    list: null,
    searched: false,
  });

  //useEffect == componentDidMount
  useEffect(() => {
    handlegetingredients();
  }, []);

  //set name hook
  const handleSetNewVariation = (e) => {
    setNewVariation({ ...newVariation, [e.target.name]: e.target.value });
  };

  //Save New variation
  const handleSaveNewVariation = (e) => {
    e.preventDefault();
    if (ingredientconsumption.ingredientconsumption.length <= 0) {
      toast.error(`${_t(t("Debe agregar el consumo de ingredientes"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });

    } else {
      setNewVariation({
        ...newVariation,
        uploading: true,
      });
      const variationUrl = BASE_URL + `/settings/new-variation`;
      let formData = new FormData();
      formData.append("name", newVariation.name);
      formData.append("ingredients", JSON.stringify(ingredientconsumption.ingredientconsumption));
      return axios
        .post(variationUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewVariation({
            name: "",
            edit: false,
            editSlug: null,
            uploading: false,
          });
          setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: [] });
          setVariationList(res.data[0]);
          setVariationForSearch(res.data[1]);
          setSearchedVariation({
            ...searchedVariation,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Base del platillo"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        })
        .catch((error) => {
          setLoading(false);
          setNewVariation({
            ...newVariation,
            uploading: false,
          });
          if (error.response.data.errors) {
            if (error.response.data.errors.name) {
              error.response.data.errors.name.forEach((item) => {
                if (item === "A variation already exists with this name") {
                  toast.error(
                    `${_t(t("Base del platillo existe"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            } else {
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          }
        });

    }
  };

  //set edit true & values
  const handleSetEdit = (row) => {

    let variation = variationForSearch.filter((item) => {
      return item.slug === row.slug;
    });
    setNewVariation({
      ...newVariation,
      name: row.name,
      variation_id: row.id,
      edit: true,
    });
  };

  //update Variation
  const handleUpdateVariation = (e) => {
    e.preventDefault();
    setNewVariation({
      ...newVariation,
      uploading: true,
    });
    const variationUrl = BASE_URL + `/settings/update-variation`;
    let formData = new FormData();
    formData.append("name", newVariation.name);
    formData.append("editSlug", newVariation.editSlug);
    formData.append("id", newVariation.variation_id);
    formData.append("ingredients", JSON.stringify(ingredientconsumption.ingredientconsumption));
    return axios
      .post(variationUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewVariation({
          ...newVariation,
          uploading: false,
          //editSlug: null,
        });
        /*setNewVariation({
          name: "",
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setingredientconsumption([]);*/
        // setVariationList(res.data[0]);
        /*setVariationForSearch(res.data[1]);
        setSearchedVariation({
          ...searchedVariation,
          list: res.data[1],
        });*/
        setLoading(false);
        toast.success(`${_t(t("Base del platillo actualiada"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        setNewVariation({
          ...newVariation,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A variation already exists with this name") {
                toast.error(
                  `${_t(t("Base del platillo existe"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };
  //search Variation here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedVariation({ ...searchedVariation, searched: false });
    } else {
      let searchedList = variationForSearch.filter((item) => {
        let lowerCaseItemName = item.name.toLowerCase();
        return lowerCaseItemName.includes(searchInput);
      });
      setSearchedVariation({
        ...searchedVariation,
        list: searchedList,
        searched: true,
      });
    }
  };
  //delete confirmation modal of variation
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteVariation(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //delete variation here
  const handleDeleteVariation = (slug) => {
    setLoading(true);
    const variationUrl = BASE_URL + `/settings/delete-variation/${slug}`;
    return axios
      .get(variationUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data === "data exist") {
          setLoading(false);
          toast.error(
            `${_t(t("Please remove this variation from food item first"))}`,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        } else {
          setVariationList(res.data[0]);
          setVariationForSearch(res.data[1]);
          setSearchedVariation({
            ...searchedVariation,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Base del platillo eliminada"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  const handlegetingredients = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-ingredient-all";
    /*let formData = {
      id_food: row.id
    };*/
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallingredients({ ...allingredients, allingredients: res.data });
        setLoading(false);
      });
  };
  const getingredientconsumption = (row) => {

    handleSetEdit(
      row
    )
    setLoading(true);
    setselectitemingredient({ ...selectitemingredient, selectitemingredient: row })
    const variationUrl = BASE_URL + "/settings/get-ingredient-consumption";
    let formData = {
      variation_property_id: row.id,
      type: 0,
    };
    return axios
      .get(variationUrl, {
        params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: res.data });
        setLoading(false);
      });
  };
  const handleaddingredien = (row) => {
    let newitemm = {
      created_at: "",
      id: 0,
      ingredient_id: row.id,
      variation_property_id: selectitemingredient.id,
      name: row.name,
      quantity: 0,
      unit_measure: row.unit_measure
    }

    if (ingredientconsumption.ingredientconsumption.length > 0) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      ingredientconsumption.ingredientconsumption.map((newOrderItem, index) => {
        if (index == 0) {
          ingredient.push(newitemm);
        };

        ingredientTemp = {
          ...newOrderItem
          //quantity: e.target.value,
        };

        ingredient.push(newOrderItem);

      });

      setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: ingredient });
    } else {
      let ingredient = []; //array to push order items
      ingredient.push(newitemm);
      setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: ingredient });

    }
  };
  const handleonchangequantity = (e) => {
    if (ingredientconsumption.ingredientconsumption) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      ingredientconsumption.ingredientconsumption.map((newOrderItem, index) => {
        if (index == e.target.name) {
          ingredientTemp = {
            ...newOrderItem,
            quantity: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          // set other items as it was which are not selected to edit
          ingredient.push(newOrderItem);
        }
      });

      setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: ingredient });
    }
  };
  //delete food here
  const handleDeleteingredient = (removeIndex) => {
    setLoading(true);

    let oldOrderItems = []; //array to push order items
    ingredientconsumption.ingredientconsumption.map((newOrderItem, index) => {
      if (index !== removeIndex) {
        //push item to orderlist
        oldOrderItems.push(newOrderItem);
      } else {
        if (newOrderItem.id != 0) {
          const url = BASE_URL + `/settings/delete-ingredientscon/${newOrderItem.id}`;
          return axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setLoading(false);
              toast.success(`${_t(t("Ingrediente Removido"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              //getingredients();
            })
            .catch(() => {
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });
        } else {
          setLoading(false);
        }

      }
    });


    //set updated order list
    setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: oldOrderItems });
  };
  const handlefiltercost = (id) => {
    let cost = 0;
    allingredients.allingredients.map((newOrderItem, index) => {
      if (newOrderItem.id == id) {
        //push item to orderlist
   
        cost = newOrderItem.cost;
      }
    });
    return cost;
  };
  const handlesetNewVariation = (status) => {
    if (status) {
      setNewVariation({
        ...newVariation,
        name: "",
        edit: false,
        uploading: false,
      });
      setingredientconsumption({ ...ingredientconsumption, ingredientconsumption: [] });
    }

  }
  const handleDefaultdestine = (status) => {
    if (status == 0) {
      setNewVariation({
        ...newVariation,
        name: "",
        edit: false,
        uploading: false,
        destine: 1,
      });

    } else {
      setNewVariation({
        ...newVariation,
        name: "",
        edit: false,
        uploading: false,
        destine: 1,
      });
    }

  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Variations"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addvariation" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newVariation.edit
                    ? _t(t("Agregar base del platillo"))
                    : _t(t("Actualizar base del platillo"))}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newVariation.uploading === false ? (
                <div key="fragment-variation-1">
                  <form
                    onSubmit={
                      !newVariation.edit
                        ? handleSaveNewVariation
                        : handleUpdateVariation
                    }
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-primary">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Nombre"
                        value={newVariation.name || ""}
                        required
                        onChange={handleSetNewVariation}
                      />
                    </div>
                    <div className="form-group">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Ingredientes"))}
                        </label>
                      </div>
                      <Select
                        options={allingredients.allingredients}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        onChange={handleaddingredien}
                        placeholder={_t(t("Ingredientes")) + ".."}
                      />
                    </div>
                    <br/>
                    <div className="fragment-food-group-1 table-responsive">
                      <table className="table table-bordered table-hover">
                        <thead className="align-middle">
                          <tr>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("#"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Ingrediente"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Cantidad"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Medida"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Total"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Eliminar"))}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ingredientconsumption.ingredientconsumption && ingredientconsumption.ingredientconsumption.length > 0 ? (
                            [
                              ingredientconsumption.ingredientconsumption.map((item, index) => {
                                return (
                                  <tr
                                    scope="row"
                                    className="xsm-text align-middle text-center"
                                  >
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {index + 1}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {item.name}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        name={index}
                                        value={item.quantity}
                                        onChange={(e) => {
                                          handleonchangequantity(e);
                                        }}
                                        className="form-control xsm-text text-center variation-min-price-input-width"
                                        placeholder="Cantidad"
                                      />
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {item.unit_measure}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {"C$ " + handlefiltercost(item.ingredient_id) * item.quantity}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <button
                                        className="btn t-bg-clear t-text-dark--light-40"
                                        type="button"
                                        onClick={() =>
                                          handleDeleteingredient(index)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }),
                            ]
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4"
                      onClick={(e) => {
                        handlesetNewVariation();
                      }}
                    >
                      {!newVariation.edit
                        ? _t(t("Guardar"))
                        : _t(t("Actualizar"))}
                    </button>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newVariation.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {newVariation.uploading === true || loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${dataPaginating && "loading"}`}></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedVariation.searched
                                    ? _t(t("Lista de base de platillos"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-0"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                  data-toggle="modal"
                                  data-target="#addvariation"
                                  onClick={() => {
                                    handlesetNewVariation(true)
                                  }}
                                >
                                  {_t(t("Agregar"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedVariation.searched
                                ? [
                                  variationList && [
                                    variationList.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      variationList.data.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (variationList.current_page -
                                                    1) *
                                                  variationList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        getingredientconsumption(item)

                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addvariation"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]
                                : [
                                  /* searched data, logic === haveData*/
                                  searchedVariation && [
                                    searchedVariation.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedVariation.list.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (variationList.current_page -
                                                    1) *
                                                  variationList.per_page}
                                              </th>

                                              <td className="xsm-text align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        getingredientconsumption(item)

                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addvariation"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newVariation.uploading === true || loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedVariation.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(variationList, setPaginatedVariation)}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(variationList)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedVariation({
                                    ...searchedVariation,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedVariation,
                                  variationForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default VariationCrud;
