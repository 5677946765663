import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
  currencyNameSecondary,
  currencyOperationSecondary,
  currencySymbolLeftSecondary,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import "moment/locale/es-us";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { FoodContext } from "../../../../contexts/Food";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
const Submitted = () => {
  //getting context values here
  const {
    cajadetalle
  } = useContext(FoodContext);
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);
  const {
    authUserInfo,
  } = useContext(UserContext);
  const [btncreate, setbtncreate] = useState(false);
  const {
    //submitted orders
    submittedOrders,
    getSubmittedOrders,
    setPaginatedSubmittedOrders,
    submittedOrdersForSearch,

    //payment-type
    paymentTypeForSearch,

    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // States hook here
  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });
  const [dbwaiters, setdbwaiters] = useState({
    dbwaiters: []
  });
  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });
  const [ordersselect, setordersselect] = useState({
    ordersselect: null
  });
  const [dataitemuser, setdataitemuser] = useState({
    dataitemuser: []
  });
  const [posform, setposform] = useState({
    id: 0,
    user_id: "",
    note: "",
    charged_to: 0,
    items: [],
    total_cost: 0
  });
  const [datausers, setdatausers] = useState([]);

  const [datasettled, setDataSettled] = useState({
    data: [],
    total: 0,
    pages: 0,
    limit: 10,
    current_page: 1,
    pageNumbers: [],
    renderPageNumbers: [],
    searchInput: ""
  });

  //useEffect == componentDidMount
  useEffect(() => {
    getdataslc();
    getwaitersdb(authUserInfo.details.branch_id);
    makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput);
  }, []);
  const handlesetselectitemwaitert = (row) => {
    let temp = ordersselect.ordersselect;
    temp.waiter_desc_id = row.id;
    setordersselect({
      ...ordersselect,
      waiter_desc_id: temp
    });
    //ordersselect.ordersselect.waiter_id = row.id;
    /*setselectitemwaiter({
      ...selectitemwaiter,
      selectitemwaiter: row,
    });*/
    /*let temp = ordersselect.ordersselect;
    temp.description = e.target.value;
    setordersselect({
      ...ordersselect,
      description: temp
    });*/
    /*if (orderspos) {
      let newOrderItemTemp = null; //to edit selected item
      let oldOrderItems = []; //array to push order items
      let tempPropertyItemsArray = []; //for each property items
      orderspos.orderspos.map((newOrderItem, index) => {
        if (newOrderItem.id === row.id) {
          if (newOrderItem.properties) {

            //changing properties of selected food item
            newOrderItemTemp = {
              ...newOrderItem,
              description: tempPropertyItemsArray,
            };
            //push updated item to orderlist
            oldOrderItems.push(newOrderItemTemp);
          } else {
            //push updated item to orderlist
            oldOrderItems.push(newOrderItem);
          }
        } else {
          // set other items as it was which are not selected to edit
          oldOrderItems.push(newOrderItem);
        }
      });
      //setNewOrder(oldOrderItems);
    }*/
  };
  const handleChangeorderdesc = (e) => {
    let temp = ordersselect.ordersselect;
    temp.description = e.target.value;
    setordersselect({
      ...ordersselect,
      description: temp
    });
  };
  const postcancel = () => {
    if (authUserInfo.details.user_type == "superAdmin" || authUserInfo.details.user_type == "cajero") {
      if (ordersselect.ordersselect.id == 0) {
        toast.error(`${_t(t("favor seleccionar otra orden"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      } else {
        if (ordersselect.ordersselect.waiter_desc_id == 0) {
          toast.error(`${_t(t("favor seleccionar mesero"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          let url = BASE_URL + "/settings/cancel-order";
          setLoading(true);
          axios
            .post(url, ordersselect.ordersselect, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setLoading(false);
              setordersselect({
                ...ordersselect,
                ordersselect: {
                  description: "",
                  waiter_desc_id: 0,
                  id: 0
                }
              });
              //getOrders();
              getSubmittedOrders();
            })
            .catch(() => {
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                closeButton: false,
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });
        }

      }
    } else {
      handleDeleteConfirmation();
    }


  };
  const handleDeleteConfirmation = () => {
    toast.error(
      `${_t(
        t("Usted no tiene permisos para esta accion")
      )}`,
      {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      }
    );
  };
  const getdataslc = () => {
    setLoading(true);
    let formData = {
      branch_id: authUserInfo.details.branch_id,
      user_id: authUserInfo.details.id,
    };
    const customerUrl = BASE_URL + "/settings/get-data-slc";
    return axios
      .get(customerUrl, {
        params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setdatausers(res.data[1]);
        setLoading(false);
      });
  };
  const getwaitersdb = (id) => {
    const variationUrl = BASE_URL + "/settings/get-waiters";
    let formData = {
      branch_id: id
    };
    return axios
      .get(variationUrl, {
        params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        setdbwaiters({
          ...dbwaiters,
          dbwaiters: res.data,
        });
      });
  };
  //payment type
  const handleSetpaymentType = (payment_type) => {
    setCheckOrderDetails({
      ...checkOrderDetails,
      payment_type,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(checkOrderDetails.payment_amount, payment_type);
  };
  //payment type amount
  const handlePaymentTypeAmount = (e) => {
    let tempPaymentAmount = {
      ...checkOrderDetails.payment_amount,
      [e.target.name]: e.target.value,
    };

    setCheckOrderDetails({
      ...checkOrderDetails,
      payment_amount: tempPaymentAmount,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(tempPaymentAmount, checkOrderDetails.payment_type);
  };
  //calculate paid amount
  const handleCalculatePaid = (paymentAmount, paymentType) => {
    let paidAmount = 0;
    if (paymentAmount !== null && paymentType !== null) {
      let thePaymentArray = [];
      if (paymentAmount) {
        thePaymentArray = Object.entries(paymentAmount);
      }
      thePaymentArray.map((eachPaymentItem) => {
        let thePaymentType = paymentType.find((paymentTypeItem) => {
          return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
        });

        if (thePaymentType.name == currencyNameSecondary()) {
          if (eachPaymentItem[1] !== "") {
            if (
              thePaymentType &&
              thePaymentType.id === parseInt(eachPaymentItem[0])
            ) {
              paidAmount = paidAmount + parseFloat(currencyOperationSecondary() == "/" ? eachPaymentItem[1] * cajadetalle.tasa_cambio : eachPaymentItem[1] / cajadetalle.tasa_cambio);
            }
          }
        } else {
          if (eachPaymentItem[1] !== "") {
            if (
              thePaymentType &&
              thePaymentType.id === parseInt(eachPaymentItem[0])
            ) {
              paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
            }
          }
        }

      });
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      paidAmount = paidAmount / localCurrency.rate;
      let theReturnMoney = 0;
      if (checkOrderDetails.item) {
        theReturnMoney =
          paidAmount - parseFloat(checkOrderDetails.item.total_payable);
      }
      setReturnMoneyUsd(theReturnMoney);
    } else {
      setReturnMoneyUsd(0);
    }
    setPaidMoney(paidAmount);
  };
  // handleSettleOrder
  const handleSettleOrder = (e) => {
    e.preventDefault();
    if (
      paidMoney <
      parseFloat(
        checkOrderDetails.item.total_payable -
        checkOrderDetails.item.paid_amount
      )
    ) {
      toast.error(
        `${_t(
          t("Please enter paid amount atleast equal to the total due amount")
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    } else {
      handleSettleOrderAxiosReq();
    }
  };
  //settle order server request
  const handleSettleOrderAxiosReq = () => {
    console.log("pagano orden");
    let url = BASE_URL + "/settings/settle-submitted-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let formData = {
      order_group_id: checkOrderDetails.item.id,
      payment_type: checkOrderDetails.payment_type,
      payment_amount: checkOrderDetails.payment_amount,
      paidMoney: paidMoney,
      localCurrency: localCurrency,
    };

    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "paymentIssue") {
          makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput);
          handleOnlyPrint();
          setCheckOrderDetails({
            ...checkOrderDetails,
            item: res.data[2],
            payment_type: null,
            payment_amount: null,
            settle: false,
          });
          setSearchedOrder({
            ...searchedOrder,
            searched: false,
          });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(
            `${_t(
              t(
                "Please enter paid amount atleast equal to the total due amount"
              )
            )}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
        var modal = document.getElementById('closemodaldeleteorder');
        modal.click();
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  //search submitted orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    makeHttpRequestWithPage(0, datasettled.limit, searchInput);
  };
  //cancel order confirmation modal
  const handleCancelOrderConfirmation = (orderGroup) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want to cancel this order?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleCancelOrder(orderGroup);
                  onClose();
                }}
              >
                {_t(t("Yes, cancel it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //cancel order here
  const handleCancelOrder = (orderGroup) => {
    if (parseInt(orderGroup.is_accepted) === 0) {
      let url = BASE_URL + "/settings/cancel-submitted-order";
      let formData = {
        id: orderGroup.id,
      };
      setLoading(true);
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setLoading(false);
          if (res.data === "accepted") {
            toast.error(
              `${_t(t("Can not cancel this order, this is being cooked"))}`,
              {
                position: "bottom-center",
                closeButton: false,
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(
        `${_t(t("Can not cancel this order, this is being cooked"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  const handleadditemuser = (row) => {

    let total = 0;
    if (dataitemuser.dataitemuser != undefined > dataitemuser.dataitemuser.length > 0) {

      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items

      dataitemuser.dataitemuser.map((newOrderItem, index) => {

        if (index == 0) {
          total = total + (row.cost_total_property + row.cost_total_variation);
          ingredient.push(row);
        };

        ingredientTemp = {
          ...newOrderItem
        };

        ingredient.push(newOrderItem);
        total = total + (newOrderItem.cost_total_property + newOrderItem.cost_total_variation);
      });

      setdataitemuser({ ...dataitemuser, dataitemuser: ingredient });
    } else {
      let ingredient = []; //array to push order items
      ingredient.push(row);
      total = total + (row.cost_total_property + row.cost_total_variation);
      setdataitemuser({ ...dataitemuser, dataitemuser: ingredient });
    }
    handlecartotal(total);
  };
  const handlecartotal = (total) => {
    setposform({ ...posform, total_cost: total });
  };
  const handleDeleteingredient = (removeIndex) => {

    let oldOrderItems = []; //array to push order items
    dataitemuser.dataitemuser.map((newOrderItem, index) => {
      if (index !== removeIndex) {
        oldOrderItems.push(newOrderItem);
      } else {
        if (newOrderItem.id != 0) {
          /*const url = BASE_URL + `/settings/delete-ingredientscon/${newOrderItem.id}`;
          return axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setLoading(false);
              toast.success(`${_t(t("Item has been deleted successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              //getingredients();
            })
            .catch(() => {
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });*/
        }

      }
    });
    setdataitemuser({ ...dataitemuser, dataitemuser: oldOrderItems });
  };
  const handlesetslccharged = (row) => {
    setposform({
      ...posform,
      charged_to: row.id
    });
  };
  const handleonchangueposform = (e) => {
    setposform({ ...posform, [e.target.name]: e.target.value });
  };
  const handleposform = (e) => {
    e.preventDefault();

    if (authUserInfo.details.user_type == "superAdmin" || authUserInfo.details.user_type == "cajero") {
      if (posform.charged_to == 0) {
        toast.error(`${_t(t("Ingresar Persona responsable"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      } else {
        let dt = {
          user_id: authUserInfo.details.id,
          branch_id: authUserInfo.details.branch_id,
          note: posform.note,
          charged_to: posform.charged_to,
          items: dataitemuser.dataitemuser,
          total_cost: posform.total_cost,
          tam: checkOrderDetails.item.orderedItems.length
        }

        if (dataitemuser.length == 0) {
          toast.error(`${_t(t("Favor agregar ingredientes o platillos"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          const foodUnitUrl = BASE_URL + `/settings/post-desperdicio_user`;

          return axios
            .post(foodUnitUrl, dt, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              toast.success(`${_t(t("Desperdicio Almacenado"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              setposform({
                id: 0,
                user_id: "",
                note: "",
                charged_to: 0,
                items: []
              });
              setdataitemuser({ ...dataitemuser, dataitemuser: [] });
              setbtncreate(false);
              getSubmittedOrders();
              var modal = document.getElementById('closemodaldeleteorder');
              modal.click();
            })
            .catch((error) => {

            });
        }
      }
    } else {
      sinacceso();
    }


  };
  const handledeleteitemnotcooking = (row) => {
    let dt = {
      user_id: authUserInfo.details.id,
      branch_id: authUserInfo.details.branch_id,
      item_id: row.id,
      order_id: row.order_group_id,
      tam: checkOrderDetails.item.orderedItems.length
    }

    const foodUnitUrl = BASE_URL + `/settings/post-delete-item`;

    return axios
      .post(foodUnitUrl, dt, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setbtncreate(false);
        getSubmittedOrders();
        toast.info(res.data[0], {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput);
      })
      .catch((error) => {

      });
  };
  const handleconfirmdeleteitem = (row) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-primary text-white">
            <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("Quieres remover item de la orden?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark"
                onClick={() => {
                  handledeleteitemnotcooking(row);
                  onClose();
                }}
              >
                {_t(t("Yes!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const handleconfirmporcobrar = (row) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-primary text-white">
            <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("Quieres enviar la orden a cuentas por cobrar?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark"
                onClick={() => {
                  handleporcobrar(row);
                  onClose();
                }}
              >
                {_t(t("Yes!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const handleporcobrar = (row) => {
    let dt = {
      user_id: authUserInfo.details.id,
      branch_id: authUserInfo.details.branch_id,
      order_id: row.item.id,
      total_payable: row.item.total_payable
    }

    const foodUnitUrl = BASE_URL + `/settings/post-order-cobrar`;

    return axios
      .post(foodUnitUrl, dt, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setbtncreate(false);
        getSubmittedOrders();
        toast.success(`${_t(t("Orden enviada a cuentas por cobrar"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {

      });
  };
  //checkOrderDetails.item.orderedItems
  const handleDeleteitem = (removeIndex) => {

    let oldOrderItems = []; //array to push order items
    checkOrderDetails.item.orderedItems.map((newOrderItem, index) => {
      if (newOrderItem.id !== removeIndex) {
        oldOrderItems.push(newOrderItem);
      } else {
        if (newOrderItem.id != 0) {
          /*const url = BASE_URL + `/settings/delete-ingredientscon/${newOrderItem.id}`;
          return axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setLoading(false);
              toast.success(`${_t(t("Item has been deleted successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              //getingredients();
            })
            .catch(() => {
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });*/
        }

      }
    });
    //setdataitemuser({ ...dataitemuser, dataitemuser: oldOrderItems });
  };
  const sinacceso = () => {

    toast.error(`${_t(t("no tiene permisos para esta accion"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",

    });
  }
  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();
  // only print bill
  //for pos manager
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {

      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        //handleOnlyPrint2();
      }
    },
  });
  //for kithcen
  const handleOnlyPrint2 = useReactToPrint({
    content: () => component2Ref.current,
  });
  //after order submit or settle
  //for pos manager
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        //handlePrint2();
      } else {
        // handleOrderSubmitSuccessful();
      }
    },
  });
  //for kithcen
  const handlePrint2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      //handleOrderSubmitSuccessful();
    },
  });
  const formartid = (id_order) => {
    var str = "" + id_order;
    var pad = "0000000";
    return pad.substring(0, pad.length - str.length) + str;
  };
  const makeHttpRequestWithPage = async (pageNumber, limit, searchInput) => {
    console.log(datasettled.limit);
    const response = await fetch(`${BASE_URL}/settings/get-submitted-orders-pagin?&page=${pageNumber}&limit=${limit}&search=${searchInput}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie()}`
      },
    });
    const data = await response.json();
    const pageNumbers = [];
    let renderPageNumbers;
    if (data.total !== null) {
      for (let i = 1; i <= data.pages; i++) {
        pageNumbers.push(i);
      }

      renderPageNumbers = pageNumbers.map(number => {
        let classes = data.current_page === number - 1 ? "page-item active" : 'page-item';
        if (number == 1 || number == data.total || (number >= data.current_page - 2 && number <= data.current_page + 2)) {
          return (
            <li key={number - 1} className={classes} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(number - 1, limit, searchInput)}>{number}</a></li>
          );
        }
      });
    }
    setDataSettled({
      ...datasettled,
      data: data.data,
      total: data.total,
      pages: data.pages,
      limit: limit,
      searchInput: searchInput,
      pageNumbers: pageNumbers,
      renderPageNumbers: renderPageNumbers,
      current_page: data.current_page
    });
  }
  const handlesetslccharged2 = (row) => {
    console.log(row.value)
    setDataSettled({
      ...datasettled,
      limit: row.value
    });
    setDataSettled({ ...datasettled, limit: row.value });
    makeHttpRequestWithPage(0, row.value, datasettled.searchInput);
  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Submitted orders"))}</title>
      </Helmet>
      {/* Print bill */}

      <div className="d-none">
        <div ref={component2Ref}>
          {checkOrderDetails.item && (
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {getSystemSettings(generalSettings, "siteName")}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {getSystemSettings(generalSettings, "address")}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {_t(t("Teléfono"))}:{" "}
                      {getSystemSettings(generalSettings, "phnNo")}
                    </p>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Orden"))}: {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                    </span>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Ticket"))}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {/*_t(t("Vat reg"))}: {_t(t("Applied"))*/}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Fecha"))}: <Moment format="DD/MM/YYYY">{new Date()}</Moment>
                      {", "}
                      {checkOrderDetails && (
                        <Moment format="hh:mm A">{checkOrderDetails.item.time}</Moment>
                      )}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Total Invitados"))}:{" "}
                      {checkOrderDetails.item && checkOrderDetails.item.total_guest}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Elaborada por"))}:{" "}
                      {checkOrderDetails.item.user_name}
                    </p>
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente"))}:{" "}
                        {checkOrderDetails.item.customer_name}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Num"))}:{" "}
                        {checkOrderDetails.item.customer_phn_no}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Dirección"))}:{" "}
                        {checkOrderDetails.item.customer_address}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Mesa"))}:{" "}
                        {checkOrderDetails.item.table_name}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Tipo de Orden"))}:{" "}{checkOrderDetails.item.dept_tag_name}
                      </p>
                      : null}
                    <table>
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text"
                          >
                            {_t(t("Platillo"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-center"
                          >
                            {_t(t("Cantidad"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-right"
                          >
                            {_t(t("Precio"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item.orderedItems.map((printItem, printItemIndex) => {
                          return (
                            <tr style={{ borderBottom: "solid 1px black" }}>
                              <th className="mb-0 xxsm-text fk-print-text">

                                <span className="d-block xxsm-text fk-print-text">{printItem.food_item}</span>
                                {printItem.properties !== null && (
                                  <span class="mb-0 xxsm-text fk-print-text">
                                    <span class="mb-0 xxsm-text fk-print-text">
                                      {_t(t("properties"))}:
                                    </span>
                                    {JSON.parse(
                                      printItem.properties
                                    ).map((propertyItem, thisIndex) => {
                                      if (
                                        thisIndex !==
                                        JSON.parse(printItem.properties)
                                          .length -
                                        1
                                      ) {
                                        return (
                                          propertyItem.property + ", "
                                        );
                                      } else {
                                        return propertyItem.property;
                                      }
                                    })}
                                  </span>
                                )}

                              </th>
                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                {printItem.quantity}
                              </td>
                              <td className="mb-0 xxsm-text fk-print-text text-right">
                                {currencySymbolLeft()}
                                {printItem.price}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Total"))}</span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("VAT"))}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.vat)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("Delivery"))}
                            </span>
                          </th>

                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.service_charge)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Descuento"))}</span>
                          </th>
                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.discount)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("propina"))}</span>
                          </th>
                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.propina_)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="mb-0 xxsm-text fk-print-text">Gran Total</td>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2" className="mb-0 xxsm-text fk-print-text">
                            {_t(t("Detalle Pago"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item != null && checkOrderDetails.item.bill_distribution ? (

                          checkOrderDetails.item.bill_distribution.map((item) => {
                            return (

                              <tr>
                                {item.is_secondary ?
                                  <td className="mb-0 xxsm-text fk-print-text">
                                    {item.payment_type + "(" + currencySymbolLeftSecondary() + " " +
                                      (
                                        item.amount
                                      )
                                      + ")"}
                                  </td>

                                  :
                                  <td className="mb-0 xxsm-text fk-print-text">
                                    {item.payment_type}
                                  </td>
                                }
                                {item.is_secondary ?
                                  <td className="mb-0 xxsm-text fk-print-text text-right">
                                    {currencySymbolLeft()}
                                    {formatPrice(currencyOperationSecondary() == "/" ? item.amount * cajadetalle.tasa_cambio : item.amount / cajadetalle.tasa_cambio)}
                                    {currencySymbolRight()}
                                  </td>

                                  :
                                  <td className="mb-0 xxsm-text fk-print-text text-right">
                                    {currencySymbolLeft()}
                                    {formatPrice(item.amount)}
                                    {currencySymbolRight()}
                                  </td>
                                }

                              </tr>
                            );
                          }
                          )
                        ) : (
                          null
                        )}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="mb-0 xxsm-text fk-print-text">Cambio</td>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.return_amount)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*<span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              En Café Alicia tu opinión es importante!
            </span>
            <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              <img src={`${BASE_URL}` + "/assets/img/qr.jpeg"} alt="Logo" width="170" height="175" />
            </span>*/}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-none">
        <div ref={componentRef}>
          {
            console.log(checkOrderDetails)
          }
          {checkOrderDetails.item && (
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {getSystemSettings(generalSettings, "siteName")}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {getSystemSettings(generalSettings, "address")}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {_t(t("Teléfono"))}:{" "}
                      {getSystemSettings(generalSettings, "phnNo")}
                    </p>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Orden"))}: {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                    </span>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Ticket"))}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {/*_t(t("Vat reg"))}: {_t(t("Applied"))*/}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Fecha"))}: <Moment format="DD/MM/YYYY">{new Date()}</Moment>
                      {", "}
                      {checkOrderDetails && (
                        <Moment format="hh:mm A">{checkOrderDetails.item.time}</Moment>
                      )}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Total Invitados"))}:{" "}
                      {checkOrderDetails.item && checkOrderDetails.item.total_guest}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Elaborada por"))}:{" "}
                      {checkOrderDetails.item.user_name}
                    </p>
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente"))}:{" "}
                        {checkOrderDetails.item.customer_name}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Num"))}:{" "}
                        {checkOrderDetails.item.customer_phn_no}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Dirección"))}:{" "}
                        {checkOrderDetails.item.customer_address}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Mesa"))}:{" "}
                        {checkOrderDetails.item.table_name}
                      </p>
                      : null}
                    {checkOrderDetails.item ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Tipo de Orden"))}:{" "}{checkOrderDetails.item.dept_tag_name}
                      </p>
                      : null}
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text"
                          >
                            {_t(t("Platillo"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-center"
                          >
                            {_t(t("Cantidad"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-right"
                          >
                            {_t(t("Precio"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item.orderedItems.map((printItem, printItemIndex) => {
                          return (
                            <tr style={{ borderBottom: "solid 1px black" }}>
                              <th className="mb-0 xxsm-text fk-print-text">

                                <span className="d-block xxsm-text fk-print-text">{printItem.food_item}</span>
                                {printItem.properties !== null && (
                                  <span class="mb-0 xxsm-text fk-print-text">
                                    <span class="mb-0 xxsm-text fk-print-text">
                                      {_t(t("properties"))}:
                                    </span>
                                    {JSON.parse(
                                      printItem.properties
                                    ).map((propertyItem, thisIndex) => {
                                      if (
                                        thisIndex !==
                                        JSON.parse(printItem.properties)
                                          .length -
                                        1
                                      ) {
                                        return (
                                          propertyItem.property + ", "
                                        );
                                      } else {
                                        return propertyItem.property;
                                      }
                                    })}
                                  </span>
                                )}

                              </th>
                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                {printItem.quantity}
                              </td>
                              <td className="mb-0 xxsm-text fk-print-text text-right">
                                {currencySymbolLeft()}
                                {printItem.price}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Total"))}</span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("VAT"))}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.vat)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("Delivery"))}
                            </span>
                          </th>

                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.service_charge)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Descuento"))}</span>
                          </th>
                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.discount)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("propina"))}</span>
                          </th>
                          {checkOrderDetails.item && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.propina_)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="mb-0 xxsm-text fk-print-text">Gran Total</td>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2" className="mb-0 xxsm-text fk-print-text">
                            {_t(t("Detalle Pago"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.payment_type ? (
                          checkOrderDetails.payment_type.map((item) => {
                            return (

                              <tr>
                                <td className="mb-0 xxsm-text fk-print-text">
                                  {item.name == currencyNameSecondary() ?
                                    checkOrderDetails.payment_amount ? (

                                      item.name + "(" + currencySymbolLeftSecondary() + " " +
                                      (
                                        checkOrderDetails.payment_amount[item.id] == undefined ?
                                          0
                                          :
                                          checkOrderDetails.payment_amount[item.id]
                                      )
                                      + ")"
                                    ) : (
                                      item.name + "(" + currencySymbolLeftSecondary() + " 0)"
                                    )
                                    :
                                    item.name
                                  }

                                </td>
                                <td className="mb-0 xxsm-text fk-print-text text-right">
                                  {checkOrderDetails.payment_amount ?
                                    checkOrderDetails.payment_amount[item.id] == undefined ?

                                      currencySymbolLeft() +
                                      formatPrice(
                                        Math.abs(0)
                                      ) +
                                      currencySymbolRight()
                                      :
                                      item.name == currencyNameSecondary() ?
                                        currencySymbolLeft() + " " +
                                        formatPrice(
                                          Math.abs(currencyOperationSecondary() == "/" ? checkOrderDetails.payment_amount[item.id] * cajadetalle.tasa_cambio : checkOrderDetails.payment_amount[item.id] / cajadetalle.tasa_cambio)
                                        ) +
                                        currencySymbolRight()
                                        :
                                        currencySymbolLeft() +
                                        formatPrice(
                                          Math.abs(checkOrderDetails.payment_amount[item.id])
                                        ) +
                                        currencySymbolRight()

                                    :
                                    currencySymbolLeft() +
                                    formatPrice(
                                      Math.abs(0)
                                    ) +
                                    currencySymbolRight()
                                  }
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          null
                        )}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="mb-0 xxsm-text fk-print-text">Cambio</td>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {
                              paidMoney - parseFloat(checkOrderDetails.item.total_payable) < 0 ?
                                0 :
                                formatPrice(paidMoney - parseFloat(checkOrderDetails.item.total_payable))
                            }
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*<span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              En Café Alicia tu opinión es importante!
            </span>
            <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              <img src={`${BASE_URL}` + "/assets/img/qr.jpeg"} alt="Logo" width="170" height="175" />
            </span>*/}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*update variations modal */}
      <div className="modal fade" id="modaldeleteorder" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">Observacion de cancelacion</h5>
              </div>
              <button

                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="mb-2">
                  <label className="control-label">
                    {_t(t("Personal"))}
                  </label>
                </div>
                <Select
                  options={datausers}
                  components={makeAnimated()}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  maxMenuHeight="200px"
                  onChange={handlesetselectitemwaitert}
                  placeholder={_t(t("Seleccione personal")) + ".."}
                />
              </div>
              <div className="form-group">
                <div className="mb-12">
                  <label className="control-label">
                    {_t(t("Observacion"))}
                  </label>
                </div>

                <textarea className="form-control"
                  name={ordersselect.ordersselect && ordersselect.ordersselect.id}
                  onChange={handleChangeorderdesc}
                  value={ordersselect.ordersselect && ordersselect.ordersselect.description}>
                </textarea>
              </div>
              <div className="row">
                <div className="col-6">

                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-success w-100 xsm-text text-uppercase"
                    onClick={() => {
                      postcancel();
                    }}
                  >
                    submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Settle modal */}
      <div className="modal fade" id="orderDetails" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {/* show order token on modal header */}
                  {_t(t("Order details, Orden"))}: #
                  {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                </h5>
              </div>
              <button
                type="button"
                id="closemodaldeleteorder"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setposform({
                    id: 0,
                    user_id: "",
                    note: "",
                    charged_to: 0,
                    items: []
                  });
                  setdataitemuser({ ...dataitemuser, dataitemuser: [] });
                  setbtncreate(false);
                }}
              ></button>
            </div>
            {/* if loading true show loading effect */}
            {loading ? (
              <div className="modal-body">{modalLoading(5)}</div>
            ) : (
              <div className="modal-body">
                {checkOrderDetails.item &&
                  parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                    <div className="row">
                      <div className="col-md-6" style={{ textAlign: "left" }}>
                        <button
                          type="button"
                          className="btn btn-primary px-3 rounded-md text-uppercase"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            handleconfirmporcobrar(checkOrderDetails);
                          }}
                        >
                          {_t(t("Enviar a Cuentas por cobrar"))}
                        </button>
                      </div>
                      <div className="col-md-6">

                      </div>
                    </div>)
                }
                {(btncreate && authUserInfo.details.user_type == "superAdmin") || (btncreate && authUserInfo.details.user_type == "cajero") ?
                  <form onSubmit={handleposform} className="mx-2 sm-text">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="mb-2">
                            <label className="control-label">
                              {_t(t("Usuario"))}
                            </label>
                          </div>
                          <Select
                            options={datausers}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            classNamePrefix="select"
                            onChange={handlesetslccharged}
                            maxMenuHeight="200px"
                            placeholder={
                              _t(t("Seleccione usuario")) + ".."
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6" style={{ textAlign: "right" }}>
                        <label htmlFor="t" className="form-label">
                          {currencySymbolLeft()}
                          {formatPrice(posform.total_cost)}
                          {currencySymbolRight()}
                        </label>
                      </div>
                    </div>

                    <div className="fk-addons-table">
                      <div className="fk-addons-table__head text-center">

                      </div>
                      <div className="fk-addons-table__info">
                        <div className="row g-0">
                          <div className="col-1 text-center border-right">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("#"))}
                            </span>
                          </div>
                          <div className="col-3 text-center border-right">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("food"))}
                            </span>
                          </div>
                          <div className="col-4 text-left pl-2 border-right">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("Additional Info"))}
                            </span>
                          </div>
                          <div className="col-1 text-center border-right">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("Cantidad"))}
                            </span>
                          </div>
                          <div className="col-1 text-center border-right">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("Cost"))}
                            </span>
                          </div>
                          <div className="col-1 text-center">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("Status"))}
                            </span>
                          </div>
                          <div className="col-1 text-center">
                            <span className="fk-addons-table__info-text text-capitalize">
                              {_t(t("Status"))}
                            </span>
                          </div>
                        </div>
                      </div>
                      {dataitemuser.dataitemuser &&
                        dataitemuser.dataitemuser.map(
                          (thisItem, indexThisItem) => {
                            return (
                              <div className="fk-addons-table__body-row">
                                <div className="row g-0">
                                  <div className="col-1 text-center border-right d-flex">
                                    <span className="fk-addons-table__info-text text-capitalize m-auto">
                                      {indexThisItem + 1}
                                    </span>
                                  </div>
                                  <div className="col-3 text-center border-right d-flex">
                                    <span className="fk-addons-table__info-text text-capitalize m-auto">
                                      {thisItem.food_item} ({thisItem.food_group})</span>
                                  </div>
                                  <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                    {thisItem.variation !== null && (
                                      <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                        <span className="font-weight-bold mr-1">
                                          {_t(t("Base del platillo"))}:
                                        </span>
                                        {thisItem.variation}
                                      </span>
                                    )}

                                    {thisItem.properties !== null && (
                                      <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                        <span className="font-weight-bold mr-1">{_t(t("properties"))}:</span>
                                        {JSON.parse(
                                          thisItem.properties
                                        ).map((propertyItem, thisIndex) => {
                                          if (
                                            thisIndex !==
                                            JSON.parse(thisItem.properties).length - 1
                                          ) {
                                            return (
                                              propertyItem.property + ", "
                                            );
                                          } else {
                                            return propertyItem.property;
                                          }
                                        })}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-1 text-center border-right d-flex">
                                    <span className="fk-addons-table__info-text text-capitalize m-auto">
                                      {thisItem.quantity}
                                    </span>
                                  </div>
                                  <div className="col-1 text-center border-right d-flex">
                                    <span className="fk-addons-table__info-text text-capitalize m-auto">
                                      {currencySymbolLeft()}
                                      {formatPrice(thisItem.cost_total_property + thisItem.cost_total_variation)}
                                      {currencySymbolRight()}
                                    </span>
                                  </div>

                                  <div className="col-1 text-center d-flex">
                                    <label className="mx-checkbox mx-checkbox--empty m-auto">
                                      <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                        {parseInt(thisItem.is_cooking) ===
                                          1 || parseInt(thisItem.is_cooking_bar) ===
                                          1 ? (
                                          [
                                            parseInt(thisItem.is_ready) ===
                                              1 ? (
                                              <i
                                                className="fa fa-check text-success"
                                                title={_t(t("Ready"))}
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-cutlery text-secondary"
                                                title={_t(t("Cooking"))}

                                              ></i>
                                            ),
                                          ]
                                        ) : (
                                          <i
                                            className="fa fa-times text-primary"
                                            onClick={() =>
                                              handledeleteitemnotcooking(thisItem)
                                            }
                                            title={_t(t("Pending"))}
                                          ></i>
                                        )}
                                      </span>
                                    </label>
                                  </div>

                                  <div className="col-1 text-center d-flex">
                                    <label className="mx-checkbox mx-checkbox--empty m-auto">
                                      <button
                                        className="btn t-bg-clear t-text-dark--light-40"
                                        type="button"
                                        onClick={() =>
                                          handleDeleteingredient(indexThisItem)
                                        }
                                      >
                                        <i className="fa fa-trash-o"></i>
                                      </button>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                      <textarea
                        className="form-control"
                        placeholder="Nota"
                        name="note"
                        value={posform && posform.note}
                        onChange={handleonchangueposform}>
                      </textarea>
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-right">
                        <button
                          type="button"
                          className="btn btn-primary px-3 rounded-md text-uppercase"
                          onClick={() => {
                            setposform({
                              id: 0,
                              user_id: "",
                              note: "",
                              charged_to: 0,
                              items: []
                            });
                            setdataitemuser({ ...dataitemuser, dataitemuser: [] });
                            setbtncreate(false);
                          }}
                        >
                          {_t(t("Cancelar"))}
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="submit"
                          className="btn btn-success text-uppercase text-center"
                          onClick={() => {
                            setCheckOrderDetails({
                              ...checkOrderDetails,
                              settle: false,
                              payment_amount: null,
                              payment_type: null,
                            });
                            setReturnMoneyUsd(0);
                            setPaidMoney(0);
                          }

                          }
                        >
                          {_t(t("Cargar platillos"))}
                        </button>
                      </div>

                    </div>
                  </form>
                  : null
                }

                <br />
                {checkOrderDetails.item &&
                  parseInt(checkOrderDetails.item.is_settled) === 0 ? (
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                        <div className="text-right">
                          {checkOrderDetails.settle ? (
                            <button
                              className="btn btn-primary px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: false,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Cancel"))}
                            </button>
                          ) : (
                            <button
                              className="btn btn-success px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: true,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Pagar"))}
                            </button>
                          )}
                        </div>

                      )}
                  </>
                ) : (
                  // if this item is not settled then show settle-cancel button else, show this notification
                  <div className="text-center bg-success text-white py-2">
                    {_t(t("La orden a sido pagada, puede cerrar ahora"))}
                  </div>
                )}
                {checkOrderDetails.item &&
                  //show this if order is cancelled
                  parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                    <div className="text-center bg-secondary text-white py-2">
                      {_t(t("Esta orden a sido cancelada"))}
                    </div>
                  )}
                {/* show this if order settle is not true, if true show payment input field */}
                {!checkOrderDetails.settle ? (
                  <div className="col-12 filtr-item">
                    <div className="fk-order-token t-bg-white">
                      <div className="fk-order-token__body">
                        <div className="fk-addons-table">
                          <div className="fk-addons-table__head text-center">
                            {_t(t("orden "))}: #
                            {checkOrderDetails.item &&
                              formartid(checkOrderDetails.item.id)}
                          </div>
                          <div className="fk-addons-table__info">
                            <div className="row g-0">
                              <div className="col-2 text-center border-right">
                                <span className="fk-addons-table__info-text text-capitalize">
                                  {_t(t("#"))}
                                </span>
                              </div>
                              <div className="col-3 text-center border-right">
                                <span className="fk-addons-table__info-text text-capitalize">
                                  {_t(t("food"))}
                                </span>
                              </div>
                              <div className="col-4 text-left pl-2 border-right">
                                <span className="fk-addons-table__info-text text-capitalize">
                                  {_t(t("Additional Info"))}
                                </span>
                              </div>
                              <div className="col-2 text-center border-right">
                                <span className="fk-addons-table__info-text text-capitalize">
                                  {_t(t("QTY"))}
                                </span>
                              </div>
                              <div className="col-1 text-center">
                                <span className="fk-addons-table__info-text text-capitalize">
                                  {_t(t("Status"))}
                                </span>
                              </div>
                            </div>
                          </div>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.orderedItems.map(
                              (thisItem, indexThisItem) => {
                                return (
                                  <div className="fk-addons-table__body-row">
                                    <div className="row g-0">
                                      <div className="col-2 text-center border-right d-flex">
                                        <span className="fk-addons-table__info-text text-capitalize m-auto">
                                          {indexThisItem + 1}
                                        </span>
                                      </div>
                                      <div className="col-3 text-center border-right d-flex">
                                        <span className="fk-addons-table__info-text text-capitalize m-auto">
                                          {thisItem.food_item} (
                                          {thisItem.food_group})
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                        {thisItem.variation !== null && (
                                          <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                            <span className="font-weight-bold mr-1">
                                              {_t(t("Base del platillo"))}:
                                            </span>
                                            {thisItem.variation}
                                          </span>
                                        )}

                                        {thisItem.properties !== null && (
                                          <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                            <span className="font-weight-bold mr-1">
                                              {_t(t("properties"))}:
                                            </span>
                                            {JSON.parse(
                                              thisItem.properties
                                            ).map((propertyItem, thisIndex) => {
                                              if (
                                                thisIndex !==
                                                JSON.parse(thisItem.properties)
                                                  .length -
                                                1
                                              ) {
                                                return (
                                                  propertyItem.property + ", "
                                                );
                                              } else {
                                                return propertyItem.property;
                                              }
                                            })}
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-2 text-center border-right d-flex">
                                        <span className="fk-addons-table__info-text text-capitalize m-auto">
                                          {thisItem.quantity}
                                        </span>
                                      </div>

                                      <div className="col-1 text-center d-flex">
                                        <label className="mx-checkbox mx-checkbox--empty m-auto">
                                          <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                            {parseInt(thisItem.destine_bar) ===
                                              1 && parseInt(thisItem.is_cooking_bar) ===
                                              1 ? (
                                              [
                                                parseInt(thisItem.is_ready) ===
                                                  1 ? (
                                                  <i
                                                    className="fa fa-check text-success"
                                                    title={_t(t("Ready"))}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-cutlery text-secondary"
                                                    title={_t(t("Cooking"))}
                                                    onClick={() => {
                                                      setbtncreate(true);
                                                      handleadditemuser(thisItem);
                                                    }}

                                                  ></i>
                                                ),
                                              ]
                                            ) : parseInt(thisItem.destine) ===
                                              1 && parseInt(thisItem.is_cooking) ===
                                              1 ? (
                                              [
                                                parseInt(thisItem.is_ready) ===
                                                  1 ? (
                                                  <i
                                                    className="fa fa-check text-success"
                                                    title={_t(t("Ready"))}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-cutlery text-secondary"
                                                    title={_t(t("Cooking"))}
                                                    onClick={() => {
                                                      setbtncreate(true);
                                                      handleadditemuser(thisItem);
                                                    }}

                                                  ></i>
                                                ),
                                              ]
                                            ) : (
                                              <i
                                                className="fa fa-times text-primary"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() =>
                                                  handleconfirmdeleteitem(thisItem)
                                                }
                                                title={_t(t("Pending"))}
                                              ></i>
                                            )
                                            }
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="my-2 payment-type-parent">
                    <Select
                      options={paymentTypeForSearch && paymentTypeForSearch}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      classNamePrefix="select"
                      className="xsm-text"
                      backspaceRemovesValue={false}
                      onChange={handleSetpaymentType}
                      maxMenuHeight="200px"
                      isMulti
                      clearIndicator={null}
                      placeholder={_t(t("Select payment methods")) + ".."}
                    />
                    {checkOrderDetails.payment_type !== null && (
                      <form
                        className="border my-2 change-background rounded-lg"
                        onSubmit={handleSettleOrder}
                      >
                        <div className="sm-text text-center text-white py-2">
                          {_t(t("Amount"))}
                        </div>
                        {checkOrderDetails.payment_type.map(
                          (eachPaymentType, paymentTypeIndex) => {
                            return (
                              <div className="addons-list__item mx-2 mb-1">
                                <input
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  name={eachPaymentType.id}
                                  autoComplete="off"
                                  className="form-control xsm-text pl-2"
                                  onChange={handlePaymentTypeAmount}
                                  placeholder={eachPaymentType.name}
                                  value={
                                    checkOrderDetails.payment_amount &&
                                    checkOrderDetails.payment_amount[
                                    eachPaymentType.id
                                    ]
                                  }
                                />
                              </div>
                            );
                          }
                        )}
                        <div className="pb-2 pl-2 my-2">
                          <button
                            className="btn btn-sm btn-warning text-dark px-3 text-uppercase"
                            type="submit"
                          >
                            {_t(t("Pagar"))}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Detalle Pago"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkOrderDetails.payment_type ? (

                      checkOrderDetails.payment_type.map((item) => {
                        return (

                          <tr>
                            {item.name == currencyNameSecondary() ?
                              checkOrderDetails.payment_amount ? (
                                <td >
                                  {item.name + "(" + currencySymbolLeftSecondary() + " " +
                                    (
                                      checkOrderDetails.payment_amount[item.id] == undefined ?
                                        0
                                        :
                                        checkOrderDetails.payment_amount[item.id]
                                    )
                                    + ")"}
                                </td>

                              ) : (
                                <td >
                                  {item.name + "(" + currencySymbolLeftSecondary() + " 0)"}
                                </td>
                              )
                              :
                              <td>
                                {item.name}
                              </td>
                            }
                            {checkOrderDetails.payment_amount ?
                              checkOrderDetails.payment_amount[item.id] == undefined ?
                                < td>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    Math.abs(0)
                                  )}
                                  {currencySymbolRight()}
                                </td>

                                :
                                item.name == currencyNameSecondary() ?
                                  < td>
                                    {currencySymbolLeft() + " "}
                                    {formatPrice(
                                      Math.abs(currencyOperationSecondary() == "/" ? checkOrderDetails.payment_amount[item.id] * cajadetalle.tasa_cambio : checkOrderDetails.payment_amount[item.id] / cajadetalle.tasa_cambio)
                                    )}
                                    {currencySymbolRight()}
                                  </td>


                                  :
                                  < td>
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      Math.abs(checkOrderDetails.payment_amount[item.id])
                                    )}
                                    {currencySymbolRight()}
                                  </td>

                              :
                              < td>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  Math.abs(0)
                                )}
                                {currencySymbolRight()}
                              </td>
                            }
                          </tr>
                        );
                      }
                      )
                    ) : (
                      null
                    )}
                    <tr>
                      <td className="text-capitalized">Total desglose</td>
                      <td>

                        <>
                          {currencySymbolLeft()}
                          {formatPrice(paidMoney)}
                          {currencySymbolRight()}
                        </>

                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">
                        {checkOrderDetails.settle &&
                          paidMoney >=
                          parseFloat(
                            checkOrderDetails.item.total_payable
                          ) ?
                          (
                            <span className="mr-2 text-secondary font-weight-bold">
                              Cambio
                            </span>
                          )
                          :

                          <span className="mr-2 text-secondary font-weight-bold">
                            Saldo Pendiente
                          </span>

                        }

                      </td>
                      <td>
                        {checkOrderDetails.settle &&
                          paidMoney >=
                          parseFloat(
                            checkOrderDetails.item.total_payable
                          ) ?
                          (
                            <span className="mr-2 text-secondary font-weight-bold">
                              {currencySymbolLeft()}
                              {formatPrice(returnMoneyUsd)}
                              {currencySymbolRight()}{" "}
                            </span>
                          )
                          :
                          checkOrderDetails.settle && checkOrderDetails.item.total_payable != null ?
                            (
                              <span className="mr-2 text-secondary font-weight-bold">
                                {currencySymbolLeft()}
                                {formatPrice(parseFloat(checkOrderDetails.item.total_payable) - paidMoney)}
                                {currencySymbolRight()}{" "}
                              </span>
                            ) : null

                        }


                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">
                        {checkOrderDetails.settle &&
                          paidMoney >=
                          parseFloat(
                            checkOrderDetails.item.total_payable
                          ) ?
                          (
                            <span className="mr-2 text-secondary font-weight-bold">
                              Cambio
                            </span>
                          )
                          :

                          <span className="mr-2 text-secondary font-weight-bold">
                            Saldo Pendiente
                          </span>

                        }

                      </td>
                      <td>
                        {checkOrderDetails.settle &&
                          paidMoney >=
                          parseFloat(
                            checkOrderDetails.item.total_payable
                          ) ?
                          (
                            <span className="mr-2 text-secondary font-weight-bold">
                              {currencySymbolLeftSecondary() + " "}
                              {formatPrice(currencyOperationSecondary() == "/" ? returnMoneyUsd / cajadetalle.tasa_cambio : returnMoneyUsd * cajadetalle.tasa_cambio)}

                            </span>
                          )
                          :
                          checkOrderDetails.settle && checkOrderDetails.item.total_payable != null ?
                            (
                              <span className="mr-2 text-secondary font-weight-bold">
                                {currencySymbolLeftSecondary() + " "}
                                {formatPrice(currencyOperationSecondary() == "/" ? (parseFloat(checkOrderDetails.item.total_payable) - paidMoney) / cajadetalle.tasa_cambio : (parseFloat(checkOrderDetails.item.total_payable) - paidMoney) * cajadetalle.tasa_cambio)}

                              </span>
                            ) : null

                        }


                      </td>
                    </tr>
                  </tbody>
                </table>


                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Order details"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Received by"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.user_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Customer"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.customer_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Sucursal"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.branch_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Department"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.dept_tag_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Table"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.table_name}
                      </td>
                    </tr>

                    {/* <tr>
                      <td className="text-capitalized">{_t(t("Waiter"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.waiter_name}
                      </td>
                        </tr>*/}

                    <tr>
                      <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Vat"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.vat)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Service charge"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.service_charge)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Discount"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.discount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">{_t(t("propina"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.propina_)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Total bill"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Paid amount"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.paid_amount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    {checkOrderDetails.item &&
                      parseFloat(
                        checkOrderDetails.item.total_payable -
                        checkOrderDetails.item.paid_amount
                      ) >= 0 ? (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Due amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.total_payable -
                                  checkOrderDetails.item.paid_amount
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Return amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.paid_amount -
                                  checkOrderDetails.item.total_payable
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Settle modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {checkOrderDetails.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedOrder.searched
                              ? _t(t("Submitted orders"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                      <ul className="t-list fk-sort align-items-center">
                        <div className="input-group col">
                          <div className="form-file">
                            <input
                              type="text"
                              className="form-control border-0 form-control--light-1 rounded-0"
                              placeholder={
                                _t(t("Search by token, customer, branch")) +
                                ".."
                              }
                              onChange={handleSearch}
                            />
                          </div>
                          <button className="btn btn-primary" type="button">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-7">
                      <div className="row align-items-center gx-2">
                        <div className="col"></div>
                        <div className="col">
                          <NavLink
                            to="/dashboard/pos"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100"
                          >
                            {_t(t("POS"))}
                          </NavLink>
                        </div>
                        <div className="col">
                          <NavLink
                            to="/dashboard/pos/settled"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-success xsm-text text-uppercase text-center w-100"
                          >
                            {_t(t("Ordenes Pagadas"))}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Select
                        options={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }]}
                        components={makeAnimated()}
                        value={[{ name: "5", value: 5 }, { name: 10, value: 10 }, { name: 25, value: 25 }, { name: 50, value: 50 }, { name: 100, value: 100 }].filter(({ value }) => value === datasettled.limit)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        className="xsm-text"
                        onChange={handlesetslccharged2}
                        maxMenuHeight="200px"
                        placeholder={"Cantidad de filas"}
                      />
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("#"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Orden"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Time"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Table"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {datasettled && datasettled.data.map((item, index) => (
                              <tr
                                className="align-middle"
                                key={index}
                              >
                                <th
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                  {index + 1 + (datasettled.current_page) * datasettled.limit}
                                </th>
                                <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                  #{formartid(item.id)}
                                </td>

                                <td className="xsm-text text-capitalize align-middle text-center">
                                  <Moment format="LT">
                                    {item.token.time}
                                  </Moment>
                                </td>

                                <td className="xsm-text align-middle text-center">
                                  {item.customer_name}
                                </td>

                                <td className="xsm-text align-middle text-center">
                                  {item.table_name}
                                </td>

                                <td className="xsm-text align-middle text-center">
                                  {item.branch_name || "-"}
                                </td>

                                <td className="xsm-text text-capitalize align-middle text-center">
                                  {parseInt(item.is_cancelled) ===
                                    0 ? (
                                    [
                                      parseInt(item.is_ready) ===
                                        0 ? (
                                        <span
                                          className="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                          onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: false,
                                            });
                                            setReturnMoneyUsd(0);
                                            setPaidMoney(0);
                                          }}
                                          data-toggle="modal"
                                          data-target="#orderDetails"
                                        >
                                          {_t(t("processing"))}
                                        </span>
                                      ) : (
                                        <span
                                          className="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                          onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: false,
                                            });
                                            setReturnMoneyUsd(0);
                                            setPaidMoney(0);
                                          }}
                                          data-toggle="modal"
                                          data-target="#orderDetails"
                                        >
                                          {_t(t("Ready"))}
                                        </span>
                                      ),
                                    ]
                                  ) : (
                                    <span
                                      className="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                      onClick={() => {
                                        setCheckOrderDetails({
                                          ...checkOrderDetails,
                                          item: item,
                                          settle: false,
                                        });
                                        setReturnMoneyUsd(0);
                                        setPaidMoney(0);
                                      }}
                                      data-toggle="modal"
                                      data-target="#orderDetails"
                                    >
                                      {_t(t("Cancelled"))}
                                    </span>
                                  )}
                                </td>

                                <td className="xsm-text align-middle text-center">
                                  {parseInt(item.is_cancelled) ===
                                    0 ? (
                                    <div className="dropdown text-capitalize">
                                      <button
                                        className="btn t-bg-clear t-text-dark--light-40"
                                        type="button"
                                        data-toggle="dropdown"
                                      >
                                        <i className="fa fa-ellipsis-h"></i>
                                      </button>
                                      <div className="dropdown-menu">
                                        <button
                                          // send state- order group id
                                          className="dropdown-item sm-text text-capitalize"
                                          onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: true,
                                              payment_amount: null,
                                              payment_type: null,
                                            });
                                            setReturnMoneyUsd(0);
                                            setPaidMoney(0);
                                          }}
                                          data-toggle="modal"
                                          data-target="#orderDetails"
                                        >
                                          <span className="t-mr-8">
                                            <i className="fa fa-refresh"></i>
                                          </span>
                                          {_t(t("Pagar orden"))}
                                        </button>

                                        <button
                                          className="dropdown-item sm-text text-capitalize"
                                          data-toggle="modal"
                                          data-target="#modaldeleteorder"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setordersselect({
                                              ...ordersselect,
                                              ordersselect: item
                                            });
                                          }}
                                        >
                                          <span className="t-mr-8">
                                            <i className="fa fa-trash"></i>
                                          </span>
                                          {_t(
                                            t("Cancelar orden")
                                          )}
                                        </button>

                                        <button
                                          // send state- order group id
                                          className="dropdown-item sm-text text-capitalize"
                                          onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: true,
                                              payment_amount: null,
                                              payment_type: null,
                                            });
                                            setTimeout(() => {
                                              handleOnlyPrint();
                                            }, 1000);

                                          }}
                                        >
                                          <span className="t-mr-8">
                                            <i className="fa fa-file-text-o"></i>
                                          </span>
                                          {_t(t("Ticket"))}
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    _t(t("-"))
                                  )}
                                </td>
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div key="fragment4">
                <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                  <div className="row align-items-center t-pl-15 t-pr-15">
                    <div className="col-md-7 t-mb-15 mb-md-0">
                      <nav aria-label="Page navigation example" style={{ float: 'left' }}>
                        <ul className="pagination">Showing {datasettled.current_page + 1} to {datasettled.limit} of {datasettled.total} entries</ul>
                      </nav>
                    </div>
                    <div className="col-md-5">
                      <nav aria-label="Page navigation example" style={{ float: 'right' }}>
                        <ul className="pagination">
                          <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(0, datasettled.limit, datasettled.searchInput)}>First</a></li>
                          <li className={datasettled.current_page == 0 ? "page-item disabled" : "page-item"} style={datasettled.current_page == 1 ? {} : { cursor: "pointer" }} ><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.current_page - 1, datasettled.limit, datasettled.searchInput)}>Previous</a></li>
                          {datasettled.renderPageNumbers}
                          <li className={datasettled.current_page == datasettled.pages - 1 ? "page-item disabled" : "page-item"} style={datasettled.current_page == datasettled.total ? {} : { cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.current_page + 1, datasettled.limit, datasettled.searchInput)}>Next</a></li>
                          <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={() => makeHttpRequestWithPage(datasettled.pages - 1, datasettled.limit, datasettled.searchInput)}>Last</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Submitted;
